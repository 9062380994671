import React from 'react'
import {FaHandPointer} from "react-icons/fa"
import { Link } from "gatsby"
import Badge from 'react-bootstrap/Badge'

const FeatureStyle6 = ({image, title, text, url, hot}) => {
    return (
        <div className="feature-6">
            <Link to={url}><img className="img-fluid" src={image} alt={text} style={{maxHeight:220}} /></Link>
            <Link to={url}>
                <h3>{title}</h3>
                {
                    hot && <Badge variant="danger">Hot</Badge>
                }
            </Link>
            <p>{text}</p>
            <Link to={url}>查看人口、学区、房价详情 <FaHandPointer/></Link>
            <br/>
            <Link to={url + "-real-estate-map"}>按neighourhool查看 <FaHandPointer/></Link>
        </div>
    )
}

export default FeatureStyle6
