import Pleasanton from "../images/pleasanton-008.jpg"
import Dublin from "../images/dublin.jpg"
import SanRamon from "../images/sanramon.jpg"
import Newark from "../images/newark.jpg"
import Fremont from "../images/Fremont.jpg"
import Danville from "../images/Danville.jpg"
import SanJose from "../images/sanjose.jpg"
import Sunnyvale from "../images/Sunnyvale.jpg"
import MountainView from "../images/MountainView.jpg"
import PaloAlto from "../images/palo_alto.jpg"
import Cupertino from "../images/cupertino.jpg"
import Milpitas from "../images/milpitas.jpg"
import Saratoga from "../images/saratoga.jpg"
import RedwoodCity from "../images/redwood.jpg"
import DalyCity from "../images/dalycity.jpg"
import CastroValley from "../images/castrovalley.jpg"
import FosterCity from "../images/fostercity.jpg"
import SanMateo from "../images/sanmateo.jpg"
import Livermore from "../images/livermore.jpg"
import LasVegas from "../images/lasvegas.jpg"
import Orlando from "../images/orlando.jpg"
import Austin from "../images/austin.jpg"
import Campbell from "../images/campbell.jpg"
import Tracy from "../images/tracy.jpg"
import MountainHouse from "../images/mountainhouse.jpg"
import Alameda from "../images/alameda.jpg"
import MenloPark from "../images/menlo_park.jpg"
import LosAltos from "../images/losaltos.jpg"
import LosGatos from "../images/losgatos.jpg"
import Atherton from "../images/atherton.jpg"
import Millbrae from "../images/millbrae.jpg"
import Burlingame from "../images/burlingame.jpg"
import Hillsborough from "../images/hillsborough.jpg"
import Woodside from "../images/woodside.jpg"
import PortolaValley from "../images/portola_valley.jpg"

// https://burlingameproperties.com/communities/foster-city
// https://sternsmith.com/communities/san-carlos/

const cities = {
  "Pleasanton": {
    name: 'Pleasanton',
    title: '旧金山东湾最佳学区，明星城市，华人眼中的“幸福屯”',
    img: Pleasanton,
    population: 82372,
    description: [
        'Pleasanton（ 普林森顿 ）是东湾最着名的城市之一，以科技经济发展之快与人口素质之高名列北加州前茅，曾被CNN评为最宜居城市，排第63位，财富杂志Forbes将其选入“全国好家乡”。这里具有历史悠久，风格独特的市中心餐饮购物休闲中心，优美宁静的居住社区，丰富多彩的文化娱乐活动，名列前茅的学校评分等等，一直为广大华人家庭所喜爱，已成为广大华人朋友关注和搬入的热门地区。华人将Pleasanton亲切的称为「幸福屯」，社区安静又漂亮，学校质量很高，但房价又比南湾便宜，性价比相对高。',
        '从上世纪80年代以来，它就以科技经济发展之快与人口素质之高名列北加州前茅。这里具有历史悠久，风格独特的市中心餐饮购物休闲中心，优美宁静的居住社区，丰富多彩的文化娱乐活动，名列前茅的学校评分等等，一直为广大购房者所喜爱。相比较San Ramon，这里的学校同样优秀，但是社区更加成熟，配套设施全面。因为 Pleasanton 的政策和San Ramon不同，所以新房子和新社区不多，很多房子在 30年房龄以上。',
        '全年最热为 7月份，最高平均气温为 90--92‘F ( 30--32 \'C ), 最低气温为12--1月份，大约为 35--37‘F ( 1--3\'C )。',
        'Pleasanton购物中心方便，隔壁Livermore的San Francisco Premium Outlets。东方明珠广场内餐厅、超市等。最值得一提的是，因为周边亚裔多，市内有两家大华分店。',
        '湾区著名的超级购物中心之一Stoneridge Mall 和 Alameda fairgrounds 等都是周末节日的好去处！每年的 5--10月份，在这里将有丰富多彩的这区活动，古典汽车展销，爱尔兰民族服装与舞蹈表演，马术表演比赛，小商业餐饮贸易游乐展销会等等。每年一度的 7月4日大型国庆焰火晚会将在这里上演！',
        'Pleasanton交通发达，社区安静漂亮，学校质量好，但房价又比南湾便宜，性价比高。不少人虽然在硅谷的大公司上班，却在此地安家，且Apple、Google等大公司都在当地设有通勤车。无论是公路580，还是680，进出很方便。且市内有两个捷运站，分别是West Dublin / Pleasanton和Dublin / Pleasanton，不少市民选择开车到捷运停车场，再坐车去旧金山等区域上班。',
        '很多公司将总部设在Pleasanton。比如财务管理和人力资本管理软件供应商Workday、处理抵押贷款申请的软体公司Ellie Mae、罗氏分子诊断公司Roche Molecular Diagnostics，提供礼品卡、品牌价值解决方案的Blackhawk Network Holdings公司、提供生命科学行业商业解决方案公司Veeva Systems等。',
        ],
    basicInfos:[
      {
        type: 'text',
        value: '以下Pleasanton历史地形图，Val Vista区以前是个湖泊，这也导致那一片地区的房子可能会有地基问题。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622091436/image35_advwmd.png',
        desc: 'Pleasanton历史地形图'
      }
    ],
    neighbours: {
      "Laguna Oaks": [

      ]
    },
    residenceInfos: [
      '全市第二大族裔，亚裔中华裔居多。家庭平均年收入为全加州中位数的2倍。在2020年美国着名的“富士比财富”评比中，Pleasanton被列为全美国平均年收入最高的前十名城市之一。2014 年，Pleasanton 被评为全美最适合居住的 50 个城市前 5 名。',
    ],
    schoolInfos: [
      'Pleasanton 拥有加州和湾区最好的公立学校。Pleasanton 联合学区位于 Pleasanton 市内，学区内共有17所学校，评分多在9-10分。Foothill High School和Amador Valley High School 都被评为加州杰出学校，还拥有“国家蓝带学校”的荣誉。',
      '这里的学校常常举办各类活动，鼓励孩子踊跃参与到各项活动中。一年四季赛事不断，8月到11月是足球季，11月到3月是篮球季，3月到学期结束是棒球季，6月到8月是游泳季节。由于活动种类众多，几乎涵盖所有学生，家长参与到孩子的活动中，这样不仅可以和孩子有紧密的沟通，同时和其他家长、社区也会有频繁的互动。',
      'Pleasanton共有3所公立初中，API分数均超过900分。',
    ],
    estatemap: "pleasanton-estate",
    related: [
      'Dublin', 'San Ramon', 'Livermore', 'Danville'
    ]
  },
  "Dublin": {
    name: 'Dublin',
    title: '社区优美，发展快速，文化多元的明星之城',
    img: Dublin,
    population: 63445,
    description: [
        '都柏林（Dublin）位于东湾内陆地区。以前市内居住的爱尔兰人众多，而爱尔兰的首都也叫Dublin，城市之名由此而来，当地邮局正式在1890年代采用都柏林的称谓。而因为学校质量高，社区环境好，居民守望相助，不少华人形容都柏林为「温情之都，快乐之城」，是旧金山湾区阿拉米达县过去7年中发展最快的城市，近年来华裔人口增长迅速。',
        '虽然地处东湾内陆地区，但Dublin交通发达，无论是公路580，还是680，进出很方便。且市内有两个捷运站，湾区BART的东湾终点站就在Dublin，分别是West Dublin / Pleasanton和Dublin / Pleasanton，不少市民选择开车到捷运停车场，地铁1小时左右可达旧金山。由于周边区域人口暴增，捷运停车位时常人满为患，一位难求。Dublin是一个新兴科技和多元文化城市，拥有18座公园和体育运动场所，8所加州杰出学校和一所蓝带小学。环境优美，学区优异，生活便利，文化生活丰富，是华人心仪的置业之地。',
        '软件公司CallidusCloud、罗斯百货（Ross Stores）、高科技医美技术公司Tria Beauty、医疗保健公司Medley Health、食品公司Challenge Dairy、摩托公司Arlen Ness的总部，都设在都柏林。金融机构Patelco Credit Union也将把总部从布利桑顿迁移到城市内。未来都柏林的办公楼是目前的三倍多，总计21万平方呎，Patelco公司前不久花费3500万购买Park Place 3号的一栋建筑，该大楼曾经是软件公司SAP的所在地。',
        '都柏林Regional Street路上有一家大华99超市，毗邻的Pleasanton有两家大华超市。三家大华超市之间都只有9分钟车程，华人可以购买符合自己口味的产品。Dublin附近有很多很不错的中餐馆，鲤鱼门、五月花都是人气很高的馆子。也有不少的超市，包括Costco，满足日常生活所需。',
        '在每年三月春暖花开的第二个周末，每年一度Saint Patrick’s Day Parade将在这里的城市广场盛大举行。它来自于爱尔兰的古老文化习俗，广大居民和游客共同庆祝又一个阳光美丽的春天来临，绿色服饰和巧克力小吃是这项民间活动的最佳色彩和乐趣。每年的城市夏日音乐节也在7–8月的周末举办，吸引了湾区各地的民众前来观赏。'
    ],
    residenceInfos: [
      '近年来华裔和新移民家庭大批迁入Dublin市区，亚裔人口增长速度很快，已从2011年的4.8%到2019年的48.4%。',
      '作为东湾内陆重要的城市，都柏林亚裔人口增长快，亚裔超市也分布颇广，生活很方便。'
    ],
    schoolInfos: [
      'Dublin最主要的学区就是Dublin Unified School District ，Niche的综合评分高达A+，包含11所公立学校，是在整个湾区排名靠前的学区。都柏林市有七所小学(阿马多尔、多尔蒂、都柏林、弗雷德里克森、约翰·格林、科尔布和默里)、两所中学(法伦和威尔斯)和一所公立高中，由都柏林统一学区管理。',
      'Dublin联合学区拥有10余所学校，为超过10000名学生提供教育服务。学区以拥有8所加州杰出学校（California Distinguished School awards ）和一所蓝带学校为荣（Blue Ribbon School). 也因此深受一贯重视教育的华人买家喜爱。'
    ],
    related: [
      'Pleasanton', 'San Ramon', 'Livermore', 'Danville'
    ]
  },
  "San Ramon": {
    name: 'San Ramon',
    title: '东湾最安全的好学区，加州抚养孩子最安全的城市',
    img: SanRamon,
    population: 75839,
    description: [
        'San Ramon 位于东湾的北面，南靠都柏林（Dublin）和普莱森顿（Pleasanton），这里有便捷的BART捷运系统可以直通旧金山、奥克兰（Oakland），南面可以到达Fremont。San Ramon的北面是丹维尔（Danville），此处一年四季郁郁葱葱，是豪宅遍地的富丽之城。环境优美，三谷风光，绿地公园，鸟语花香。深受广大华人家庭和新移民的欢迎。',
        'San Ramon曾被评为全美20大最受欢迎和宜居城市之一，被 SafeWise 评为加州2020年最安全的城市，排名第7位。它还是全美最佳学区之一和全美收入最高的城市。2020年最新数据显示，较2019年圣拉蒙的犯罪率又降低1个百分点，为0.6%。学区总是跟社区安全、增值潜力挂钩。学区越好的房子，保值潜力越大，不怕将来卖不出好价钱。特殊时期，学区房一般也更抗跌。',
        '在San Ramon，有石油巨头Chevron、健身俱乐部24 Fitness的总部、通用电气（GE）的软件开发总部，还有AT&T西区总部、UPS、Toyota的区域中心都在此。此外，在附近的Dublin和Pleasanton也有很多IT巨头的分部，如Oracle、SAP等在此驻扎。',
        '饮食购物在San Ramon也非常方便，附近有两家99⼤大华超市、两家Costco，多家超市开车都在5到15分钟之内。附近还有SanFrancisco Premium Outlets、众多华人著名的餐馆。',
        'San Ramon风格各异的高尔夫球场之多可以说是湾区之最了。著名的有TheBridge Golf、San Ramon Golf、CanyonLake Golf 等等，每年有很多国际赛事在此举行，以及邻近的 Black Hawk、Alamo、Diable等高档社区也有专属的高尔夫球场。',
        'San Ramon有两个邮政编码（Zipcode）94582和94583，高速公路680以东的94582主要是Doughterty Valley，为大多数华人青睐，因为这里有更好的学区和更新的房子。',
        '距离旧金山市区和国际机场约为30–40英里，南湾高科技硅谷中心城区为30–40英里，与湾区东西南北大动脉–580 / 680高速公路沿线的三谷地区Dublin，Pleasanton，Danville市相邻。到达东湾地铁终点站–Dublin/Pleasanton Bart Station仅为10分钟车程'
    ],
    residenceInfos: [
      '圣拉蒙的人口，受教育程度高达96.9%。这里拥有庞大的亚裔居民，华人居多，占总人口的39.5%，华人约占18% 人口比例，仅次排名第一的白人居民。且亚裔的家庭收入中位数13.9万美元，高于圣拉蒙整体水平的12.7万美元。高素质居民的构成，为该市带来了极低的犯罪率。',
      '在⼈均收入方面，根据CBS的一份调查显示，全国只有16个城市中一半以上家庭收⼊入超过六位数，⽽其中San Ramon是六位数收入比例排名第一高的城市，中位数家庭收入为$162,339（2019年数据，如今应该更高）。高收入和高素质的居民，是让这座城市学区好、治安好的最重要因素。',
      '值得一提的是San Ramon的治安也非常好，其高收入和高素质的居民构成，是这座城市学区好、治安好的最重要因素。在2018年9月，全国消费者和个人理财机构WalletHub将圣拉蒙列为湾区“best place to raise a family”的最佳地点，也是所有加州城市中寻求扎根的家庭中排名第二的好地方。'
    ],
    schoolData:[
      {
        type: 'link',
        value: 'https://srvusd-ca.schoolloop.com/file/1218730286175/1407474792799/4750340671702824238.pdf',
        desc: 'San Ramon 小学学区'
      },
      {
        type: 'link',
        value: 'http://srvusd.ca.schoolloop.com/file/1280584351915/1275747792896/3202646099503673974.pdf',
        desc: 'San Ramon 中学学区'
      },
      {
        type: 'link',
        value: 'http://srvusd.ca.schoolloop.com/file/1280584351915/1275747792896/7428520023966604633.pdf',
        desc: 'San Ramon 高中学区'
      }
    ],
    videos: [
      {
        "title": "Jing Xue New Listing 2530 Tamworth Ln, San Ramon, CA 94582",
        "link": "https://www.youtube.com/embed/MGJndEFnzm4"
      },
      {
        "title": "Jing Xue Tamworth Ln, San Ramon, CA 94582 UNBRANDED",
        "link": "https://www.youtube.com/embed/CRx9xNUDZ-Y"
      },
      {
        "title": "Jing Xue Proudly Presents 319 Langton Ct, San Ramon, CA 94582",
        "link": "https://www.youtube.com/embed/CSD6PDcFgMU"
      },
      {
        "title": "Jing (Simone ) Xue New Listing at 3416 Ironwood Dr, San Ramon, CA 94582, USA",
        "link": "https://www.youtube.com/embed/CNmy8efRZRY"
      },
      {
        "title": "Jing Xue Real Estate Team_3015 Montbretia Way, San Ramon CA 94582",
        "link": "https://www.youtube.com/embed/xsKPFDTBfXk"
      },
      {
        "title": "New listing at 621 Kirkstone Ct San Ramon",
        "link": "https://www.youtube.com/embed/xz7nXMkb6kE"
      }
    ],
    schoolInfos: [
      'San Ramon在近年来科技经济文化教育蓬勃发展，已经成为2013年全美国最富有的小城市（10万人口以下）前十名（家庭年平均收入为16–17万美金），拥有全加州 Top 10% 的最佳小学，中学，高中，平均评分都在900分以上。',
      'Dougherty Valley高中，这个学校2007年竣工，造价1.5亿美金，是当时乃至今日全美设备最先进，最完善，造价最贵的公立高中。其设施与硬件高档新颖，有符合奥林匹克场馆要求的游泳馆和体操馆，还有可以容纳2,800人的全新塑胶跑道运动场，以及600名观众的现代化剧场。凡是来过这个学校的人，无不为其先进的设备而羡慕。',
      '此外，该学校的学术水平也是湾区数一数二的。美国高中生有一项PSAT考试（Preliminary SAT），又称为全国优秀学生奖学金考试（National Merit Scholarship Qualifying Test–NMSQT ）。每年约有320万学生参加考试，只有1万6千名能进入半决赛资格，其中的50%又可进入决赛资格。Dougherty Valley高中的学生在2012年有95名进入半决赛，2013年更超过100名了。',
      '除了优秀的高中，还有加州排名前十名的初中（6到8年级）、评分9分的 Windemere Ranch Middle School，其2013年API全国统考更获得加州第一名；2014年还获得“Blue Ribbon School”的荣誉（全国只有25所初中入选，全加州只有5所），9分的还有Pine Valley Middle School 和 IRon Horse Middle School。 此外，Dougherty Valley区的小学也很优秀，区内所有的小学在最新的GreatSchoolsRating都是满分10分，无一例外。',
      'Doughterty Valley High名列US News 2015加州最佳高中的第29名，仅次于第26名位于Palto Alto的Henry Gunn High School，以及第27名的Saratoga High School。但这两所高中的所在城市，其独立屋平均房价都超过2百万美元。'
    ],
    estatemap: "san-ramon-estate",
    related: [
      'Pleasanton', 'Dublin', 'Livermore', 'Danville', 'Blackhawk'
    ]
  },
  'Livermore': {
    name: 'Livermore',
    title: '地广人少，风景优美的酒庄，交通方便，房价亲民',
    img: Livermore,
    population: 90269,
    description: [
        'Livermore 位于旧金山以东约40哩，这里的大片土地由碎石土壤构成。东西长15英哩，南北宽10英哩，被海湾和山脉环绕而形成山谷，阳光普照大地，昼夜温差很大。非常适合种植和生产优势葡萄酒。目前这里拥有40多家酿酒厂，超过5000英亩葡萄园，年产各类优质红白葡萄酒6万余箱，是加州红酒的重要产区之一。它的知名度虽没有 Napa Valley那么高，但历史更长。这里的葡萄种植和红酒酿制开始于1840年。',
        'Livermore山谷的葡萄园、牧场、酒庄里，阳光和煦，空间开阔，是迷人的休闲景点。这个山谷是一个阳光明媚的保护区，也是加州最古老的葡萄酒产区之一，这里阳光灿烂，氛围闲适，让人如同置身只有内行人士才知悉的神秘胜地。',
        '湾区最为著名的 –San Francisco Premium Outlet-- 大型名牌商品直销中心，有超过150家世界名牌高级高中档时尚精品。令琅满目，物美价廉，客户蜂拥，车水马龙！这里已经成为旧金山湾区广大居民周末购物郊游的最好地点之一！',
        'Livermore全年最高平均气温在7月份约为92–95华氏度，最低平均气温在1月份约为30–33华氏度。市政府近年来大力发展城市建设与外宣传，刚刚装修改造的市中心休闲购物街喷泉雕塑，鲜花盛开，中西餐饮，任君品尝。这里还有湾区着名的18洞国际标准高尔夫球场，LVR大水库也刚刚新建成湖畔公园，是您全家夏日里钓鱼烧烤的最好去处！',
        '美国着名的劳伦斯国家核技术研究实验室（ www.llnl.gov ）就坐落在城市的东南方，近6000名尖端科学家，工程师，实验团队在这里研发出各种高科技军工武器与装备，是美国国防事业的重要部门之一。大家熟知的无线通讯视听安装服务（ www.comcast.com ）总部就设在这里。由于这里地广人少，风景优美，交通方便，房租便宜，近年来很多南湾，旧金山地区的高科技公司纷纷搬迁来此落户，发展事业。',
    ],
    schoolInfos: [
      'Livermore高中是位于Livermore的一所高等级公立学校，它有1794名学生，在9-12年级，师生比例为23比1。根据国家考试成绩，45%的学生至少精通数学，64%的学生阅读。'
    ],
    related: [
      'Pleasanton', 'Dublin', 'Danville', 'San Ramon'
    ]
  },
  'Danville': {
    name: 'Danville',
    title: '加州传统的好学区、富人区，最适合家庭居住城镇',
    img: Danville,
    population: 40693,
    description: [
        'Danville 丹维尔市位于旧金山湾东部，是东湾著名的适宜居住的城市之一，距离旧金山市中心车程30分钟，是在东湾三谷地区的五个城市中，历史最为悠久，风光最为美丽的一个乡村风格小镇。它位于 湾区东北部Contra Casta 县的680高速公路旁，被称为San Ramon Valley的心脏城市。他它的历史可以追溯到1860年由墨西哥人开采矿山和农场开始发展。',
        'Danville 距离加州大学伯克利分校20分钟车程，加州大学戴维斯分校1小时车程。距离旧金山市区和国际机场约为30–40英里，南湾高科技硅谷中心城区为30–40英里，与湾区东西南北大动脉–580 / 680高速公路沿线的三谷地区Dublin，Pleasanton，San Ramon市相邻。到达东湾地铁终点站–Dublin/Pleasanton Bart Station仅为10分钟车程',
        '据安全产品审查网站Safewise的一份新报告发现，加州最安全的城市在旧金山东湾（East Bay）。在这项安全研究中名列榜首的是东湾丹维尔镇（Danville），它是康特拉科斯塔县（ Contra Costa County）的 Mount Diablo下属的直辖镇。',
        'Danville市长ReneeS.Morgan说:「Danville一直被评为加州最安全城市。社区安全是我们所有居民和企业的最高优先事项。」',
        'Danville是Contra Costa县一个财产和暴力犯罪率都非常低的宁静祥和之地。 Danville每1000人中只有0.35起暴力犯罪和7.83起财产犯罪。它去年也被评为州最安全城市。',
        '这座城市一直以美国小镇风情，优美景色，高尚社区，最好学校而闻名于北加州和旧金山湾区。居民和游客可以到具有130年历史的装饰典雅，树木成荫的市中心休闲购物，在不同情调的获奖餐厅里与家人享受美酒佳肴。或漫步乡间小路，欣赏鸟语花香。周末与朋友骑单车游览大自然风光是当地居民的爱好。距离市区5英里拥有着名的蒙迪亚布罗州立公园（ Mt Diablo State Park ），当您努力爬到其3500尺的野营山顶时，一定会陶醉在旧金山湾区的绝色美景之中。'
    ],
    schoolData:[
      {
        type: 'link',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625812541/1625812424803_g1zg7r.jpg',
        desc: 'Danville 小学学区'
      },
      {
        type: 'link',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625812540/1625812477520_g8xwet.jpg',
        desc: 'Danville 中学学区'
      },
      {
        type: 'link',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625812541/1625812524711_tukdej.jpg',
        desc: 'Danville 高中学区'
      }
    ],
    residenceInfos: [
      '犯罪率系数仅为75(美国平均为319)，治安情况非常好。在三谷地区城市中是最好的。',
    ],
    schoolInfos: [
      'Danville 市政府与学校管理部门多年来特别重视教育投资与人才培养，这里的整个大湾区学校综合评分最高的城市之一。其中7所小学里满10分的5所，9分的2所，3所中学为9--10分，2所公立高中为9---10分，2所私立高中均为10满分。毕业生考入斯坦福、伯克莱加大等名牌大学的比例非常高。阿亭尼私立高中（ The Athenian School ）是加州最佳私立高中的前10名，录取严格，竞争激烈。毕业生考入斯坦福，伯克莱加大等名牌大学的比例是湾区最高的。现有来自中国各地的最优秀留学生20多名。近年来很多湾区其他城市的华人家庭和新移民为了子女得到最好的教育，纷纷搬到这里安家落户。'
    ],
    related: [
      'Pleasanton', 'Dublin', 'Livermore', 'San Ramon', 'Blackhawk'
    ]
  },
  'Fremont': {
    name: 'Fremont',
    title: '整个湾区的中点，一流学区，全美生活最幸福的地方',
    img: Fremont,
    population: 237807,
    description: [
        'Fremont（弗里蒙特，也有当地华人称之为福临门），位于旧金山湾的东南侧（俗称东湾，Eastbay），占地92平方英里，湾区第四大城市，加州第五大城市。Fremont市政府建制于1956年1月23日，由Centerville，Irvington，MissionSan Jose，Niles和WarmSprings这五个较小的社区组合而成。城市得名于被尊称为“伟大先锋”（GreatPathfinder）的约翰·查理斯·费利蒙（JohnCharles Fremont），也是东湾最靠近硅谷的城市。',
        'Fremont通过大桥与旧金山半岛相连，不过很容易发生拥堵，但如果不堵车的话20分钟即可到 Facebook 办公室。',
        '气候上来看，该市与湾区其它城市一样，夏季炎热干燥，冬季湿润多雨，全年气温在14-26摄氏度。昼夜温差明显，但相对于湾区南部为小。同时，夏季的气温也没有湾区南部高（低3摄氏度左右），冬季则基本持平。',
        'Fremont分为南北两区。北区是一个主要居住区，被联合市、中心维尔区、纽瓦克和郊狼山区域公园包围。位于 84 号和 880 高速交汇处，无论是通往旧金山、圣何塞，还是去往奥克兰都非常方便。这是一个不断增长的社区，包括 Ardenwood 社区、湖泊和鸟类社区以及 Northgate 社区。南区主要是一个工业区，著名的特斯拉工厂的所在地，也是 Warm Springs / South Fremont Station 的所在地。这里也有一些新屋在南区建立起来，如 Lennar 开发的楼盘等。',
        'Fremont中央公园内有一个大湖伊丽莎白湖，是个休闲聚会的好地方。后面的MissionPeak更是hiking的好地方，曾被提名为湾区八景之一。',
        'Fremont居于整个湾区的中点，从地图上看，Fremont是西北、东南走向的夹在680，880的两条高速公路的狭长地带，距离880和680都不远，过84号桥就到中半岛。此外，Fremont还有BART通过，可以坐BART北上三藩工作。Fremont的交通实际上应该加分，而不是减分，住在Fremont，意味着整个湾区核心地带，北到三藩，南到南圣何塞，往西过桥到中半岛，往东到Pleasonton，San Ramon，您可以在这个广大区域的任何地方找工作。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625528317/fremont-neighbourhood_ooetcn.jpg',
        desc: 'Fremont neighbourhood'
      },
    ],
    residenceInfos: [
      'Fremont是一个包容且多元化的城市，是一个对亚裔友好的城市，超半数人口为亚裔居民，其中印度裔、华裔占据大多数，仅有 19% 的人口是白人。',
      'Fremont是非常适合华人居住的城市，附近有5家华人超市，两家狮子城（另一家在Newark），两家大华99（另一家在Newark），还有在UnionCity的永合超市，无数中餐馆。对于华裔来说，这里生活极为方便。在城市一南一北有两个华人商业区，有超市，餐馆等等，生活非常便利。如果有上年纪的父母也在美国，也可以参加诸如 Age Well Center（原名 The Fremont Senior Center）一类组织举办的中老年舞蹈团、太极等活动，有的时候也会在周末的户外活动进行演出，非常热闹。',
      '2018年，在WalletHub制作了一份全美最幸福城市榜单，榜单中，Fremont高居榜首，是全美生活最幸福的地方。Fremont以幸福指数79.89排名全美第一。Fremont的社区和环境排第1，感情和身体健康排第3，收入和就业排第6。WalletHub考察了28项幸福指标，包括抑郁率、收入增长率、身体健康，离婚率，工作时长和平均闲暇时间等，并根据情绪和身体健康、就业收入和社区环境三大方面,对美国180多个城市进行排名。'
    ],
    schoolInfos: [
      'Fremont拥有加州一流的教育。Fremont统一学区有五所高中：包括 American  高中、 Irvington 高中、John F. Kennedy高中、Mission San Jose 高中和 Washington 高中。超过8分的小学有17所，初中有3所，高中有5所（10分是满分，8分及以上就算好学校），评价为8分的 Thornton Junior High School，评价为7分 Patterson Elementary School 等学校。',
      '其中 Mission San Jose 高中更是佼佼者，可以说MSJ是整个湾区从小学到高中属于加州最最顶尖的学区之一，这个等级的学区放眼整个湾区，不会超过5个，评分为 9 分，其 API 分数达 951，Irvington 高中的 API 也达到了 884 分。2018 年 U.S.News 加州公立学校排名，Mission San Jose 高中在整个湾区排名第一，全加州排名第八，甚至超越了 Palo Alto 的 Henry M. Gunn High School。（在Niche的排名中，Gunn还是稳居第一）。',
    ],
    estatemap: "fremont-estate",
    related: [
      'Pleasanton', 'Dublin', 'Union City', 'San Ramon', 'Milpitas', 'Newark'
    ]
  },
  'Cupertino': {
    name: 'Cupertino',
    title: 'Apple总部，硅谷的心脏，顶级学区，湾区中国城',
    img: Cupertino,
    population: 60170,
    description: [
        'Cupertino隶属旧金山San Francisco城市圈，位于旧金山的南部、圣荷西San Jose西部，是硅谷的核心城市之一。Cupertino与县府圣荷西相邻，部份市区延伸至圣塔克鲁兹山脉山脚。它在全美最富裕的城市中排名11位，《福布斯》杂志把它列为教育质量最好的小城之一。',
        'Cupertino作为科技重镇，被称为“硅谷的心脏”，是Apple苹果、Symantec赛门铁克、Seagate希捷等大公司总部所在地，苹果公司在当地有34300名员工，是该市的最大雇主。该城在2012年被评为全美最适宜居住的小型城市中第二十七位，加利福尼亚州第二位。Cupertino的房产除了学区好，还临近众多大企业，无论是房价涨幅还是租金回报都相当不错。',
        '走在Cupertino的闹市街头，随处可见各类中式餐馆和华人超市，象征华人生活的99大华超市已在此经营发展多年。这里的市民十分富足，中位数收入167,534美元，是加州平均水平的2倍。2009年12月1日，Cupertino成为北加州第一个由亚裔人担任市长的城市。现今该市仍为硅谷重要城镇之一。',
        'Cupertino没有自己的警察局或消防队，该市的治安和灾害控制由圣塔克拉拉县警局和消防局负责。',
        '这座小城带给海外游子的不仅是华人熟悉的生活味道，还提供了极其优秀顶尖的学区配套，苹果教父乔布斯的高中学业就是在Cupertino的家园中学里完成的。Cupertino的学区与森尼韦尔Sunnyvale、圣荷西San Jose、萨拉托加Saratoga同一学区，整个学区共有59所学校，Cupertino辖区内有14所满分公立学校，是名副其实的满分学区。',
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624471039/NextdoorNeighborhoodsMap_nxceyp.jpg',
        desc: 'Cupertino neighbourhood'
      },
    ],
    basicInfos:[
      {
        type: 'text',
        value: 'Cupertino的华人商业也很丰富，这里有两家大华超市，可供日常采购，中餐厅种类也很多，小肥羊，杨师傅烧烤，三好拉面，85°C ，以及喜茶等各式奶茶品牌，应有尽有。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622099245/FhglryLCEGpo855H9X_kMITCycAA_cn22tg.png',
        desc: 'Cupertino的华人生活'
      },
      {
        type: 'text',
        value: 'Cupertino水泥厂位于Cupertino西部的山丘，与Foothill Boulevard和Stevens Creek Canyon Road附近的居民区相邻，并因工业过程和繁忙的车辆交通而散发大量空气污染，危及附近居民的当地居民和敏感个人（例如儿童）的健康。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622791307/lehigh-07_tmbf3s.jpg',
        desc: 'Cupertino水泥厂'
      }
    ],
    residenceInfos: [
      'Cupertino是名副其实的“中国城”，2009年12月1日，Cupertino成为北加州第一个由亚裔人担任市长的城市。',
      'Cupertino是很多移民喜欢定居的城市，生活设施、超市购物等非常便利。同时因为高科技企业聚集，很多技术型人才会在此定居，人口素质比较高。治安好，人口受教育程度属于美国较高水平，约有 78.8% 的人口为本科以上学历，华人受教育程度以研究生以上学历为主。失业率仅有 4.2%，低于加州 5.5% 的整体水平。',
    ],
    schoolInfos: [
      'Cupertino隶属于 Cupertino 学区，公立教育系统和私立教育系统都优秀。尽管因为评分系统的调整，让满分学校的数量减少，但对于华人家庭来说，依然是名副其实的满分学区。',
      'Cupertino联合学区内有18所小学，5所初中及5所高中，API（公立学校的量化指标）分数大多数为900分以上，非常优秀！Cupertino的中小学在加州享有极高的口碑，例如，C. B. Eaton 小学、全美蓝带学校 William Faria 小学和 William Regnart 小学都是评分为 9 分的优秀小学，评价为9分的 D. J. Sedgwick Elementary School，评价为8分的 Warren E. Hyde Middle School等学校。',
      '另外，在Cupertino有很多面向孩子的的编程课程、夏令营等可以选择，极大的丰富了孩子的兴趣爱好。',
      'Cupertino High School跟Monta Vista High School属于Cupertino市。Monta Vista High School的房产位于85号公路以西，靠近山区。Cupertino High School的学区房位于85号公路以东，Bollinger Road以北，地势较为平缓，存在少部分商业区域。基于API(AccountabilityProgress Reporting，美国K12公立学校衡量学术水平的主要标准)来看，Monta Vista HS（956）高出Cupertino HS(906)一筹。这个分数的差距很大是在于Monta Vista HS中Asia亚裔学生（中国人，印度人）的比例要大于Cupertino HS，如果但看亚裔学生成绩，区别就不是很大（969 vs 943）。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Cupertino高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335333/FUHSD-map-1_ye1bol.png',
        desc: 'Cupertino高中的学区划分'
      }
    ],
    related: [
      'Milpitas', 'Santa Clara', 'Fremont', 'San Jose', 'Sunnyvale'
    ]
  },
  'Union City': {
    name: 'Union City',
    title: '',
    population: 74559,
    description: [
        'Union City（联合城）位于旧金山湾区东湾，与Fremont，Newark并列成为东湾三城（Tri-City）。Union City的交通十分方便，连接880号高速公路， 贯通东湾各城市。州内84号公路以及Dumbarton大桥直接连接硅谷中心城市Menlo Park，对于在Facebook或Palo Alto上班的科技公司员工来说也非常方便。近年来，Union City的华人也越来越多，开设了华人超市以及各类餐厅，生活便利。',
        '联合城连接880号公路，连接硅谷各大城市, 包括圣荷西，佛利蒙特，伯克利大学等。州内84号公路以及Dumbarton大桥直接连接硅谷中心城市Menlo Park，对于在Facebook或Palo Alto上班的科技公司员工来说非常方便，距谷歌、苹果等高科技公司基本在半小时车程，同时两地享有湾区捷运系统以及多个机场，十分便利。',
        '该市人口多聚集于东西两侧，上班时间多数雇员会前往硅谷等商业区或工业区工作，导致白天人口骤减。但节假日时，人们喜欢观赏电影，聚餐或在公园运动，散步。Alvarado大道，Alvarado Niles等大道让前往沃尔玛以及 塔吉特商场购物的人们更为方便。',
        '该市的主要道路有：联合市大街，Mission大路，瓦拉多大道，Decoto大道，Whiple大道等。由此前往旧金山，屋仑和圣荷西都很方便。880号州际公路途经本市西部，在Alvarado大道,Alvarado-Niles大道 Whipple大道和Decoto大路设有出口。BART站位于Decoto-Avarado-Niles附近，乘坐捷运可以很快抵达东湾其他城镇和旧金山。'
    ],
    related: [
      'Pleasanton', 'Dublin', 'Fremont', 'San Ramon', 'Milpitas'
    ]
  },
  'Hayward': {
    name: 'Hayward',
    title: '',
    population: 159620,
    description: [
        'Hayward位于Castro Valley和Union City之间，市内有近16万人，是湾区第六大城市。Hayward处于南北湾中介点，且以San Mateo-Hayward Bridge与半岛城市相连，对于在半岛上班的人来说，非常便利。如果不想开车，也可以搭乘捷运（BART），往南至Fremont、Milpitas，往北则可至Oakland、San Francisco。',
        'Hayward北边是Oakland和San Leandro，南边是Union City，Fremont和San Jose；与西部半岛的San Mateo和Foster City隔湾向望，并有92号公路桥与之相连；湾区高速公路主干道之一的州际公路880穿城而过；同时有地铁（Bart）通向旧金山市区和半岛，交通便利。Hayward市中心的交通一直都非常繁忙。',
        '除此之外，Hayward城市内有Costco等商店，生活机能完整；Hayward东部山区更是受不少人喜欢，景色优美。而且，就算是山上可了望海湾、欣赏日落美景的房屋，价格也算是十分亲民。依照地产网站估计，Hayward的中位数房价，与邻近城市佛利蒙相差约40多万，因此吸引许多追求性价比的买家。',
        'Hayward靠山的区块也有高级豪宅，有景色、有环境。',
        '湾区大量的购房者开始慢慢地从南湾来到Hayward。Hayward是过去几年湾区房价上涨最快的地区之一，从2013年房子均价约30万美元涨到如今均价约80万美元，翻了一番；去年一年涨了15%。即便如此，Hayward房价依然远低于湾区平均的105.2万美元；以面积来算，Hayward均价398美元/平方英尺，湾区均价500美元/平方英尺，长期以来Hayward都是湾区的房价洼地。'
    ],
    related: [
      'Pleasanton', 'Dublin', 'Fremont', 'San Leandro', 'Milpitas'
    ]
  },
  'San Leandro': {
    name: 'San Leandro',
    title: '',
    population: 89703,
    description: [
        'San Leandro是美国加利福尼亚州阿拉梅达县下属的一个城市。于1872年3月21日建市。城市面积约为34.6平方公里，San Leandro位于旧金山湾东岸，西北在奥克兰，东南在Hayward之间。北靠东湾中心城市奥克兰，南临硅谷等地区，驱车到旧金山，或到Tri-Valley的Dublin等城市都不远，交通位置独特。且城市内有3家亚裔超市，有2个捷运站，还有多个华人餐厅。',
        'San Leandro这样交通方便、有升值空间的房子。纵观近年来的走势，San Leandro的房价在2012年3月为谷底，房价中位数为30万2000元，随后几乎一路飙升，有接近9年时间。比如邻近社区的Fremont和Castro Valley，同样户型或面积的房子，要比圣利安住贵20到30万，所以逐渐成为华人置业的热门区域。San Leandro的房屋增值空间很大，3年前60万买的房子，如今要80万才行。',
        'San Leandro长期以来一直是许多食品加工业务的所在地，也是许多公司业务的所在地，如JanSport、North Face、Ghirardelli、OSIsoft、Otis sunikmeyer和可口可乐工厂。Maxwell House经营着一家咖啡烘焙厂，作为母公司卡夫食品公司制定的削减成本计划的一部分，该厂后来被关闭。San Leandro市有五个主要购物中心，即海湾交易会中心、西门中心、温室购物中心、滨海广场中心、佩尔顿广场。',
        'San Leandro有两个学区San Leandro统一学区包括华盛顿庄园的一部分，San Leandro统一学区包括San Leandro大部分地区和奥克兰的一小部分地区。San Leandro有三所高中:San Leandro高中、太平洋高中(位于San Leandro统一学区)和玛丽娜高中(位于San Leandro市范围内，但隶属于邻近的San Leandro统一学区)。San Leandro高中成立于1926年。San Leandro高中是诸如商业学院、社会正义学院和圣·莱安德罗多媒体学院( SLAM )等学术项目的所在地。位于San Leandro的获奖国家项目之一是美国分布式教育俱乐部，一个市场营销学生协会。2007年，来自San Leandro高中的六名学生赢得了他们的比赛项目，并于2007年4月27日赢得了在佛罗里达州奥兰多市的比赛席位。San Leandro统一学区是美国和加拿大447个学区之一，该学区获得了年度AP学区荣誉榜的提名。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624490904/sanleandro_jwae96.jpg',
        desc: 'San Leandro neighbourhood'
      },
    ],
    residenceInfos: [
      '人口密度为每平方英里5，423.8人(合2，094.1人/每平方公里)。',
    ],
    related: [
      'Pleasanton', 'Dublin', 'Fremont', 'Hayward', 'Milpitas'
    ]
  },
  'Milpitas': {
    name: 'Milpitas',
    title: '越来越年轻化的城市，华人工程师的首选',
    img: Milpitas,
    population: 80430,
    description: [
        '位于湾区东南的密尔比达（Milpitas），与湾区很多其他的小型城市规模差不多。但特别的是，该市超过六成以上的人口都是亚裔，其中华人、印度人、菲律宾人、越南人都不少；而且依据统计，密尔比达居民有超过一半都是「外国出生」，在全美国所有稍具规模（超过6万5000人）的城市之中，排行第五，也是南湾新移民比例最高的城市。拥有四分之一华人血统的密尔比达前市长艾和谐（Jose Esteves）就是菲律宾移民，他33岁才移民来美，创下纪录、前后担任了12年市长，2015年才卸任。',
        '因为闹得沸沸扬扬的垃圾场扩容事件，让这座小城市成为湾区被瞩目的焦点。Newby Island Landfill垃圾场在Milpitas西边，位于圣荷西市境内，但是冬季来自太平洋的季风有时会把垃圾场以及海滩盐场的异味吹到Milpitas，这让附近的居民颇有微词。Newby Island Landfill垃圾场的味道可能会对人体产生一些危害，但是这方面的研究还在进行中，具体的危害目前还比较难以衡量和量化。除去味道这个因素，Milpitas的环境，房子本身的质量，小区环境以及部分学区都还是相当不错的。',
        '冬季Newby Island Landfill垃圾场的异味对当地房价会稍有影响，尤其是对那些距离垃圾场较近的房子。住的远一点，不会受垃圾场太大影响，因为最终支撑当地房价的主要是交通、区位和购物环境3大要素。一般来说，I-680的西侧，237号公路的北侧的区域一个月总有那么几天会经历这个味道。沿着公路880往北走，越靠近Newby Island垃圾填埋场，那么你就越有可能闻到不美好的味道。再往南一点的Great Mall附近的情况就好了很多。',
        'Milpitas拥有据称是北加州最大最高档的华人生活中心——环球广场（Milpitas Square），有超市、多种中式台式餐馆和饮品店；亚裔超市也很多，如价格优惠的海洋超市、大华99、狮子城、永兴超市，还有一家菲律宾超市；大型购物中心有Great Mall。目前Milpitas正在兴建另一所大型高档亚裔购物中心。',
        '因为交通区位好，这几年，Milpitas人数增长较快。为解决住房需求问题，市政府目前限制独立屋建设，鼓励建造共度屋和Townhouse。这也抑制了当地房价的上涨。高科技公司的年轻人往往喜欢这在这样的房子里。因此，Milpitas的居民越来越年轻化。',
    ],
    basicInfos:[
      {
        type: 'text',
        value: 'Milpitas的居民，到了夏天都不敢开窗户，需要在家中安装了空气净化器，主要原因是Milpitas有一个湾区最大的垃圾处理场Newby Island Landfill。它位于880和Dixon Landing Road交界口以西，实际是属于北San Jose的，但是由于风向大部分情况下是从西北吹向东南的原因，最大的受害者却是Milpitas的居民，尤其是在880/237一带，味道比较明显。以下是Milpitas臭味强度图。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622094368/%E6%B9%BE%E5%8C%BA%E8%87%AD%E5%91%B3%E5%BC%BA%E5%BA%A6%E5%9B%BE_ymigc6.png',
        desc: 'Milpitas臭味强度图'
      }
    ],
    schoolInfos: [
      'Milpitas的公立学校实行分班制。因为实行分班制，Milpitas高中的竞争压力也不小。每年，学校也会有大约十几名高中生被哈佛、耶鲁、斯坦福等一些美国名校录取。因此，选择住在Milpitas，家长不必担忧子女竞争不过库柏毕诺或帕洛阿图的学生。'
    ],
    estatemap: "milpitas-estate",
    related: [
      'Pleasanton', 'Dublin', 'Fremont', 'Hayward', 'San Leandro'
    ]
  },
  'San Jose': {
    name: 'San Jose',
    title: '硅谷的商业和研发中心，完善的生活配套设施',
    img: SanJose,
    population: 1030119,
    description: [
        // http://www.yidianzixun.com/article/0L9HGvCw
        // https://posts.careerengine.us/p/5f52fea371c6b97a24df3552
        // https://sa.sogou.com/sgsearch/sgs_tc_news.php?req=Y9wdA8kYHNO91zW6LR_rg9_OhFdkggHes9LMGXBYfp4=&user_type=1
        // https://www.flyhomes.com/blog/google%E5%8D%A0%E5%9C%B0%EF%BC%8C%E4%BA%A4%E9%80%9A%E5%8D%87%E7%BA%A7%EF%BC%8Csan-jose%E7%9A%84%E4%B8%8B%E4%B8%80%E4%B8%AA10%E5%B9%B4%E4%BD%A0%E8%83%BD%E5%90%A6%E6%8A%93%E4%BD%8F%EF%BC%9F/
        '圣何塞（San Jose）有“硅谷首都”的美誉，位于旧金山湾区的南部，是湾区著名的金融城市，也是全美最重要的高新产业技术区之一。该市成立于1777年，它是加州第三大城市以及旧金山地区的第一大城市，人口密度在加州排名第七，也被称为硅谷的“首都”。圣何塞地区的安全指数也很不错，根据美国联邦调查局的统计，在全美50万人口以上大城市中，圣何塞连续十几年来一直属于全美最安全的十大城市之一。',
        '圣何塞是美国人均GDP最高的城市，2019年的人均GDP是11万美元，圣荷塞市内共有1000人以上的公司25家，世界最赚钱的公司，Apple、PayPal、intel、Yahoo、ebay、HP、FireFox、Google都在这里。这也是圣荷西吸引众多人才的原因之一。圣荷西的居民十分富裕，家庭人均收入远高于全美水平。与此同时，这里的消费水平也是全加州乃至全美最高地区之一。',
        '圣何塞还有一个排名很优秀，2004年的时候获得了全美最安全城市第一名，这个统计包含谋杀、强暴、强盗、攻击、抢劫、和汽车窃盗等六个犯罪项目。',
        '由于圣荷西的经济发展迅速，城市现代化建设较为完善。并且依托其独特的地理位置，交通网络发达，到硅谷各大城市都十分便利，因此深受当地居民的喜爱，房产具有客观的升值潜力，是一个值得投资房产的城市。',
        '圣荷西由于优越的地理位置，三面环山，阻挡了大部分水汽进入，所以这里一年中大多数是晴天，且一年四季都不冷不热，不像旧金山三面环湾气温起伏那么大，也没有旧金山著名的雾气。气候宜人，全年有近300个风和日丽的好天气。',
        '圣何塞和周围地区的生活费用是全加州和全美国最高的地区之一，这里的高房价是造成这个情形的最大原因。但是，圣何塞地区也是拥有最高的可支用收入的地区之一。圣何塞的居民拥有比任何其他城市都多得美国专利、和两倍的工作效率。超过35%的全美国创业投资在圣何塞和硅谷的公司。',
        '圣荷西的商业发展十分繁荣，文化也很多元，华人在这里生活十分便利，不仅有华人餐馆和华人超市，甚至电视台每晚也会播放中文新闻和电视剧。此外，市内还有150多家购物中心，以及博物馆、艺术表演中心、美洲主题公园等娱乐休闲地点。同时圣荷西在艺术、体育等方面设施也很齐全，市内有许多博物馆、剧院以及体育中心。'
    ],
    basicInfos:[
      {
        type: 'text',
        value: '圣何塞的诺曼米内塔圣何塞国际机场，造成了San Jose、Santa Clara等地区的噪音污染~'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622156626/sjc_yaanmi.jpg',
        desc: 'San Jose噪音污染区域'
      }
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624255027/san-jose-neighborhoods_iw72jq.gif',
        desc: 'san jose neighbourhood'
      },
    ],
    schoolInfos: [
      '圣何西市内有多所学院和大学，规模最大和最为人知的是圣何西州立大学，于1862年由加州州议会立法创立，也是加利福尼亚州立大学系统的首个校区。在加州圣荷西州立大学拥有极高的名誉和口碑，被誉为“为硅谷培养精英人才”的名校。',
      '此外，圣荷西的中小学教育资源也很好，有56所8分以上的学校，其中7所是满分学校。满分高中Piedmont Hills High School是雅虎创始人杨致远的母校。之前海悦家介绍过的著名的私立学校，哈克学校也位于圣荷西。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是San Jose 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624336281/WG-ES_thmyqk.png',
        desc: 'San Jose 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是San Jose 学区 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624336280/WG-HS_ei6uwl.png',
        desc: 'San Jose 学区 高中的学区划分'
      }
    ],
    related: [
      'Milpitas', 'Santa Clara', 'Fremont', 'Sunnyvale'
    ],
    videos: [
      {
        "title": "6569 Rolling Oaks Dr, San Jose, 95120 Coming Soon Listing by Jing Xue",
        "link": "https://www.youtube.com/embed/HCFDoCDYg-8"
      },
      {
        "title": "6569 Rolling Oaks Dr, San Jose, 95120 Coming Soon Listing by Jing Xue",
        "link": "https://www.youtube.com/embed/_QfDx6os-ZQ"
      }
    ]
  },
  'Santa Clara': {
    name: 'Santa Clara',
    title: '',
    population: 129488,
    description: [
        '圣克拉拉（Santa Clara）是位于美国加利福尼亚州Santa Clara County的一座城市，成立于1777年，建市于1852年。根据美国人口调查局人口普查统计，Santa Clara是旧金山湾区人口第九大城市，距离旧金山市约45里。圣他克拉拉市地理位置很好，去哪里都很方便，环境很不错，有公园绿地、商场。',
        'Santa Clara位于硅谷中心地带，是硅谷的重要组成城市之一，许多高科技企业诸如intel、NVIDIA的总部都位于该市。上下班时间附近不管237、101公路路段，还是San Tomas Expressway，路上穿梭的都是硅谷工作的科技人。该市有加利福尼亚州的大美洲主题公园(Great America) 。是北加州的家庭休閒的胜地！公园从3 月开放到11 月。该主题公园是加州唯一一个拥有免费水上项目的主题公园, 拥有北加州数量最多的游艇，观光艇和水上汽艇。',
        'Santa Clara与San Jose、Sunnyvale等市相邻。在气候上，该地属于典型的地中海式气候。夏季干燥不炎热，冬季湿润多雨。在交通上，美国国道101、美国国道280经过该市。Santa Clara的公共交通也十分便利，距离最近的圣何塞国际机场车程仅10分钟，距离稍远的旧金山和奥克兰国际机场也只需半小时左右即可到达。',
        'Westfield Valley Fair商场就在Santa Clara与San Jose的交界处。作为全美国房价、收入最高的区块之一，Valley Fair的店家自然非常「高大上」，LV、卡蒂亚、Tiffany等国际品牌应有尽有，另外享誉中外的小笼包餐厅「鼎泰丰」，北加州唯一一间分店也座落于该商场。',
        '圣克拉拉市文化体育设施丰富。对于喜欢体育的球迷来说，49人队的主场就位于圣克拉拉。圣克拉拉新建的体育馆已经于2014 年八月开始使用。该馆可以容纳6万8500人，它也将用于舞台表演和特别事件的演出。',
        '如果是不需考虑学区，又喜欢绿地的买家，可以考虑圣他克拉拉central park附近的房产，这里环境悠闲，「公园面积大、树也多，散步起来特别舒服。」公园里还有一个人造湖，晚上不仅散步的人多，春夏季时也会有许多雁鸭停泊，「有时都比人还多，也别有一番趣味。」',
        '如果是单身或刚结婚、喜欢公寓的人，圣市也有不少选择。特别是靠近Rivermark社区附近，就在Montague Expressway旁，有Safeway等商家，离Intel、Cadence等公司都不远，很受单身族群欢迎。这片公寓区块，多半是豪华设计，自己住起来非常舒适，出租也很容易，一个单位月租通常4000元以上。',
        'Santa Clara另一个大片公寓的区块则是集中在韩国城附近。这一区韩式餐厅很多，也是圣他克拉拉县南韩商家与韩裔居民的聚集地，相对于邻近科技公司的新建案公寓，这区的屋龄通常较高。附近的南韩超市、餐厅多，韩国城附近也有许多韩式咖啡厅、甜点店。很多都是走路就能到的距离，非常方便。',
        '圣他克拉拉靠近库比蒂诺、新苹果园区的房价应该还有上涨的空间。95051附近住宅邻近Apple太空船园区，Nvidia新园区也在附近。'
    ],
    basicInfos:[
      {
        type: 'text',
        value: '圣何塞的诺曼米内塔圣何塞国际机场，造成了San Jose、Santa Clara等地区的噪音污染~'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622156626/sjc_yaanmi.jpg',
        desc: 'San Jose噪音污染区域'
      }
    ],
    schoolInfos: [
      'Santa Clara市没有比较好的学校，房子相对便宜。Santa Clara最好的大概就是Cupertino学区那一块，学校比较好，但是房价比较贵。圣他克拉拉靠库比蒂诺的小区，由于离苹果新总部、库比蒂诺Main Street商场近，这几年非常火红。几年前要价不到80万的三房独栋房，现在随便也要近200万，而且几乎没有房源。一方面当然是工作，科技业不断增加的高收入职缺；另一方面就是学区驱使，部分家长为了挤进库比蒂诺学区，愿意花高价买房。',
      'Santa Clara大学等著名学府也位于此。整座城市的教育水准在加州属于前列。圣塔克拉拉大学还曾连续15 年获得美国新闻世界报导杂志（US News & World Report ）评为美国西部大学排名第二名，是西部最有价值的学校之一。'
    ],
    related: [
      'Milpitas', 'San Jose', 'Fremont', 'Sunnyvale'
    ]
  },
  'Sunnyvale': {
    name: 'Sunnyvale',
    title: '高素质的居民，干净明亮的街区，时尚多样的配套',
    img: Sunnyvale,
    population: 153185,
    description: [
        // https://www.epochtimes.com/gb/16/3/17/n4664343.htm
        '森尼维尔（Sunnyvale）位于旧金山南部，位于南湾的圣克拉拉县Santa Clara County，与Google总部山景城、Apple 总部Cupertino接壤，距离圣荷西、圣克拉拉、帕罗奥图、萨拉托加、洛斯阿图斯等硅谷核心城市都非常靠近，是硅谷的核心城市之一。位于Sunnyvale的知名公司包括Yahoo!雅虎，Juniper等.',
        'Sunnyvale市面积约61.4平方公里，陆地面积56.3平方公里，水域面积5.1平方公里。“Sunnyvale”市如其名，一年四季几乎都享受在阳光的沐浴中。Sunnyvale享有宜人的地中海气候，夏季干燥但并不炎热，冬季温和多雨。夏季气温通常维持在25至28摄氏度之间，甚至更低，在冬季5-10摄氏度几乎是最冷温度。 ',
        'Sunnyvale最大的优势在于交通便利！位于硅谷中心城市，毗邻 Mountain View, Cupertino, San Jose, 南部的居民区离苹果公司新造的超大园区非常近，离Cupertino Village的华人社区，超市，餐厅等仅大约十分钟车程，非常方便。苹果公司的新办公园区，以及Kaiser医院也都在附近。 Sunnyvale近年还新建造了许多年轻舒适的社区，充满了活力。Sunnyvale是全美少数城市中，把多项公共安全部门合而为一的地区，这意味着公共安全的雇员需要具有作为消防员、警察和EMT的功能，他们可以在任何紧急情况下做出应对。Sunnyvale还长期位于全美最安全的前二十个城市之列。',
        '作为硅谷的重要组成部分，森尼维尔以高科技产业和航空业为主，雅虎、苹果、谷歌、LinkedIn等著名公司的主要运营中心都在这里，也是美国Onizuka空军基地的所在地，通常被称为“蓝立方”。',
        '森尼维尔的城市安全性很高，曾在全美十大安全城市榜上有名。森尼维尔是美国极少数拥有统一公共安全系统的城市之一，工作人员被培训为消防队员、警察和急救人员，他们能以三个角色中随意切换，用来应对紧急状况。',
        'Sunnyvale房价持续增长的原因主要是Sunnyvale得天独厚的地理位置，还有优秀的学区、科技巨头的入驻，Sunnyvale政府对其的建设。'
    ],
    basicInfos:[
      {
        type: 'text',
        value: '80年代San Jose一个埋于地下的三氯乙烷（TCE）储存罐发生泄漏，污染了包括Sunnyvale, Cupertino和Mountain View很多地区的用水。这些基地的土壤和地下水，都不同程度的受到过TCE的污染。为了治理这些泄漏，硅谷形成了21个superfund。湾区superfund的水，土壤和空气都已经是安全健康的级别，对公共健康和环境已经没有危害了。目前，湾区的superfund大多都已经被改造成了各类商业或者居住区。湾区所有superfund都治理的很成功，理论上已经没有了有害物质。每一个superfund都会有季度检查和5年大检，所有检查结果会公布在美国环保署的网站上。'
      },
      {
        type: 'text',
        value: '但是要买的小伙伴还是需要尽量避免房子买在superfund区域，以免影响以后卖房价格。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622094921/superfund_hpkwie.png',
        desc: '南湾Superfund site map'
      }
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624255939/district_map_SunnyvaleElementarySchoolSiteBoundaries_1_ffyphq.gif',
        desc: 'Sunnyvale neighbourhood'
      },
    ],
    residenceInfos: [
      '森尼维尔城市总人口中亚裔人口以科技工作者为主。失业率远低于全美和加州均数，多种数据显示森尼维尔发展前景良好。森尼维尔拥有舒适的居住环境和充足的就业机会，城市安全性高，城中大多数的家庭都从事科技工作，有大量亚裔科技精英生活在这里。',
      '在森尼韦尔地区学历达到本科以及超过本科的居民各占30%左右，和当地的高收入人群比例相差无几，这表示大多数高收入居民的教育水平都不会低。同时，由于高教育水平的居民占有绝大多数，当地的治安情况良好，非常适合家庭定居。'
    ],
    schoolInfos: [
      '森尼韦尔教育资源雄厚，20所公立学校中有10所公立学校平均评分为8-9分，有4所学校评分为9分（在原有评分系统是10分）。',
      'Homestead HS与Fremont HS位于Sunnyvale市，其中Homestead HS学区近邻Cupertino。与Cupertion市的两家高中相比，这两家高中的diversity（学生种族多样性）好很多，然而他们的API就会低一大截。拿Homestead HS来说，overall API仅为873，而亚裔学生的API高达930。另外Homestead High School有一位杰出校友 - Steve Jobs （苹果公司创始人和CEO，国内人称乔帮主，史蒂夫 乔布斯）。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Sunnyvale 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335332/SV-ES_wxoela.png',
        desc: 'Sunnyvale 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Sunnyvale 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335333/FUHSD-map-1_ye1bol.png',
        desc: 'Sunnyvale 高中的学区划分'
      }
    ],
    related: [
      'Milpitas', 'San Jose', 'Fremont', 'Santa Clara'
    ]
  },
  'Mountain View': {
    name: 'Mountain View',
    title: '从谷歌诞生地到硅谷核心区',
    img: MountainView,
    population: 83377,
    description: [
        '山景城（Mountain View）是美国加州圣克拉拉县（Santa Clara County）内的一座城市，山景城位于旧金山湾的西南部，面积31.7平方公里，水域0.28平方英里（约0.72平方公里），与附近的Palo Alto，Sunnyvale 和San Jose组成了硅谷的最主要地区。',
        '有3个全球财富1000强的公司的总部位于山景城，其中 包括Google公司、赛门铁克（Symantec Corporation）和Intuit。另外Mozilla公司、微软的MSN、Hotmail、Xbox、MSNTV部门、SGI和美国国家航空航天局埃姆斯研究中心（NASA Ames Research Center）等著名机构都位于该市。山景城还是美国第一座完全覆盖免费无线网络的城市。',
        '山景城拥有旧金山湾区典型的地中海气候，夏季阳光充裕凉爽干燥，冬季温暖湿润，而春季与秋季，气温和降雨都比较适宜，全年光照充足，是一座非常适宜居住的城市。炎热季节持续4.5个月左右，从6月初到10月中，每日平均高温大概22°C，低温大概13°C。 凉爽季节持续2个月左右，从11月底到2月初，每日平均高温大概16°C，低温大概8°C。山景城有好几个不错的公园。最有名的是Shoreline Park，在101东边，靠着海湾。有个小湖，可以划船，还有个很长的trail可以骑车。还有Questa park，很大的绿地和playground。有网球场，还有个专门遛狗的地方。',
        '山景城拥有4条主要的高速公路。交通便利，位于82号、85号、101号、237号高速公路中间，距离美国国家航空航天局埃姆斯研究中心只有3英里；距离Google公司总部也只有4英里。同时，山景城还拥有大量的公园和娱乐设施。山景城距离斯坦福（Stanford University) 大学约15分钟车程；距离圣荷西州立大学（San Jose State University）约20分钟车程；距离旧金山市区约45分钟车程。',
        '山景城拥有一条Caltrain，又称加州铁道，该列车为美国加州旧金山湾区的通勤铁路，连接旧金山至硅谷。最北端车站位于旧金山，最南端车站为吉尔罗伊（Gilroy）。该铁路由1863年开始运营直至现在，拥有很长的历史。列车有3种列车等级，分别是各站停车列车（Local）、快速列车（Limited）和特急列车（Baby Bullet），从山景城站乘坐80分钟可以到达吉尔罗伊站；乘坐60分钟即可到达旧金山站，出行很方便，所以该列车是当地人经常会选择乘坐的交通工具。',
    ],
    basicInfos:[
      {
        type: 'text',
        value: '80年代San Jose一个埋于地下的三氯乙烷（TCE）储存罐发生泄漏，污染了包括Sunnyvale, Cupertino和Mountain Wiew很多地区的用水。这些基地的土壤和地下水，都不同程度的受到过TCE的污染。为了治理这些泄漏，硅谷形成了21个superfund。湾区superfund的水，土壤和空气都已经是安全健康的级别，对公共健康和环境已经没有危害了。目前，湾区的superfund大多都已经被改造成了各类商业或者居住区。湾区所有superfund都治理的很成功，理论上已经没有了有害物质。每一个superfund都会有季度检查和5年大检，所有检查结果会公布在美国环保署的网站上。'
      },
      {
        type: 'text',
        value: '但是要买的小伙伴还是需要尽量避免房子买在superfund区域，以免影响以后卖房价格。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622094921/superfund_hpkwie.png',
        desc: '南湾Superfund site map'
      }
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624519311/47540_original_augxyb.jpg',
        desc: 'Mountain View neighbourhood'
      },
    ],
    neighbours: {
      "Blossom Valley": [
        "Blossom Valley曾经是一个果园，后来慢慢发展成为居民区，与Mountain View的其他社区相比，这里的街道更宽敞、房屋的Lot Size更大。在这个的房子面积在 1,200 平方英尺到 2,600 平方英尺之间，但占地约8,000 平方英尺 到 11,000 平方英尺。",
        "2009年，著名的房地产开发商 Summerhill Homes 在Satake Nursery建造了 30 套房屋，并以该家族的名义将其命名为Satake Estates。这些房屋的面积在 2,200 平方英尺到 3,400 平方英尺之间。",
        "Blossom Valley属于Los Altos学区而且靠近公司和超市，包括在附近的Blossom Valley Shopping Center，以及其他零售商店都在步行距离之内，此外距离Los Altos Village和Mountain View’s Castro Street只有很短的车程。",
        "Blossom Valley社区的公园有Varsity Park、Cuesta Park、Saint Francis Acres、McKelvey Park，虽然不大，但非常适合周末的休闲活动。Blossom Valley社区附近也有网球场、棒球场、烧烤和野餐区以及一个游乐场。",
      ],
      "Castro City": [
        "相比于Mountain View的其他区，Castro City非常适合首次购房的家庭。Castro City大多数的房屋建于 1960 年代和 1970 年代，都是设计为Ranch牧场式房屋，面积在 1,000 到 1,500 平方英尺之间。Castro City也有一部分房子建于 2001 年左右。",
        "Castro City有Mountain View最大的公园之一Rengstorff Park。Rengstorff Park 有烧烤设施、野餐区、棒球场、篮球场、滑板公园、儿童游乐场、网球场和室外排球场等设施。"
      ],
      "Cuesta Park": [
        "Cuesta Park的特点是绿树成荫的街道、迷人的路边景色。Cuesta Park街道上有许多减速带，所以车速不快，可以带着小孩步行到 Cuesta 和 Bubb park，公园附近也有野餐烧烤区。",
        "Cuesta Park 的大部分房屋建于 1950 年代，都采用统一的Ranch style，面积在 1,500 平方英尺到 2,200 平方英尺之间，lot size平均在 5,100 平方英尺到 6,000 平方英尺之间。居民可以步行前往许多生活设施，比如 Ranch 99 、Nob Hill、Marshalls 以及 Grant Hill Park 购物中心。除了步行即可到达市中心的便利设施外，该社区还靠近湾区主要高速公路，非常适合通勤。",
      ],
      "Downtown Mountain View": [
        "Downtown Mountain View，也称为老山景城，是这座城市的核心。该社区的居民享有所有这些令人难以置信的便利设施的轻松步行距离。",
        "Downtown Mountain View和Castro Street有很多有名的餐厅，提供各种美食，包括米其林星级餐厅 Chez TJ。Downtown有很多设计精美的建筑、夜总会、酒吧。Downtown大多数的房子面积从 4,500 平方英尺到 7,000 平方英尺。",
        "Downtown有两个公园，Eagle Park 非常适合野餐，而Mercy-Bush Park是一个小公园，以及拥有足球和垒球场、篮球场和排球场、游乐场和野餐区。"
      ],
      "Gemello": [
        "Gemello社区绿树成荫，距离任何主干道都只有两个街区，没有城市的喧嚣，非常安静。",
        "该社区的大部分房屋建于 1950 年代初，面积在 1,200 平方英尺到 1,900 平方英尺之间，lot面积在 5,000 平方英尺到 7,000 平方英尺之间。最近，很多居民都选择扩建或者加建二层来增加面积。",
        "Gemello 附近有很多娱乐和便利的生活设施。步行即可到达附近的 Gemello Park，El Monte Shopping Center也在步行范围内。"
      ],
      "Martens": [
        "Martens-Carmelita 有美丽的自然风光。这个社区靠近大公司和生活设施，而且环境宁静，非常适合年轻家庭定居。",
        "Martens-Carmelita 位于Bentley Square和Waverley Park之间。这边的房子建于 1970 年代后期，面积约为 2,000 平方英尺左右。有的房子的地块面积超过四分之一英亩，很多买家选择在该社区购买房产，因为他们可以选择改建更大的房子。",
        "虽然 Martens-Carmelita 没有公园，但居民可以使用 Huff Elementary的草地和操场。Stevens Creek trail位于该社区的东边，可方便地到downtown Mountain View。"
      ],
      "Monta Loma": [
        "Monta Loma是Mountain View最古老的街区之一。这边的房子lot很大，在 5,000 平方英尺到 6,500 平方英尺之间。",
        "Monta Loma 还受益于在 Mayfield Mall 旧址上建成的新 Google 园区，该园区与Monta Loma的住宅直接相邻。Monta Loma Park因其大型游乐场而深受居民喜爱。Thaddeus Park是小公园，专为小孩子而设计，有小规模的秋千、跷跷板和滑梯。"
      ],
      "North Bayshore": [
        "North Bayshore是一个商业化的社区。作为高科技圣地，North Bayshore有包括 Google、Intuit 和 LinkedIn 在内的世界知名公司。",
        "North Bayshore 还提供各种户外休闲设施，如打高尔夫球、足球、排球、骑自行车、跑步、远足、划独木舟和风帆冲浪。许多 Google 员工都使用Stevens Creek Trail骑自行车上班。", 
        "餐饮和社交活动可以去 Rengstorff House、Lakeside Café、Michael's 餐厅、Shoreline 水上运动中心和咖啡厅以及 Shoreline Golf Links。North Bayshore 的一个标志性建筑是 Shoreline Amphitheatre 的锥形帐篷舞台罩，是世界著名艺术家经常光顾的场所。"
      ],
      "North Whisman": [
        "North Whisman 社区有很多 1990 年代建造的古色古香、维护良好的single-family homes和condos，以及 2007 年左右建造的 Evandale Avenue 上的较新单户住宅。",
        "North Whisman Road沿线有谷歌的高档写字楼。 对于喜欢徒步休闲或步行上班的人来说，Whisman Road上的 Hetch-Hetchy Trail连接到 Stevens Creek Trail，可方便地到downtown Mountain View。"
      ],
      "Rex Manor": [
        "雷克斯庄园 (Rex Manor) 是一个安静的社区。",
        "最近，随着建筑商购买了状况不佳的旧房屋，然后将其拆除并重新建造，或大量扩建。Rex Manor社区的中心是Stevenson Park，有大片草地，非常适合棒球、足球和橄榄球等运动。该社区位于Mountain View的中心位置，到Mountain View学校、高速公路、餐厅、购物场所和downtown都很方便。"
      ],
      "Shoreline West": [
        "Shoreline West边界是 Villa Street、Shoreline Boulevard、El Camino Real 和 Escuela Avenue。大多数房屋建于 1940 年代，面积从 1,000 平方英尺到 1,200 平方英尺。在 1990 年代后期，建造了一波更大的房屋，面积从 1,500 平方英尺到 2,200 平方英尺。由于靠近市中心，许多购买这个社区房子的买家主要是看中土地的价值，购买以后往往会盖新房。",
        "Shoreline West的一个买点是，步行即可到达downtain和几个公园，包括 Eagle Park、Mariposa Park、Castro Park 和 Rengstorff Park。",
        "靠近所有这些大型公园意味着居民也可以使用公园的娱乐设施。比如说 Rengstorff 公园有游泳池 - 滑板公园、wade pool以及老年人和青少年社区中心。 Eagle Park 也有一个小型游泳池。"
      ],
      "Slater": [
        "Slater的房屋大多状态不好，因此斯莱特的房价在Mountain View更加亲民。Slater上高速公路和到市中心的生活设施都很方便。",
        "Google谷歌和Symantec赛门铁克的办公楼就在North Whisman Road对面。 居民人口统计中，Slater以年轻的高科技公司工程师和年轻家庭为主。",
        "Slater的休闲娱乐设施有Creekside Park 和 Slater School Park 以及 Clocktower Coffee 和 Roger’s Donut Deli。"
      ],
      "St. Francis Acres": [
        "St. Francis Acres是一个安静、绿树成荫的社区。房屋面积在 5,000 平方英尺到 5,700 平方英尺之间，这边允许居民加盖第二层楼。",
        "社区里面没有主路，所以车上不是很快，小孩子可以安全地在街上玩耍。 此外，社区居民可以步行到 El Monte Shopping Center，以及 Miramonte Avenue上的 McKelvey Park，公园里可以打棒球或垒球。"
      ],
      "Sylvan Park": [
        "Sylvan Dale 位于硅谷的中心地带，但非常安静惬意，有远离快节奏都市喧嚣的感觉。",
        "社区中心是 Sylvan 公园，这是Mountain View最大的公园之一，有足球区、排球场和网球场以及慢跑道。Sylvan Park 周围是建于 1970 年代中期的大型single family house。",
        "这里的房子面积大约 10,000 平方英尺左右，基本都是大约 3,000 平方英尺的大房子。Sylvan Dale 社区每栋房屋都有独特的风格和外形，没有两个完全一样的房子。 "
      ],
      "Waverly Park": [
        "Waverly Park最初是一个杏子和西梅果园，现在已经成为Mountain View最高档的社区。这个社区在很多方面都Mountain View山景城最好的，社区里有很多lot很大的房屋和社区中央也有可供周末聚会的大公园。韦弗利公园 (Waverly Park) 的大部分房屋都是在 1960 年代至 1980 年代之间分阶段建造的，通常面积超过 3,000 平方英尺。",
        "Waverly Park社区不仅房屋比其他街区宽敞得多，还拥有丰富的休闲场所。Cooper Park有网球场和大片草坪，经常有附近的小孩在公园里踢足球。",
        "韦弗利公园 (Waverly Park) 附近的 Stevens Creek Trail 上步行和骑自行车非常方便，可骑自行车前往 Baylands 和 Google。购物方面，附近的居民可以去位于 Nob Hill 的 Grant Road 或 Ranch 99 轻松购买，距市中心仅 10 分钟路程。"
      ],
      "Whisman Station": [
        "Whisman Station 是一个 1998 年的住宅开发项目，包括联排别墅和single family house。社区以Magnolia Park为中心，Magnolia Park里面有草地、喷泉和游乐场，傍晚附近的居民大多会带孩子在公园散步。Whisman Station还有一个公园是Chetwood Park。",
        "Whisman Station的居民主要以高科技行业为主的年轻夫妇。Whisman Station位于Mountain View市中心，方便上下班；Caltrain是在Castro Street的Mountain View Station。",
        "Whisman Station与Castro Street之间有一座人行天桥，居民只需要 15 分钟就可以步行到downtown。"
      ],
      "Willow Gate": [
        "Willow Gate 社区有各种各样的住宅，包括 Cypress Point Lakes Condominiums、在 Willow Street 上还有类似于太浩湖的乡村小屋。",
        "从Willow Gate可以轻松步行穿过Central Expressway即可到达市downtown和Caltrain。"
      ]
    },
    residenceInfos: [
      '由于历史原因，山景城有很多老墨，而他们都喜欢集中在一起。在分区图中，老墨比较集中的一片是Monta Loma。具体在街道上，就是California 和Rengstorff一带。学校和房价上来说，基本上El Camino是个分界线。El camino西边的学校比东边好，房子也更贵。',
    ],
    schoolInfos: [
      '山景城内比较好的小学有两个。Frank L. Huff School和Benjamin Bubb Elementary， API 分别是960和920。其他都800左右甚至不到。所以山景城本身并没有多少好学校。但是，山景城南边的一小部分是归Los Altos的Oak Elementary，Shoreline 和Springer之间的一部分，是归Los Altos的Springer小学。这两个都是非常好的学校。当然，这些地方房价就更贵。',
      '山景城市内常用三个邮政编码，分别是94040，94041和94043。其中，94040学区最好。共有3800多个商业地址，36800多栋私人住宅，其中独立屋仅20500多栋。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Mountain View-Los Altos 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335040/MVLA-Schools_ruhjlt.png',
        desc: 'Los Altos 高中的学区划分'
      }
    ],
    related: [
      'Milpitas', 'San Jose', 'Fremont', 'Santa Clara', 'Sunnyvale', 'Palo Alto'
    ]
  },
  'Palo Alto': {
    name: 'Palo Alto',
    title: '硅谷核心城市，加州最好的学区',
    img: PaloAlto,
    population: 66666,
    description: [
        // http://hhause.com/newsDetails/1361
        // https://www.flyhomes.com/blog/school-district-you-need-to-know/
        // http://cathyliurealtor.blogspot.com/2019/05/palo-alto.html
        'Palo Alto位于加利福尼亚州旧金山湾区、圣克拉拉郡的西北角，是一个宪章城市（Charter City），即拥有本市自己的宪章文件，像是我国的自治区。Palo Alto之所以闻名于世，因为这里有著名的高新科技园——硅谷，汇集了众多大名鼎鼎的企业，例如惠普、特斯拉、Skype、Space Systems、福特研发中心、谷歌、脸书、罗技等。许多人将Palo Alto称为硅谷的中心，因为它拥有很多高科技公司。据说是由两位当时在史丹佛大学研究室工作的威廉•休利特（William Hewlett）与戴维•帕卡德（Dave Packard）在他们房子的车库里于1939年成立了惠普公司 (HP) 后，由此便带动了了硅谷的出现。至今，惠普的总部仍位于Palo Alto。',
        '很多科技公司的 CEO 都选择在此居住。谷歌的 Larry Page, Facebook 的 Mark Zuckerberg,已故的苹果总裁Steve Jobs 都在Palo Alto图有房产。因其地理位置绝佳,Palo Alto的房产普遍增值。',
        'Palo Alto和加州其他城市一样，是亚热带地中海气候，夏季干燥，冬季湿润。最热月8月平均高温为26.4℃，平均低温为8.9℃；最冷月12月平均高温为14.6摄氏度，平均低温为3.3摄氏度。Palo Alto的雨水天气集中在冬春季节，平均年降雨量为411毫米，属于半干旱地区。这里空气质量好，拥有许多重要的自然栖息地，包括河口、河岸和橡树林。Palo Alto的面积为66.4平方公里（25.6平方英里），其中61.3平方公里（23.7平方英里）是陆地、5.1平方公里（2平方英里）或7.6%的面积是水。',
        '位置和交通：位于硅谷的中心区，Google，facebook都在附近，交通四通八达，北上三藩，南下Mountain View，Cupertino 都很方便。Palo Alto距离旧金山市中心和机场、圣荷西市中心和机场车程都不足1小时，距离Google、FaceBook等公司总部也不足20分钟车程',
        'Palo Alto是湾区最贵的城市之一，可以细分成20多个社区，每个区域有着不同的特点。Palo Alto排在前三名的依然是最负盛名的Old Palo Alto、Crescent Park和Professorville。'
    ],
    basicInfos:[
      {
        type: 'text',
        value: 'Palo Alto作为硅谷“诞生地”，汇集了大量知名企业，例如惠普、特斯拉、Skype、SpaceSystems、福特研发中心、谷歌、脸书、罗技等，加上后期的高房价和高成活成本的门槛，使得这里的居民大多是高净值人群。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624257764/https_______mmbiz.qpic_yxpjn0.jpg',
        desc: 'Palo Alto硅谷“诞生地”'
      },
      {
        type: 'text',
        value: '斯坦福大学的购物中心就在附近，是湾区最大的购物中心，里面各种品牌应有尽有。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622310356/Stanford-Shopping-Center-1280x720_fgdp0d.png',
        desc: '斯坦福大学的购物中心'
      },
      {
        type: 'text',
        value: '全美房价最贵的几个zip code，Palo Alto排名第五，湾区仅次于Atherton，可见Palo Alto是名副其实的顶级豪宅区。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622310613/559aecb26bb3f7d0598ebcbe_c5xrk5.jpg',
        desc: 'the most expensive ZIP codes in the US'
      }
    ],
    neighbourInfos:[
      {
        type: 'text',
        value: 'PaloAlto共有两个中心：Downtown Palo Alto，位于UniversityAvenue，有许多餐厅与商店；Business District，位于CaliforniaAvenue与历史性道路ElCamino Real的交叉处。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624255598/PA-Color-Map_mnanzr.jpg',
        desc: 'Palo Alto硅谷“诞生地”'
      },
    ],
    neighbours: {
      "Adobe Meadows": [
        "Adobe Meadows是South Palo Alto最活跃的房地产市场。"
      ],
      "Old Palo Alto": [
        "Old palo alto是palo alto起家的地方，是一个传统富裕优雅的社区，这里住着许多世界上最富有的人。这个社区树木繁茂，草坪郁郁葱葱，有许多豪宅，房子平均占地9,000平方英尺，比Palo Alto其他地方大得多。生活配套成熟，可以轻松步行至California Street的商店、餐馆和农贸市场。",
        "Gamble Garden 和 Bowden Park 的绿地，适合当地居民的户外休闲活动。",
        "已故的苹果公司创始人Steve Jobs就曾居住在Waverley Street，Google co-founder Sergei Brin也居住在这里。"
      ],
      "Downtown": [
        "Palo Alto最早的社区之一。最早因为斯坦福大学建设而兴起，至今仍然保留着当初住宅、商业、零售的格局。这里商业设施发达，生活方便。可以轻松步行至市中心购物区，餐饮、咖啡馆、公园、商店、剧院等非常丰富。但相对来说交通较为拥挤，停车也比较困难。由于离斯坦福大学很近，备受年轻人和年轻家庭追捧。这一区公寓很多，独立屋面积也相对较小。",
        "这个社区还有占地2.4 acre的Johnson Park。"
      ],
      "Professorville": [
        "离斯坦福大学很近，许多斯坦福的年轻教授在这一带定居，这一区也因此得名。文化气息浓厚，街道安静且绿树成荫，有些房子也成为了历史遗迹。Professorville是政府规定的历史街区（Historic District），会有很多restricion，通常这里的房子不能拆除，如果有任何改动也要遵循很严格的规定，所有大型工程都要通过历史资源协会批准。由于年代较远，车库不足，街道较窄，停车不太方便。",
        'Professorville地区历史悠久，包括惠普 (HP) 车库等地标建筑；被许多人视为硅谷的发源地。',
        "住在Professorville的居民可以步行到达downtown、El Camino Real、CalTrain 车站、Palo Alto High School，当然还有斯坦福大学。"
      ],
      "Community Center": [
        "社区拥有很多娱乐设施，包括艺术剧院，拥有占地超过19 acre的 Rinconada Park，以及儿童图书馆等。这里可以看到很多 Craftsman 或者Mediterranean建筑风格的建筑，很多房屋都有地下室。紧邻101高速，到University Avenue上的购物区也房产方便。",
        "这个社区每年上市的房子屈指可数，一旦上市就会有很多offer，从侧面也印证了这个Community Center的受欢迎程度。"
      ],
      "Crescent Park": [
        "这里是Palo Alto最有名的社区，宁静，优雅和可以说是无可挑剔社区，社区维护得非常好，虽然离市中心繁华的University Ave只有几个街区，但这个社区完全是另一个风格。宽阔的树冠街道，提供了幽静的社区环境。同时由于紧邻101高速，又结合了便利的交通优势，吸引了众多行业大佬的青睐，包括Facebook创始人扎克伯格，他就居住在新月公园 (Crescent Park) 。这个地区位于洪水区，房主必须购买洪水保险。这里的房子不能建造地下室。",
        "Crescent Park 地理位置优越，前往Palo Alto购物区以及 101 号高速公路 非常方便。Crescent Park还有Palo Alto第二大的Eleanor Pardee Park，公园里有游乐场、野餐区和步行道。"
      ],
      "Green Gables": [
        "Green Gables由几个Neighborhood组成，包括Duveneck/ St. Francis，Embarcadero Oaks，Garland，Leland Manor和Triple El，这里的许多街道都绿树成荫。这些Neighborhood各有特色。Duveneck / St.Francis与Crescent Park比较像，豪宅比较多。Triple El有许多Joseph Eichler设计的Eichler房子。Fulton Street是湾区著名的年度“圣诞树巷”。",
        "这个社区上Highway 101非常方便。"
      ],
      "College Terrace": [
        "这个区靠近斯坦福大学，小而安静的街区，许多居民都与斯坦福关系密切。不少终身教授住在这里。这里大部分房子都被斯坦福所有，只能由斯坦福的教职工才能购买。靠近California Avenue的餐厅和商店以及斯坦福大学。这边的房子不像Cresent Park的房子一样豪华。",
        "社区里有Palo Alto Main Library，the Children’s Library，Palo Alto’s Rinconada Park，Junior Museum 和 Zoo"
      ],
      "Midtown Palo Alto": [
        "这个社区位于Palo Alto中部，社区相对更为整齐和配套设施成熟。这里有很多公园，图书馆，play ground，生活非常方便，住宅风格多样。近年来不少华人工程师进入Palo Alto 的首选地，这里也有不少rebuild的住宅。Midtown近些年来很受亚洲人的喜爱，也出现了很多新建豪宅，社区相对更为整齐，生活非常方便，住宅风格多样。这里有很多新建的房屋。房屋占地大概在6000sqft~8000sqft之间。相对于Palo Alto的的其他区，它是Palo Alto的“入门级”社区 。",
        "该区域紧邻 Oregon Express，Bayshore Fwy 两条快速路，临近 Palo Alto 机场，交通非常便利。",
        "其房屋建筑相对比较老旧，最老的房屋可追溯到 1890 年，整个社区规划在早期做的非常好，建筑风格相对统一，目前仍是一个居住舒适的社区。周边生活设施完善，临近 Byxbee 公园、Bayland Nature Preserve ，向南即是斯坦福大学，还有三个小型的公园Hoover, Greer and Seale Parks，居民生活环境优美舒适。",
        "随着硅谷房价水涨船高，Midtown 社区越来越被很多个人和开发商所重视，很多房屋做了翻新与重建之后，这里的房价和成交量都有了明显的提升。",
        "对于想兼顾affordability, accessibility, 娱乐和生活质量的年轻人来说，Midtown无疑是最佳的社区。"
      ],
      "South Palo Alto": [
        "South Palo Alto 有几个Neighborhood组成，包括：Palo Verde，Adobe Meadow /Meadow Park，Charleston Gardens，The Greenhouse，Greendell，Green meadow，Walnut Grove，Fair meadow，Charleston Meadow，Monroe Park等。这个大的社区有很多Eichler风格的住宅，受Eichler房屋风格的限制，翻新也需要有Eichler的元素，并不可以随意翻新。而且不少区域不能建筑二层。在购买房屋的时候，需要注意这些限制。对于希望在Palo Alto定居的年轻家庭来说，相对于Palo Alto的的其他区，South Palo Alto是一个极具吸引力的行政区，它是Palo Alto的“入门级”社区 。",
        "这个区域集中了 60 年代建造的房屋，房屋面积属于中等大小，配套的公共设施非常完善，有宽阔的街道，和方便易达的公园，比如 Mitchell Park Library and Community Center 等公园，社区的街道有交通限制，社区生活既和谐又安全。"
      ],
      "Ventura": [
        "Ventura 这个区位于Alma 和 El Camino 之间，距离El Camino Real 沿线的购物商场和餐馆很近，很多生活设施步行就可到达，价格相较其他Palo Alto其他社区较低一些。但是街道较窄，房子占地较小。而且离铁路轨道较近，需要注意铁路声音的问题。"
      ],
      "Barron Park": [
        "这里曾经是一大片果园，现在还能看到各种果树，郁郁葱葱的自然景观区，拥有许多参天大树。整个社区sidewalk相较于其他社区较少，非常安静。这个区这几年开始不断有年轻家庭涌入。由于不在洪水区，区内翻新、重建的住宅多带有地下室。如果喜欢安静的生活，但又不想远离城市的便利，那么Barron Park是不错的选择，而且还有占地13-acre的Bol Park，相对于Palo Alto的的其他区，它是Palo Alto的“入门级”社区 。交通便利，可以轻松到达Los Altos 和 California Avenue商店和餐馆。"
      ],
      "Green Acres": [
        "Green Acres由三个Neighborhood组成:Green Acres 1，Green Acres 2和Palo Alto Orchards, 紧邻North Los Altos。Green Acres相对是个较小的社区。住宅平均占地7,500 至 10,000 square feet，平均面积为 1,600 至 2,000 square feet。Green Acres 的居民步行即可到Terman Park和Juana Briones Park。Juana Briones Park有足球场、棒球场以及网球场和篮球场，是体育运动的理想场所",
        "中学和高中是Terman Middle School 和 Gunn High School",
        "附近的公园有Terman Park 和 Juana Briones Park.",
      ],
      "Palo Alto Hills": [
        "位于280高速北面，同时被大片自然保护区环绕，这一区的自然环境和景观一流，拥有幽静的田园风光。位置相对偏僻，人口不多，只有不到 100 套住宅，交通不是特别方便，大多都是豪宅庄园，这个社区的特点是房子大占地大，有的房子可能有好几英亩。",
        "比邻Pearson-Arastradero Preserve，占地533 英亩，可以欣赏海湾美景，而且还有一条10.25 miles的trails。Palo Alto Hills Golf & Country Club就坐落在此。",
        "Palo Alto Hills非常像Los Altos Hills和Portola Valley，完美符合人们的期待，“so close to everything yet so removed” “如此接近一切，却又如此疏远”"
      ]
    },
    residenceInfos: [
      '根据2019年全美人口普查，该市居民平均受教育程度全美最高，这里也是全美生活成本最高的城市之一。',
      'Palo Alto居民华裔是亚裔中的主力军，亚裔比例较大，也表示华人人口比例较多。作为美国人均受教育程度和家庭收入较高的城市，Palo Alto受到华人精英家庭的喜爱。在华人众多的地方，希望移居于此的华人家庭能够迅速融入当地生活。',
      'Palo Alto高等教育人口占比是80%，在人才济济的硅谷几乎占比最高，失业率远低于全美和加州均数，家庭收入是全美中值的2倍多，多种数据显示帕罗奥图的发展前景良好。'
    ],
    schoolInfos: [
      'Palo Alto学区非常优质，已经连续多年被评为加州最好的学区，公立教育系统和私立教育系统都优秀。',
      'Palo Alto教育资源非常充足，世界著名的斯坦福大学（Stanford University）坐落于此，为硅谷培养了众多高科技产品领导者和创业型人才，是美国培养最多亿万富豪的大学。市内共有101所学校，其中84所为私立学校、15所公立学校。比较知名的小学和中学有，评价9分的Palo Verde Elementary School，评价8分的 Jane Lathrop Stanford Middle School 等，是名副其实的好学区！',
      '作为Niche排名第一的学区，这里从小学到高中学区都很好，全市共有学校104所，其中公立学校19所，90%以上都在8-10分。学区内有两所满分高中，Palo Alto high和Gunn High，都是US News排得上号的好高中，一直以来都是很多家庭的梦想的学校。前者重艺术Liberal Art，后者重科学Science。在新的评分标准下，Henry M. Gunn High School因为少数族裔和低收入家庭学生占比过低，而评分下调，降至9分，但教学质量依然一流。',
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Palo Alto 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624334786/PA-ES_lbdvli.png',
        desc: 'Palo Alto 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Palo Alto 中学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624334786/PA-middle_pw9nxf.png',
        desc: 'Palo Alto 中学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Palo Alto 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624334788/PA-high_urv79f.png',
        desc: 'Palo Alto 高中的学区划分'
      }
    ],
    estatemap: "palo-alto-estate",
    related: [
      'San Jose', 'Fremont', 'Santa Clara', 'Sunnyvale', 'Mountain View', 'Campbell', 'Menlo Park'
    ]
  },
  'Menlo Park': {
    name: 'Menlo Park',
    title: '湾区的新贵族城市',
    img: MenloPark,
    population: 66666,
    description: [
        // https://www.epochtimes.com/gb/16/10/16/n8401966.htm
        // https://www.epochtimes.com/gb/16/10/31/n8445946.htm
        'Menlo Park现在最为人熟知的应该是脸书（Facebook）的总部设立在此。脸书也带动了当地的房价、房租市场，尤其在他成为上市股票公司之后，更是上涨底气十足！Facebook公司向住在距离公司10英里范围内的员工提供住房补贴：单身员工1万美金的补贴；对结婚家庭补贴15,000美金。这诱人的奖励是导致门罗公园的房价上涨的一个因素。',
        'Menlo Park也是美国风险投资的金融中心，世界上最强大和最活跃的风险资本家全集中在这里。其中，沙丘路（Sand Hill Road）更是是一个全国知名的道路，著名的风险投资公司都沿这条路展开。这些公司的投资造就了许多知名的硅谷公司，如：亚马逊（Amazon）、思杰（Citrix）、艺电（Electronic Arts）、基因泰克（Genentech）、谷歌（Google）、Intuit和赛门铁克，当然也包括脸书。',
        'Menlo Park分别和南边Palo Alto、北边Atherton接壤，夹在这两个超级富豪城市，这三年Menlo Park的房价似乎也不甘示弱，每个月都能见到几处四、五百万的房产销售价，实在令人咋舌！',
        'Menlo Park是狭长形的城市，从280公路一直贯穿到101公路，到达湾内的沿海。Menlo Park作为旧金山和硅谷之间的纽带，吸引着众多家庭、企业投资者的入驻，有名的斯坦福购物中心就在Menlo Park的Allied Arts区的隔壁。',
        '圣克鲁斯大道（Santa Cruz Ave.）是市中心的主要干道，这里有各种商店，从高档服装、家具店到餐馆、超市，一应俱全。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624435768/Menlo-Park-Map-Neighborhood_klxfnt.jpg',
        desc: 'Menlo Park neighbourhood'
      },
    ],
    neighbours: {
      "Allied Arts": [
        "Allied Arts 位于Menlo Park的市中心，也叫Stanford Park，著名的斯坦福购物中心 (Sanford Shopping Mall) 就在Allied Arts的隔壁。由于这里接近斯坦福购物中心，非常受年轻买家的欢迎，这也是Allied Arts的卖点之一。",
        "普林斯顿路（Princeton Rd.）和耶鲁路（Yale Rd.）属于较昂贵的街区，越靠近 El Camino Real的房子房价就越便宜。 ",
        "高中属于Menlo-Atherton高中，虽然在湾区学区房中没有Palo Alto学区有名，但也是湾区的顶级学区。"
      ],
      "Downtown Menlo Park": [
        "圣克鲁斯大道（Santa Cruz Ave.）是Downtown Menlo Park的主要干道，这里有各种商店，从高档服装、家具店到餐馆、超市，一应俱全。",
        "高中属于Menlo-Atherton高中，虽然在湾区学区房中没有Palo Alto学区有名，但也是湾区的顶级学区。"
      ],
      "Central Menlo": [
        "Central Menlo是Menlo Park最贵的社区，房价通常都要260万美元以上，较大或较新的房子都是400万以上。Central Menlo的房屋以ranch style 为主，占地平均在10,000 sq.ft. 左右。在Hermosa Way或者Robert S. Drive上的房子lot size都在0.5 acre左右。Central Menlo的房子虽然贵，但有钱的人就是喜欢买这个区的房子，而且offer的价格往往会高出listing price很多。",
        "高中属于Menlo-Atherton高中，虽然在湾区学区房中没有Palo Alto学区有名，但也是湾区的顶级学区。"
      ],
      "The Willows": [
        "The Willows是一个三角地带，在Willow Road和101公路之间。The Willow和Palo Alto紧邻，中间被San Francisquito 小溪隔开。这里的房屋建筑主要以modest Bungalows和grand Craftsmen为主。 ",
        "这里的房子大部分占地都在5,000sq.ft左右, 而且 lot都较狭长，但是也有一些较大的lot的房子。因为临近Facebook，近几年The Willows住进不少高科技公司的白领。The Willows 绿化很好，社区安静，学区优秀，环境优美。不少新屋主都把房屋升级或重建。"
      ],
      "Sharon Height": [
        "Sharon Height是Menlo Park城市中一个占地1.8平方英里的独特社区，最初由Duncan McDonald和Mark Radin在1960年代开发用于住宅目的。Zip code为94025和94027。北边以Trinity Avenue和Sharon Heights高尔夫乡村俱乐部为界；东边以Santa Cruz Avenue为界；南边以Sand Hill Road和San Francisquito Creek为界；西边则与280号高速公路交汇，通勤方便。",
        "Sharon Heights以Sharon Heights高尔夫乡村俱乐部的名字命名，这个俱乐部极富贵族气息。可以提供包括高尔夫场地、健身中心、网球场、宴会厅等在内的设施。Sharon Heights的街道上树木成荫，优雅地连绵起伏于山丘之中，为居民提供美如画的风景及绝对私密性。在sand hill road 北边，环境优美。Sharon Heights的lot都较大，平均在0.25 acre。社区内就有当地热门公园——沙龙公园（Sharon Park），以及附近的斯坦福山公园（Stanford Hills Park），是周末全家休闲、野餐的绝佳去处。走在湖边看看野鸭、喂喂鱼，让心情获得彻底放松。",
        "紧邻斯坦福大学，Sharon Heights的学区也同样是一流水准，教学水平优质，共有4所评分都在8-10分的公立学校和一所私立学校。包含评分10分的Las Lomitas小学，以及La Entrada中学和Menlo-Atherton高中。",
        "随着越来越多的中美富商和高科技新贵进入这片社区，曾经的大片庄园逐渐被新建豪宅取代，Sharon Heights也发展成为一个高档社区。",
        "在这里买房要留意土壤和边坡的问题，有些房子可能有需要加固地基。",
        "Sharon Heights的房源极为稀少，一年上市的房产屈指可数。Sharon Heights因为高尔夫球场和风险投资公司的聚集，逐渐成为一个高档社区，南边的沙丘路（Sand Hill Road）就是风投公司的“风水宝地”。",
        "Sharon Height拥有奢华酒店Rosewood Hotel & Resorts，以及酒店内的米其林1星餐厅Madera。附近还有Menlo Park图书馆（Menlo Park Library）、Sharon Heights购物中心（Sharon Heights Shopping Center）和斯坦福购物中心（Stanford Shopping Center）等，方便居民的一切生活需求。"
      ],
      "Stanford Hills": [
        "Stanford Hills是一个被夹在沙丘路和高山路（Alpine Road）之间的小社区，仅有78户。Standford Hills属于斯坦福大学的出租土地，但是物业可供开放购买。此社区以Branner Dr.作为唯一进出口，社区安静，环境优美，因此隐私性非常好，而且平时车辆很少。"
      ],
      "Flood Park Triangle": [
        "Flood Park夹在Bay Road、Marsh Road和101公路之间，因临近101公路，会有一些噪音，所以多少影响了该区房价的上涨。就学区来说，这里还算不错。这里的房子lot大部分在6,000sqft以下，因为大部分这个区域的老房子以3房1卫为主，所以买了这个区域房子的买家大多选择加建或者改建。"
      ],
      "Menlo Oaks": [
        "Menlo Oaks有乡村和田园般的气息，人行道不多，路灯也少，街道比较狭窄，但是布满橡树、桉树和常绿树，随时都像走在林荫大道上。",
        "这里有一所很有名的私立学校 – 半岛学校（Peninsula School），有超过80年的历史和卓越的教学品质。Menlo Oaks还有一所历史悠久的神学院St. Patrick‘s Seminary & University，是一个历史悠久的天主教机构。Menlo Oaks的房子都是single family home，没有公寓类型的房产，靠近Willow Road的房子房价较低。",
        "需要注意的是，部分Menlo Oaks的区域是unincorporated，直属San Mateo County。"
      ],
      "Belle Haven": [
        "Belle Haven是101公路东边的一个三角地带，早期的大萧条期间，这里被规划为经济适用房区域，房屋平均在800 sq.ft.~1,500sq.ft.。虽然靠近Facebook总部，但仍然是Menlo Park最便宜的社区之一。",
        "房价低的原因，也来自学区问题，主要是Belle Haven小学评分不高。2008年经济危机的时候，Belle Haven的房价惨跌，有一半属于不良房产，2010年被投资者大量购买，改成了租贷物业。"
      ],
      "West Menlo": [
        "West Menlo Park属于unincorporated area，并不属于Menlo Park，West Menlo Park公共服务归San Mateo直接管辖。West Menlo Park房子直接的间距较小，房价也比Menlo Park大部分区域的房子便宜，面积也更小，一般占地约6,000sq.ft.。不过，West Menlo Park的学区却不输给Menlo Park较贵的社区；而且居民的生活机能更方便，距离商店更近。",
        "West Menlo Park的建筑法规相对宽松，所以在这里经常可以看到6,000sf.qt.的lot上建起双车库的2,500sqft以上面积的房子。"
      ],
      "Linfield Oaks": [
        "Linfield Oaks和Felton Gables夹在El Camino Real和Middlefield Road之间。Menlo Park的市政楼、警察局、图书馆都在这个区域。Caltrain也横穿此区。",
        "Linfield Oaks有两大社区，一个是Taylor Morrison 的Morgan Lane，拥有56 栋Victorian-style 的独栋别墅社区；另外一个是Summerhill Homes的 Lane Woods，拥有32栋Craftsman-style的独栋别墅社区。",
        "Felton Gables是一个非常小的社区，被夹在加州火车铁路和Encinal小学中间。社区原本是一位美国参议员的私人财产，在上世纪的30、40年代被分成115房产，现在这些房子仍保有很多当初的结构，而且限制拆除。",
        "Felton Gables的左侧是Holbrook Palmer 公园，部分居民从后门就能走到公园，而且旁边就是很棒的小学Encinal。不过Felton Gables有个问题，这个区居民进出都依靠Encinal Ave，到了学校上下学时间，就有交通问题。",
        "Felton Gables、Linfield Oaks两区都有高铁兴建的困扰，一但高铁决定延伸到旧金山，这两区的部分居民就有可能需要搬离。"
      ]
    },
    residenceInfos: [
    ],
    schoolInfos: [
      "高质量的教育：高水准的小学和中学（门罗公园市小学区和Las Lomitas学区拥有API分数925或更高的分数）"
    ],
    related: [
      'San Jose', 'Fremont', 'Santa Clara', 'Sunnyvale', 'Mountain View', 'Palo Alto'
    ]
  },
  'Atherton': {
    // https://www.epochtimes.com/gb/16/8/15/n8201071.htm
    name: 'Atherton',
    title: '湾区的美国顶级豪宅区，硅谷大佬云集',
    img: Atherton,
    population: 66666,
    description: [
        'Atherton位于硅谷中心区域，紧邻斯坦福所在的Palo Alto地区。Atherton是全美最富有的小镇之一，现在已经变成硅谷的董事、高管们，以及风险投资人和富豪们的住宅区。相比大名鼎鼎的Palo Alto, Atherton要低调的多。Atherton位于Palo Alto和Menlo Park旁边，依靠着Google, Facebook, Linkedin的园区，距离斯坦福校园也很近。',
        '很多硅谷巨头，包括微软的联合创始人Paul Allen, 惠普的CEO Meg Whitman, 和 Google的chairman Eric Schmidt, Facebook的COO Shery Sandberg 都居住在这里。根据2016年Forbes排行，94027是全美第三贵的邮编。Atherton的房子并不是有钱就能买到的，这个区基本都是Old Money，祖上就是大富大贵的，对IT新贵是可望而不可及的。',
        'Lloyden Park坐落于El CaminoReal和铁路之间，以Wilburn Ave与Lloyden Dr为界线。Lloyden Park是Atherton所有社区里最具有郊区风格的。Lloyden Park在1920年被分成了86个独立家庭住宅区。每座住宅占地面积在三分之一英亩左右。这里是小镇里唯一有地下设施、街灯和人行道的社区。',
        'West Atherton区的特点是独特，醒目，豪华。历史上，这里曾是Faxon Dean Atherton的一块600英亩的私人牧场。现在它成了最贵的住宅区。Atherton先生的房屋旧址即是现在的Menlo Circus俱乐部。West Atherton的街道宽阔。道路两旁种着老橡树、雪松和松树。这里没有人行道，道路充满着乡村气息。一排排的栅栏和精致的大门使路过的人只能瞥见豪宅的一角。虽然有大门和栅栏，许多漂亮的房子还是为街道增色不少。',
        '在Alameda和280高速路之间的West of the Alameda被称为“上层市场Upper-Market”。这是一个独特的社区，特点是富有而宏伟。这里的居民之所以选择这个社区是因为他们更喜欢这里的隐秘性而不是像West Atherton和Lindenwood那种离市区更近的环境。这个区被橡树、小溪和山坡环绕。它的引人之处是房子和周边的翠绿环境融为了一体，旧金山半岛的迷人风景在此展露无遗。房子和周围的绿树之间的距离大，使房子显得更幽静隐秘。你仿佛觉得深在郊外，其实这里距离Menlo Park市中心只有几分钟的车程，上280高速路也很方便。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624435768/xAtherton-Neighborhood-Map.jpg.pagespeed.ic.z5riWWnK2N_phhzqh.webp',
        desc: 'Atherton neighbourhood'
      },
    ],
    neighbours: {
      "Atherton Oaks": [
        "Atherton Oaks有宽敞的房子，绿树丛荫的环境和良好的学校。这里的许多房屋是在一块或几块地中央建造的一层楼的住房。但也有旧的大房子点缀在不同的道路之中，因为过去的旧金山人很愿意住在离Fair Oaks火车站近的地方。",
        "过去的老房子占地并不大，但次现在这里的房子要求占地面积1英亩或更大。所以这个社区的房子分为两种：1）一种是占地面积小于3万平方英尺的房子；2）是占地面积大于3万平方英尺的房子。两种房型的中位数房价大约差了两百万美元。",
        "一般来讲，在Atherton Oaks，地点决定售价。最贵的地段在树林边上，Fair Oaks路的尽头，比如Virginia路和Mount Vernon路。靠近繁华街道或车站地段的房价要比里面的房子低15%左右。然而这些地方依然有Atherton典型的宽敞楼层设计、老树和修整的庭院。",
        "在Atherton Oaks的孩子们上Encinal小学（2013年API成绩930），小学坐落在小区内，属于Menlo Park市小学区。到了六年级上Hillview中学（2013年API成绩950），属于Menlo Park市中学区。到了高中，学生们上Menlo-Atherton高中（2013年API成绩819）。这个高中有很好的AP课程。但也有不少家庭选择上这里的私校，比如Sacred Heart或Menlo。",
        "Holbrook Palmer是Atherton社区里唯一的一座公共公园。1968年以前是私人拥有的，现在它保有历史建筑，有网球场、幼儿园和棒球场。另外，Fair Oaks火车站、Atherton图书馆、邮局和市政厅离这里都只有几个街区。",
        "这个社区离斯坦福大学的活动场所也只有几分钟的路，离几个私人俱乐部很近，在这些俱乐部可以游泳、打高尔夫球、打网球、骑马。",
        "虽然这区有一些新的房子，但房价没有隔壁Lindenwood社区升值得快。主要因为这里的环境略逊于Lindenwood。但如果你想找一个有好学校，大房子的社区，Atherton Oaks仍是很好的选择。"
      ],
      "Lindenwood": [
        "当你沿着Middle Field路行驶在Lindenwood的林荫道上，美景会让你忘却时间。这里郁郁葱葱的林荫隧道，蜿蜒的道路和热爱散步的居民让Lindenwood充满老式优雅的气息。",
        "这个优雅的氛围要追溯到19世纪，这里曾是“银矿之王”Jame. C. Flood的夏日别墅。他在这块600英亩的土地上建造了有44个房间的“Linden Towers”。他用来自世界各地的古董和纯银饰品来装饰他的房子。",
        "虽然过去了许多年，这里仍保有Flood那时的喷泉、雕塑和街灯。甚至于最早的“JCF”装饰牌仍然在Lindenwood大门上方的铁质拱门上。有大门的封闭社区增加了隐秘性。小区内几乎没有车辆行驶，迷宫一样弯曲的路上是尽是高大的树木。这种幽静隐秘的设计是许多富豪的向往。",
        "如今Lindenwood既是新居民也是老居民的家。他们之中有许多是创建硅谷的家庭后裔。现在这里有488个住户。大部分房子都占地一英亩左右。和West Atherton不同的是，这里有许多老房子。新房子都是大约几百万的豪宅。",
        "不管整体房地产市场如何，这里的房价是相对稳定的，稳步增值，浮动少，最高价相对低。2016年，在Lindenwood的房价中位数大约是630万美元",
        "大部分Linderwood的居民都是Lindenwood家庭协会的成员。这个协会致力于解决社区问题，比如Lindenwood地区自然灾害的预防工作，处理对社区产生重大影响的问题，维护公共场所的绿化和园艺，以及组织社交活动。"
      ],
      "Lloyden Park": [
        "Lloyden Park坐落于El CaminoReal和铁路之间，以Wilburn Ave与Lloyden Dr为界线。Lloyden Park是Atherton所有社区里最具有郊区风格的。这里曾是20世纪初一个富有的工业家的宅邸。Lloyden Park在1920年被分成了86个独立家庭住宅区。每座住宅占地面积在三分之一英亩左右。这里是小镇里唯一有地下设施、街灯和人行道的社区。",
        "虽然许多家庭进行了彻底的内部装修和升级，小区还是保持了许多原来的特点。建筑风格包括杜铎风格、地中海风格和木瓦风格。",
        "Lloyden Park的校区属于Redwood City学区。这里幼儿园到八年级的学生都上Selby Lane学校，高中上Menlo-Atherton高中。也有许多家庭选择上这个地区的私校，包括Sacred Heart、Phillips Brooks和Menlo。",
        "与周围地区相比，Lloyden Park的房价上升得慢。主要原因是开发商不在小块地和靠近火车站的地方建房。因为社区的面积小，几乎没有房子会在同一个月上市出售，房屋买卖很少，房价也是Atherton之中较低的一区。"
      ],
      "West Atherton": [
        "如果用三个来形容West Atherton区，那就是：独特，醒目，豪华。历史上，这里曾是Faxon Dean Atherton的一块600英亩的私人牧场。现在它成了最贵的住宅区。Atherton先生的房屋旧址即是现在的Menlo Circus俱乐部。",
        "West Atherton的街道宽阔。道路两旁种着老橡树、雪松和松树。像其它Atherton的社区一样，这里没有人行道，道路充满着乡村气息。一排排的栅栏和精致的大门使路过的人只能瞥见豪宅的一角。虽然有大门和栅栏，许多漂亮的房子还是为街道增色不少。",
        "对于许多住户来说，这个社区是最好的选择。由于越来越多的硅谷显赫高管和投资者搬到这里，社区的面貌在快速的变化着。几乎每条街道都有新的住宅在兴建。",
        "随着年轻房主的入住，旧式牧场风格的房子越来越少。即使在经济不景气的时候，对West Atherton的房产来说没有什么影响，需求量仍然很高。跟其它高端豪宅市场的买主一样，这里的买主是高度量身订造房产，与其说买这些房子是投资，不如说是展览品。",
        "这里的房屋各有特色，价格不一。在West Atherton区内边缘的房价比在中心街道的约低8%。"
      ],
      "West of Alameda": [
        "在Alameda和280高速路之间的这个社区被称为“上层市场Upper-Market”。这是一个独特的社区，特点是富有而宏伟。这里的居民之所以选择这个社区是因为他们更喜欢这里的隐秘性而不是像West Atherton和Lindenwood那种离市区更近的环境。",
        "这个区被橡树、小溪和山坡环绕。它的引人之处是房子和周边的翠绿环境融为了一体，旧金山半岛的迷人风景在此展露无遗。房子和周围的绿树之间的距离大，使房子显得更幽静隐秘。你仿佛觉得深在郊外，其实这里距离Menlo Park市中心只有几分钟的车程，上280高速路也很方便。",
        "这个区另一个吸引人的地方是Menlo Park分数很高的Lomitas学校。住在这里孩子可以上加州最好的几所中小学。但这里的Woodside高中，在GreatSchools Rating中只有7分，API只约700多分。主要原因是学生来源比较混杂。"
      ],
    },
    basicInfos:[
      {
        type: 'text',
        value: '马术俱乐部Menlo Circus Club成立于1920年，是当地最有名的富人社交活动场所。这是一个会员制的乡村俱乐部，为小镇居民提供一个打马球和家庭活动的优渥环境。入会条件是由一位现任会员提出申请，愿意担保你入会，并且其他会员没有异议，方能入会。加入俱乐部以后，需一次性缴纳会员费6万美元，再加以后每月500-1000的月费。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622310162/MenloCircus1_vwthms.jpg',
        desc: '马术俱乐部Menlo Circus Club'
      },
      {
        type: 'text',
        value: '全美房价最贵的几个zip code，Atherton排名第二，湾区排名第一，可见Atherton是名副其实的顶级豪宅区。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622310613/559aecb26bb3f7d0598ebcbe_c5xrk5.jpg',
        desc: 'the most expensive ZIP codes in the US'
      }
    ],
    residenceInfos: [
    ],
    schoolInfos: [
      'Lloyden Park的校区属于Redwood City学区。这里幼儿园到八年级的学生都上Selby Lane学校，高中上Menlo-Atherton高中。也有许多家庭选择上这个地区的私校，包括Sacred Heart、Phillips Brooks和Menlo。',
      'West of the Alameda的学区是Menlo Park分数很高的Lomitas学校。住在这里孩子可以上加州最好的几所中小学。但这里的Woodside高中，在GreatSchools Rating中只有7分，API只约700多分。'
    ],
    related: [
      'San Jose', 'Fremont', 'Santa Clara', 'Sunnyvale', 'Mountain View', 'Palo Alto'
    ]
  },
  'Woodside': {
    name: 'Woodside',
    title: '众多风投公司的所在地，宁静富裕的小镇，精英荟萃之地',
    img: Woodside,
    population: 86200,
    description: [
        '伍德赛德（Woodside）是旧金山湾区硅谷中心区域重要的富人区之一，这里名人云集，例如甲骨文的CEO，惠普总裁等。作为美国最富有和最富裕的地区，全球许多最具名望的人都在伍德赛德安居乐业，享受生活。',
        '伍德赛德是旧金山湾区一个宁静富裕的小镇，以精美的住宅和一流的学校著称。这座庄园距离众多风投公司的所在地——沙山路仅六分钟车程，并且可以方便抵达谷歌、脸书和苹果的总部。',
        '伍德赛德市是个精英荟萃之地，许多名人富豪都乐居在此，生活环境极为优渥、而高素质的人群也让该地区备受推崇。住在这里的名人包括：拉里•埃里森（Oracle公司总裁）和戈登•摩尔（英特尔公司创始人之一）等等。该市夏季凉风习习，有宽大的车道，环境舒适怡人，并且很适合骑马。',
        'Woodside位于硅谷的中心，地理位置完全的隐私和自然的美景，交通非常方便，位于中半岛硅谷与三藩市之间，距离旧金山国际机场仅20分钟，离斯坦福大学仅10分钟路程。',
        '拥有宜人的地中海式气候，一年四季如春，是旧金山湾区最适合居住的地段之一。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624945424/xWoodside-Neighborhood-Map.jpg.pagespeed.ic.WDhOcGhAHW_vp0tlh.webp',
        desc: 'Woodside neighbourhood'
      },
    ],
    related: [
      'Portola Valley', 'Burlingame', 'Hillsborough'
    ]
  },
  'Portola Valley': {
    name: 'Portola Valley',
    title: '美国最美城镇，富有又低调的小镇',
    img: PortolaValley,
    population: 86200,
    description: [
        '富有小镇——波托拉谷镇（Town of Portola Valley），这个小镇景色有名，曾获得最美城镇的赞誉。',
        '以一个西班牙探险者Gasper de Portola命名的波托拉谷（Portola Valley），是美国最富有的小镇之一。此地山林环绕，步道系统完善，自然风光亮丽，它受到严格的保护，令波托拉谷镇充满旧金山湾区难得一见的乡村风情。为了维护传统的城镇文化，拒绝让廉价的速食店设立；也有的为了融入自然景观，房主协会规定不能兴建泳池和草坪。',
        'Portola Valley的房产有一个特别之处，其建筑方针都要确保房屋和周围的环境自然和谐地融合在一起，因此吸引着那些喜欢开放空间，乡村生活，占地广阔和漂亮风景的硅谷富人们前来。',
        '住在Portola Valley的居民毫无疑问是富有的，但他们也是低调的。Portola Valley的房子既有平房式的豪宅，也有山顶上农舍般的小屋。虽然Portola Valley似乎离喧嚣繁华的硅谷很远，其实从这里到Palo Alto、 Menlo Park和旧金山只有很短的路程。',
        'Portola Valley可分成7个小区:',
        '高地区（Alpine）也称Los Trancos，位于Portola Valley的丘陵之中，为居民们提供了安全又安静的环境。',
        'Portola Valley Ranch 被评为圣马刁县（San Mateo）最美丽的地方之一，在旧金山豪宅中独树一帜。',
        'Brookside Park/Willowbrook是一个低调的社区，住着喜欢宁静郊区的居民。',
        'Woodside Highlands特别之处是房屋占地面积小，有湖畔村落的感觉。'
    ],
    schoolInfos: [
      'Portola Valley除了环境优美，学校也都不错，尤其K-8年级的学校，API都是九百多分，高中分数稍低Menlo-Atherton High School（API 819）、Woodside High School（API 758）。然而，很多居民也会选择优秀的私立学校。',
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624951264/258043-xl_rxalk7.jpg',
        desc: 'Portola Valley neighbourhood'
      },
    ],
    neighbours: {
      "Vista Verde": [
        "高地区（Alpine）也称Los Trancos，位于Portola Valley的丘陵之中，为居民们提供了安全又安静的环境。同样的房型在Los Trancos会比在Central Portola Valley的更为实惠，但是优美的风景并不会因此减少。",
        "有些人觉得沿着Los Trancos骑单车，路又长又曲折，陡峭的山坡和狭窄的路面不适合骑车。但对于那些喜欢健行的人来说， Los Trancos的开放保留地是很理想的去处。",
        "在Santa Cruz山区，274英亩的保留地有5英里以上的健行路线，许多路线处于海平面2,000英尺以上的高度。一条1.5英里长的路，甚至是位在断层线和地震带上。"
      ],
      "Portola Valley Ranch": [
        "Portola Valley Ranch 被评为圣马刁县（San Mateo）最美丽的地方之一，在旧金山豪宅中独树一帜。453英亩绵延起伏的山坡、原始橡树林、绿地、小溪和池塘构成了此地的美景。品种繁多的野生动物使得这里成为观赏鹿、野猫、琓熊和各种鸟类的好地方。",
        "为了保护Portola Valley Ranch原始自然的环境，只有四分之一的土地可以被用来建筑独门独户的住宅。或许稀有关系，此区在1977年曾经获得过Sunset杂志和美国建筑学院颁发的住宅建筑奖。",
        "Portola Valley Ranch仅有250户独立屋住户，卧室从2间到5间不等，而且因木质和大地色调的建筑外观，使这里的住宅与绿树茂密的自然环境融为一体。总体来说，Portola Valley Ranch有14种不同的户型。",
        "尽管这里的房子没有附带车库，但许多房屋都是高屋顶，宽大的玻璃帷幕，能观赏到风山保护区（Windy Hill Preserve）的景色。在Portola Valley Ranch的社区中心，可以容纳100多人，这里有两个游泳池，3个网球场，一个酿酒用的葡萄园、蔬菜园和健行专用的小路。",
        "Portola Valley Ranch的房主协会监督房子的开发和建筑规范的实施，从而维护这区的自然与住宅的魅力。对住宅的限制包括：不允许种草坪、私人泳池和建有围栏的花园；房屋的外墙必须要刷成大地色系，或与自然背景色融合一起的颜色；住家需照顾周围原生的植物。",
        "Portola Valley Ranch的地理位置很理想，既躲开了大城市的喧嚣，又能方便的去到其它城市。它离斯坦福大学只有10分钟的路程；离旧金山南部30分钟；离北圣何西30分钟；去Nathhorst Triangle、Village Square和Ladera购物中心也很方便。"
      ],
      "Brookside / Willowbrook": [
        "Brookside Park/Willowbrook是一个低调的社区，住着喜欢宁静郊区的居民。以Portola Valley的标准，这里的房子是比较小的，一般占地面积是半英亩。地方小的好处是这里的居民之间有亲近的感觉。",
        "这里的居民也多是富裕家庭，他们的休闲活动多半是健行、骑单车和沿着小路慢跑。在这个社区有一条很有名的自行车路线叫“循环（Loop）”。这是一条沿着Portola、Alpine和Sand Hill Road，有11英里长的自行车道，这一圈的尽头连着另一条很好的自行车路线——Foothill Expressway，对于那些热爱骑单车的人来说，这是不可多得的好机会。"
      ],
      "Central Portola Valley": [
        "这里地处连绵起伏的丘陵地，有许多纵横交织的小路，中央波托拉谷区（Central Portola Valley）和威斯崔吉区（Westridge），为那些既喜爱乡村生活又不想与城市隔绝的居民提供了宁静的居住环境。",
        "这里的居民还能享受Portola Valley公共设施带来的方便。这些公共设施包括：商店、好学校、当地的私人俱乐部，距离280公路也很近。事实上，Portola Valley的多数豪宅都在这两区现身。",
        "Central Portola Valley和Westridge的房子大多坐落在有橡树围绕的丘陵上。占地面积大到2.5英亩左右。许多这里的居民在硅谷、旧金山、圣何西工作。更因为靠近国际风险投资中心，尤其Sand Hill Road这个街区吸引了很多风险投资人。",
      ],
      "Westridge": [
        "Central Portola Valley和Westridge的房子大多坐落在有橡树围绕的丘陵上。占地面积大到2.5英亩左右。许多这里的居民在硅谷、旧金山、圣何西工作。更因为靠近国际风险投资中心，尤其Sand Hill Road这个街区吸引了很多风险投资人。",
        "对于新买主来说，在这两区购房的一个缺点是，严格的房屋设计规范。比如，建房者必须严格遵守Westridge建筑监管委员会制定的设计指南，目的是保护这里迷人的乡村特色。"
      ],
      "Ladera": [
        "拉德拉区（Ladera） 是一个地处Portola Valley东北部，有大约535户住家的小社区。不禁让人想起中西部的小镇。这个1946年在山坡上兴建的社区有着迷人的历史，最早曾是西班牙赠予地的农场，如今变成繁荣独立的自治社区。",
        "在Ladera的居民很注重联谊，这里的家长和孩子们都积极参加社区的活动。比如，孩子们参加游泳队比赛、夏令营，或者受训当救生员等等，这些活动的地点都在Ladera娱乐中心。同样，家长们帮助组织社区的活动，比如独立日的庆祝活动、车库拍卖和圣诞节圣歌晚会。",
        "在Ladera的535栋房屋中，有近半数的房子是原建的。许多占地面积四分之一英亩到2英亩的房子一直保持着传统牧场式的设计。一些房子可以看到湾区全景，向北最远可以看到旧金山，另一些房子可以把Sears湖光和Stanford山色尽收眼底。",
        "虽然离高速公路只有四分之一英里的距离，但因为山丘的地势，大部分房子听不到公路的噪音。而且这里有方便的购物商店，大部分住户骑车或走路就可到达。"
      ],
      "Woodside Highlands": [
        "与Portola Valley其它社区相比，Woodside Highlands特别之处是房屋占地面积小，有湖畔村落的感觉。这里的道路狭窄曲折，少有直路。然而，因为靠近Alpine路，使得这个区比起同价位山区里的房子要便利很多。",
        "Woodside Highlands最早是为富有的旧金山人建造的夏季度假场所。如今，渐渐被其它区超越，反而成为进入Portola Valley 地区的一个切入点。虽然这里有越来越多的重建房屋，许多房子的标价还是比Portola Valley的平均房价低。",
        "和Portola Valley其它地区一样，Woodside Highlands的居民有着很强的社区感。每年社区促进协会都会组织居民们参与和沟通活动。比如野餐、复活节采蛋、打扫道路、圣诞节活动等等。"
      ]
    },
    related: [
      'Woodside', 'Burlingame', 'Hillsborough'
    ]
  },
  'Redwood City': {
    name: 'Redwood City',
    title: '百万美元的毫宅，无敌海景',
    img: RedwoodCity,
    population: 86200,
    description: [
        'Redwood City是位于旧金山半岛上的一座城市，位于北加利福尼亚湾区，旧金山以南约27英里( 43公里)，大概一小时车程，圣何塞西北24英里( 39公里)。该城是北加洲较富有的郊区城市之一，拥有许多过百万美元的毫宅，美丽海景，是一个发展完善和多样化的城市。红木城的名字是为了向这个地区大面积高贵的红杉树林致意。',
        '红木城房地产业发展的相当迅速，主要是因为有能载船的深水城市港口。在18世纪中叶，经济繁荣，人口增长，红木城被选为新发展起来的San Mateo县的县政府所在地。',
        '优点: 通勤方便，市中心热闹，发展快，医疗好，周围是富人城市，离海滩森林都不远。',
        '优点: 治安在靠近north fair oaks区比较混乱，华人相对少，超市什么要去san mateo/foster city，有些区学区不是很好。',
        '靠近海湾的红木岸（Redwood Shores） 不仅有甲骨文、艺电等大公司，居住环境也十分优越。许多房屋都可看到水景，甚至带有私人的游艇甲板连接内河和水道。2009年福布斯杂志还将 Redwood Shore 评为全美最佳高端社区之一。红木海岸周边优良的学校、宁静的环境、综合性公园（76英亩的红木海岸泻湖Redwood Shores lagoon）、健全的户外设施,都是它成为理想居家之地的原因。Redwood Shores 和 Foster City 虽然都是填土，Redwood Shores 大部分房子要晚造20-30年，填土质量好不少。Foster City 现在很多房子地基问题很严重。Redwood Shores 不是全部区域都是填来的，只有挨近海边那一小半是的，靠近101路的一多半都不是。买房时disclosure可查。',
        'Redwood City有很多公司的总部，包括：Avangate ampex、BigBand Networks、broad vision、水晶动力学、Electronic Arts、GoFundMeInformatica、iPass公司、Jivox、Openwave、Oracle、Shutterfly、Support.com、Evernote。此外，全日空航空公司在雷德伍德市555号双海豚大道350套房经营一间办公室。直到1999年，世嘉在雷德伍德市运营其美国总部；在那一年，总部搬到了旧金山。3DO公司成立时，总部设在雷德伍德市。除了大型科技公司，该镇还有一个生机勃勃的小商业社区。',
        '红木城（Redwood City）得天独厚，在湾区中位置适中，没有旧金山的冷，也没有南湾的炎热，不管天气、交通都比湾区各地更好，唯独学区稍有遗憾。不管是否在意学区，红木城都是一个相当热门的区域，如前所述它的交通便利，有大公司的加持，城市持续更新，预估明年房价还是会有些微涨幅。'
    ],
    neighbours: {
      "Central Redwood City": [
        "红木城地理位置的中心是由中央红木城社区（Central Redwood City）组成的。这个社区地处Jefferson Avenue南部，El Camino Real西部，Woodside Road北面和Hudson Street东面。",
        "它离当地的设施很近。对于通勤人员来说，这个社区的交通很方便，步行可到轻轨站，离101高速和280高速开车只需10分钟。红木城的市中心就在Central Redwood City的东边，很受欢迎的Whole Foods Market和在Sequoia Station的购物中心也在步行范围之内。",
        "在Central Redwood City大多数是独立屋，中世纪住宅，但在Jefferson大道上，靠近El Camino Real和Roosevelt Avenue的东边，也有套房和公寓。独立屋面积从800 到3,000平方英尺不等，最多5个卧室。占地面积大小不一，平均是5,000和6,000平方英尺。"
      ],
      "Downtown Redwood City": [
        "Downtown Redwood City是这个城市的心脏。有着丰富的历史，为当地居民提供多样的购物和餐饮服务，在San Mateo县法院外的广场上有丰富多彩的社区活动。这个区北临San Carlos市，东临101高速，南边是Woodside Road，西边是El Camino Real。",
        "21世纪初这个地区开始重建，直至今日建筑群仍在不断的更新中。Downtown Precise计划为城市未来发展成一个商业、娱乐和兼具住宅的整合型区域，同时又保持了这个地区独特的历史。",
        "除了是购物、餐饮中心以外，Downtown Redwood City还有著名的Fox Theater 和 Club Fox为当地居民提供了娱乐享受。除了商业建筑外，红木城市中心的住宅包括有独立屋、城镇屋别墅和公寓。独立屋的年代跨越很大，有一些老房甚至是1800末期建造的，但现在重建的新房越来越多。",
        "学区还算不错，Clifford中小学、Sequoia高中的API成绩都有800分以上。"
      ],
      "Edgewood Park": [
        "埃奇伍德公园（Edgewood Park）社区北临San Carlos城，东临El Camino Real，南边是Whipple Avenue，西边是Alameda de las Pulgas。Edgewood Park的地理位置很好，因为它开往101和280高速路很便利，离学校、车站和市中心都不远。因此，公共交通线、购物和饭馆都离得很近。在红木城市中心的街道上享受美食。（Redwood City市府网站）",
        "这个社区主要是由独立屋组成的，在靠近El Camino Real的东边有一些商业建筑和公寓。在这个区虽临近闹市，但住宅面积和占地面积都不小，有些占地甚至大到1.25英亩。值得一提的是，这里的学区还算不错，Clifford中小学、Sequoia高中的API成绩都有800分以上。",
      ],
      "Farm Hill": [
        "农场丘陵（Farm Hills）社区在Emerald Hills的南边，北临 Jefferson Avenue，东临 Alameda de las Pulgas，西边和南边是Woodside城市。最令人赞叹的是，它有一个占地42英亩的Stulsaft公园。",
        "公园有很大的游乐场地，还有夏天开放的水乐园，公园里布满了小路，为热爱健行的居民提供很好的道路。夏季，公园甚至还为社区的孩子们组织夏令营。",
        "Farm Hills大部分住房是独立屋，但也有连体别墅社区。总的来说，这个地区因为有许多山丘，使得很多房子都可以看见海湾和山脉的景色。",
        "这里大部分房子是传统型的，是在1940年到1970年建的一层或两层楼的房子。有许多是加高了的牧场型房子。其中一些除了有效居住空间外，还有精巧的地下室。"
      ],
      "Friendly Acres": [
        "在红木城东南部有一个独特的社区叫Friendly Acres。这个区的北边是Woodside Road，东边是101高速路，南面是Marsh Road，西面是El Camino Real。",
        "Friendly Acres混合著独立屋、多家庭套房、工业和商业房。这里的独立屋不大，多是一层的房子，一般有2到3个卧室，1到2个卫生间。房子使用面积从800到1,500平方英尺不等。",
        "斯坦福医学院在这个区有一个大型研究中心，除了进行研究以外还可以进行门诊手术。这个区的商业办公楼还包括Genentech的分部和Ampex Data Systems的总部。"
      ],
      "Horgan Ranch & Kentfield Commons": [
        "在Redwood City的西南部是霍根牧场（Horgan Ranch）和肯特菲尔德社区（Kentfield Commons）。这个区北接Woodside Road，东临El Camino Real，南边是Atherton城市，西边是Alameda de las Pulgas。",
        "Horgan Ranch要依靠San Mateo县提供城市所需的服务。这个区大部分的住房是独立屋。一些房子最早来自1900年代初期，另一些是在1950年到1980年之间建的，更新的一些建筑也如雨后春笋般在这个区涌现出来。",
        "另外，Horgan Ranch也有各种商业建筑，沿着Woodside路有套房，还有一些1980年代建的有独立产权的公寓。这些公寓建在一个公园般的环境里，居民可以去网球场、娱乐室和游泳池。",
        "Kentfield Commons是一个有大门的大型社区，在1980年代末，90年代初建成。在这个区的独立屋有3到4间卧室，2到3个浴室，使用面积从1,500到2,700平方英尺不等。社区里包含游乐场和大泳池。"
      ],
      "Mount Carmel": [
        "位处Edgewood Park南面的是卡梅尔山（Mount Carmel）社区，它的北边是Whipple Avenue，东边是El Camino Real，南面是Jefferson Avenue，西面是Alameda de las Pulgas。这个区的住宅主要是独立屋，在靠近El Camino Real的东边也有一些公寓。",
        "这个社区许多老房子都进行了大规模的重建，使得传统风格与新鲜的现代感融合在一起。许多街道都是绿树丛荫，房子面积从700到3,800平方英尺不等，占地面积变化也很大，从5,000平方英尺到半英亩不等。",
        "这个区的位置很好，因为从这里的许多地方都可以步行到Whole Foods Market、Sequoia Station购物商店和市中心。这里的居民享受着公共交通、商店和餐馆，以及280和101高速路提供的便利。",
      ],
      "Woodside Plaza": [
        "伍德赛德广场（Woodside Plaza）社区安置于Farm Hills和Central Redwood City之间。这个区北面是Jefferson Avenue，东边是Hudson Street，南面是Woodside Road，西面是Alameda de las Pulgas。",
        "除了美丽的风景和林荫道以外，许多人被Woodside Plaza吸引也是因为它靠近101和280高速路以及红木城市区。在Woodside路上比较热闹，有不少城镇屋、公寓、购物中心和商业楼。",
        "Woodside Plaza的许多房子是一层的农场式房屋，建于中世纪，面积从1,200到1,500平方英尺不等。有一些房子还大于2,000平方英尺，占地面积从5,000 到8,000平方英尺不等。",
        "Woodside Plaza有31英亩的Red Morton社区公园，设施包括社区游泳池、运动场、篮球场、网球场、休闲健身课、幼儿园、少年活动中心、健身中心、游乐场和可以烧烤的野炊场所。"
      ],
      "Emerald Hills": [
        "翡翠丘陵（Emerald Hills）小城，较少人知道这个区域，因此房市冷清。其实它不属于红木城，但它紧靠红木城西北边，在Edgewood Park和 Mount Carmel的西边，在Farm Hills的北边。Emerald Hills的环境更清幽，房价也比红木城来得高。但这里的止赎房数量偏高，2015的纪录是万分之4，比全美的万分之0.8还高。",
        "这个有着起伏的丘陵，蜿蜒的道路和私人湖泊。这里各式各样的独立屋，有一些是能够看到湾区美丽的景色。房子的面积有700平方英尺的小房子，也有到1万平方英尺的豪宅，占地面积从5,000平方英尺到3英亩都有。",
        "这里有静谧的大自然，有几条健行路线受到登山爱好者喜爱。这里还有Emerald Lake Country俱乐部、Emerald Hills高尔夫俱乐部。另有一个467英亩自然保护区Edgewood Park也邻近此区。"
      ],
      "Redwood Shores": [
        "红木海岸（Redwood Shores）属于红木城的一部分，由于他的独特性与房价和红木城其他区域的差异颇大，所以将红木海岸独立介绍。其占地约1,500英亩，地处贝尔蒙特（Belmont）和圣卡洛斯（San Carlos）的东部，在Foster城和红木城之间。",
        "在过去的二十多年，红木海岸的居民目睹了这个区域的惊人发展，新的商业大厦的落成，购物中心的兴建，增加两所新小学。如今，小区还有一个私人体育俱乐部和图书馆。",
        "居民喜欢这里宁静的环境，以及很多慢跑小路和76英亩大的红木海岸湖（Redwood Shores Lagoon）。虽然这个区的整体规划里包括一些出售或出租的公寓，主要还是以独立屋为主。其中有许多是临水的房子，有私人码头，别致的水景使红木海岸成为有特殊吸引力的社区。"
        // https://www.epochtimes.com/gb/17/1/8/n8680007.htm
      ]
    },
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624435768/Redwood-City-Neighborhood-Map_nstmzn.jpg',
        desc: 'Redwood City neighbourhood'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470177/map-redwoodshores_pehwjy.jpg',
        desc: 'Redwood Shore neighbourhood'
      },
    ],
    residenceInfos: [
      'Redwood City人口密度为每平方英里3955.5人( 合1527.2人/平方公里)。'
    ],
    schoolInfos: [
      "红木城的学区，以API来看几乎所有的公立学校都没有超过900分的；GreatSchools Rating评分低于7分的居多。中央红木城的公立小学Hawes、John Gill的API分数仅在700分附近徘徊，比较好的是Sequoia高中、Clifford中小学（K-8），API分数都超过800分，GreatSchools Rating达6至7分。",
      '红木城拥有优质学校，例如Redwood Shores Elementary School,以及 Carlmont High School,其API分数都接近900分。值得一提的是，红木城的磁石学校North Star Academy （API分数达996), 在2012年被美国教育部评为National Blue Ribbon School之一。',
      'Redwood Shores小学（API成绩928）和Sandpiper小学（API成绩939）。这两所小学都属于Redwood Shores学区。这个学区总共有6所小学。和其他城市不同的是，这个学区的学生几乎都可以步行上学。因为学生们通常被指定就读离家最近或第二近的学校，但根据学校的招生限制，学生们也可能被指定上6所学校中的任何一所。',
      "Redwood Shores小学有从K到5年级的学生，约400多人。学生与教师的比例是23：1。Sandpiper小学有约600多人，学生与教师的比例是24：1。这两所学校的学生毕业后都升上Ralston Intermediate初中，API成绩907，有大约900名学生。初中毕业后的学生升上Carlmont高中，API成绩878，约有2,100多名学生。"
    ],
    related: [
      'Daly City', 'Foster City'
    ]
  },
  'Daly City': {
    name: 'Daly City',
    title: '',
    img: DalyCity,
    population: 107008,
    description: [
        'Daly City是美国加利福尼亚州圣马特奥县的一座城市，也是旧金山湾区的一部分，紧邻旧金山南边，面积20.48平方公里。根据2019年美国人口普查，共有人口106,621。旧金山湾区捷运系统在本市设有同名车站。Daly City跟旧金山接壤，地理位置自不用说，优越的地理位置加上没有rent control的限制让它成为租房市场的天堂。Daly City优越的交通地理位置使得出租房空置率极低，经常出现一房难求的状况。',
        'Daly City是美国加利福尼亚州圣马特奥县最大的城市，该市位于旧金山以南，以纪念商人和地主约翰·唐纳德·戴利命名。',
        '人口密度为每平方英里13，195.0人( 合5，094.6人/每平方公里)。',
        'Daly City一直被人们诟病的就是大雾，深夜时分行驶在Skyline周边的小路上，有时会让小编联想到寂静岭中的场景。',
        'Daly City的公路基础设施包括1号、35号和82号州际公路以及280号州际公路。280号高速公路将Daly City一分为二，280号州际公路也是连接旧金山与圣马丁奥和圣克拉拉县的主要交通走廊。',
        'Daly City的公交很发达。美国人口普查局已将戴利市确定为公交乘客最多的城市之一。公共交通由SamTrans、BART (位于达利市车站和科尔马车站，毗邻达利市界限)和旧金山的一些Muni线路提供。Daly City位于旧金山市中心西南约8英里处，旧金山国际机场位于戴利市东南9英里处；两者都很容易通过高速公路或BART到达。'
    ],
    basicInfos:[
      {
        type: 'text',
        value: 'Daly City雾特别大，早晚会有很浓重的雾。通常大雾的形成是因为有温暖的空气在比较凉的太平洋上空盘旋，然后吹到了内陆地区，有时候还会到山谷地区。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622164733/0_202007030539015geM4_uoasje.jpg',
        desc: 'Daly City大雾天气'
      }
    ],
    schoolInfos: [
      'Daly City有几个公立学校区。最大的是杰斐逊小学区和杰斐逊联合高中区，这两个区的总部都设在该市。此外，还有海湾小学区(两所学校)、布里斯班学区(Daly City的全景学校)和南旧金山统一学区(Daly City的两所学校)。Daly City有两所高中:韦斯特穆尔高中和杰斐逊高中，还有一所延续学校桑顿高中和一所成人学校杰斐逊成人教育。'
    ],
    related: [
      'Redwood City', 'Foster City'
    ]
  },
  'Saratoga': {
    name: 'Saratoga',
    title: '悠闲安静，临近硅谷，硅谷精英的后花园',
    img: Saratoga,
    population: 28005,
    description: [
        '萨拉托加（英文：Saratoga）是美国加利福尼亚州圣克拉拉县下的一个城市，是临近硅谷的是一座充满乡村格调的小镇。位于旧金山湾区的圣克拉拉山谷西部，圣荷西西方、Cupertino南方。萨拉托加与Cupertino相接壤,85 号公路穿过该市,地理位置极佳，处于硅谷西部，距离苹果总部所在地的Cupertino以及谷歌总部所在地的山景城Mountain View都非常近，仅需20分钟左右车程。',
        '小镇不是很大也不是很有名气，主街名为Big Basin WY, 街道两旁遍布着餐厅、咖啡厅、工艺品店和别致的小花园。小镇的节奏非常悠闲。这里没有商业街的喧哗，没有车水马龙的拥挤，街道两旁绿树成荫，有些树开满了鲜花，与街道花坛里的各色鲜花相互映衬、争芬斗艳。走在这里的马路上你会发现，路上的行人很少，车子也不多，街边一些咖啡屋、酒吧长廊非常温馨安静。可以说，这里是硅谷人的后花园。',
        '比较全美两千多个城市的四房两卫房屋平均价格，旧金山湾区萨拉托加成为购买这样规格房屋最贵的城市，平均价格接近250万美元（245万3,718美元），别墅邻近交通要道，且环境优美，治安较好。同时，周边学校教学质量较高，是不错的学区房选择。',
        '2008 年 CNN 将其评为全美收入第四高的城市,2009 年被福布斯评为全美前 20 的居民受教育程度最高的小镇。Bloomberg 商业周刊将Saratoga为加利福尼亚州生活成本最昂贵的郊县地方。2011年，彭博新闻社还将该地的邮政编码，95070，列入2011年度全美最富裕的邮政编码第十八位。',
        '此外值得注意的是，萨拉托加是加州为数不多FBI安全评级达到 A 的城市。'
    ],
    residenceInfos: [
      '当地居民年龄分布上，45岁-64岁的人最多，占30%，其次是18岁以下的年轻人，占20%。萨拉托加的年龄中位数为47.8岁。',
      '2018年美国社区调查数据显示，萨拉托加是美国第8大富豪城市。这里的居民平均家庭年收入约19万美元，是全美水平的3.5倍。',
      '萨拉托加在2009年被福布斯评为美国20个受教育程度最高的小城镇之一。'
    ],
    schoolInfos: [
      '萨拉托加学区内共有25所学校，8所公立学校和17所私立学校，其中有7所公立学校评分满分，教育资源优质名副其实。',
      '萨拉托加高中是硅谷乃至全美都排名在前的学校。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Los Gatos-Saratoga 学区 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335942/LG-HS_doxorz.png',
        desc: 'Los Gatos-Saratoga 学区 高中的学区划分'
      }
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625293554/FAQ4saratoga_screenshot_map_red_border_updated_11_25_2020_with_saratoga_village_lrmahh.jpg',
        desc: 'Saratoga neighbourhood'
      },
    ],
    neighbours: {
      "Monte Vista": [
        "很小的一个区域，越往西lot越大，大部分都在山上。可以看到占地4 acre以上的地，但价格也不会因为地大比其他平地区域贵很多，需要去实地查看lot 可用面积。"
      ]
    },
    related: [
      'San Jose', 'Sunnyvale', 'Mountain View', 'Campbell'
    ]
  },
  'Los Gatos': {
    // https://liveinlosgatosblog.com/list-of-los-gatos-neighborhoods/
    name: 'Los Gatos',
    title: '深受欢迎的富人区，环境优美学区优质',
    img: LosGatos,
    population: 28005,
    description: [
        '洛斯盖图斯（Los Gatos）是圣塔克拉拉县下辖的一座城市，位于旧金山湾区，在San Jose的西南角，距San Jose20分钟的车程，圣克鲁斯山的山麓，硅谷的南端，是一个在硅谷中非常独特的城市，距兄弟小镇Saratoga仅10分钟路程。Los Gatos在Silicon Valley地区一直以来都是一个富裕的社区，当地房产也非常抢手。走进Los Gatos，你会仿佛离开了硅谷，来到了一个美国的乡村小镇。',
        '据《彭博商业周刊》，Los Gatos为美国排名第三十三的富裕地区，是个非常受人欢迎的居住地。镇内的圣塔克鲁斯大道热闹非凡，有各种艺术品小店、服装店、特色餐厅、花店。从现代的苹果店到古老的修鞋铺都能在这里看到，你可以在工艺品小屋绘制一个自己风格的瓷具，也可以乘坐特色的观光马车欣赏小镇的美景。',
        '这里环境优美，生活方便，充满自然情趣，并且有优质教育资源，适合有孩子的家庭。',
        'Los Gatos原意来自于西班牙文的「猫」，起源于小镇附近山区以前经常出没凶猛的美洲狮和短尾猫，因此在镇上也能随处可见大山猫的图像和雕像',
        '曾经的加州农场，后来的南太平洋铁路沿线小镇，再到苹果初代车库公司和Netflix的总部所在地，以及如今房价六位数起步的硅谷豪宅区',
        'Los Gatos没有Mountain View，Sunnyvale，Palo Alto等有名。但等到大家随着工资的增长慢慢的能负担得起3M以上的房子的时候，就会更多的考虑生活质量，到时候更看好Saratoga／Los Gatos，因为湾区真正环境好，有大lot的地毕竟就那么多。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624469738/West-Los-Gatos-neighborhoods_un9vjk.png',
        desc: 'Los Gatos neighbourhood'
      },
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Los Gatos 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335943/LG-ES_lyjgup.png',
        desc: 'Los Gatos 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Los Gatos-Saratoga 学区 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335942/LG-HS_doxorz.png',
        desc: 'Los Gatos-Saratoga 学区 高中的学区划分'
      }
    ],
    related: [
      'Saratoga', 'Sunnyvale', 'Mountain View', 'Campbell'
    ]
  },
  'Los Altos': {
    // https://posts.careerengine.us/p/60b28260eda7a1622e65eb8d
    name: 'Los Altos',
    title: '最昂贵和最富有的城市之一，硅谷豪宅区',
    img: LosAltos,
    population: 28005,
    description: [
        'Los Altos是一个怡人舒适的小城， 位置适中，往北到San Francisco约45分钟的车程，往南到San Jose大概是25分钟的车程，有两条公路，北边的是101，南边的280，在东边还有一条85公路，交通非常方便，位于硅谷与三藩市之间，中半岛的南端。Los Altos不是很大， 人口也不到3万，但是一个很有特色的小城，有一所大学叫Foothill college, 附近有一所现代化的医院叫El Camino Hospital。气候宜人，终年的温度大概就是摄氏20度左右，不冷不热，是非常适合家庭定居的环境。Los Altos杜绝所有商业的发展，附近除了一个商业化的downtown，其他都是住宅区，非常安静，学区质量非常高。Los Altos是一个非常注重生活品质的人居住的地方。平均房价约 1.5M-1.7M，高端的房价大概是5.2M左右，最低的价格也要1M左右，3房1个浴室，是一个非常小的格局。',
        '为什么房价这么贵？Los Altos可以细分为4个区域，一个是Los Altos Village, 靠近Downtown，有一些一个世纪前盖的英式和法式的豪宅，接下来在南边的部分叫Midtown，位于S EL-Mounte以南，一直到Fremont以北的这块区域，这里差不多是1970年左右盖的房子；南端在Los Altos South，这里的学校是跟Cupertino连在一起；还有一个很有特色的区域，叫Los Altos Country Club Area，这里的房子都是环绕高尔夫球场而建的，早上起来可以看到绿草地，听到鸟叫，看到蓝天，这是最美好的享受。Los Altos里面可以看到很多10年内新建和改建的房子，尺寸大概是2500sq.ft，高屋顶，采光明亮，没有压迫感，设计先进，具有人性化。 学校都非常高端。',
        'Los Altos Hills 拥有高品质的学校，图书馆和教堂, 其优质的学区更是吸引着重视教育的亚裔 人士. Los Altos Hills 也是美国最昂贵, 和最富有的城市之一,其所在的邮政编码（94022和94024）被福布斯评为是全美最昂贵的邮政编码第24位。',
        'Los Altos没有Mountain View，Sunnyvale，Palo Alto等有名。但等到大家随着工资的增长慢慢的能负担得起3M以上的房子的时候，就会更多的考虑生活质量，到时候更看好Saratoga／Los Gatos，因为湾区真正环境好，有大lot的地毕竟就那么多。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624435673/xLos-Alto-Neighborhood-Map.jpg.pagespeed.ic.OMdttOHxwJ_bofuc9.webp',
        desc: 'Los Altos neighbourhood'
      },
    ],
    schoolInfos: [
      'Los Altos主要学区大概可以分成三部分，北边94022主要涵盖在Palo Alto Unified School District，94024主要是Mountain View-Los Altos Union High School District，最南侧还有一部分学区跟Cupertino一样，是Monta Vista High。'
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Los Altos 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335040/LA-ES-1_btcmt8.png',
        desc: 'Los Altos 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Mountain View-Los Altos 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335040/MVLA-Schools_ruhjlt.png',
        desc: 'Los Altos 高中的学区划分'
      }
    ],
    related: [
      'Saratoga', 'Sunnyvale', 'Mountain View', 'Campbell', 'Los Gatos'
    ]
  },
  'San Francisco': {
    name: 'San Francisco',
    title: '',
    population: 883305,
    description: [
        '旧金山（San Francisco），又译“圣弗朗西斯科”、“三藩市”。旧金山是美国加利福尼亚州太平洋沿岸港口城市，是仅次于洛杉矶的第二大城市，也是美国西部最大的金融中心和重要的高新技术研发和制造基地。',
        '旧金山位于美国加利福尼亚州西海岸圣弗朗西斯科半岛（37°48’0”N,122°25’0”W），面积47平方英里（121.73平方千米），三面环水，环境优美，是一座山城。',
        '旧金山是个移民城市，因为非拉丁裔白人少于44%的总人口；亚裔，大部份为华裔，占了33%的总人口；各拉丁裔族群总共仅占15%的总人口；非洲裔仅占6%的总人口，少于全美国非裔人口比例。终身都居住在旧金山的市民并不多，而有34.4%的市民在外国出生。',
        '旧金山半岛三面环水，受太平洋加利福尼亚寒流影响，是典型的凉夏型地中海式气候，因长期受海风影响，夏天的日高温通常只有20摄氏度左右，一年大约只有一个星期会因强劲陆风影响而超过摄氏30度，9月是最暖和的月份，因太平洋水温长年在摄氏10-15度间，夏天半夜也可能下降到10度以下。旧金山因临近海边和金门海峡，下半夜和早上多受雾的影响，夏季降雨极少，雨季为一月到四月间，冬天虽冷，但鲜有降雪或霜。'
    ],
  },
  'Blackhawk': {
    name: 'Blackhawk',
    title: '',
    population: 28005,
    description: [
        '丹维尔（Danville）位于旧金山湾东部，是东湾著名的适宜居住的城市之一，距离旧金山市中心车程30分钟。Blackhawk是Danville东部最高端的封闭式大型社区，社区内的Blackhawk Country Club黑鹰乡村俱乐部 1979年开业，拥有4800英亩土地，2个高尔夫球场。这套欧式风格别墅位于黑鹰谷高尔夫俱乐部（Blackhawk golf club)，设计非凡、品位极高，拥有无与伦比的优质生活氛围。',
        'Blackhawk黑鹰社区是旧金山东湾最富裕的社区之一。拥有3,300多户豪宅，拥有六个相通的封闭式社区（Gated Community），和一个占地4,800英亩（19平方公里）的乡村高尔夫俱乐部。附近还有一个环境优美，依山傍水的黑鹰商场（Blackhawk Plaza），里面有池塘瀑布，还有著名的老爷车博物馆。',
        'Danville是东湾三谷地区的五个城市中，历史最为悠久，风光最为美丽的一个乡村风格小镇。它位于Contra Casta 县的680高速公路旁，被称为三瑞蒙山谷（San Ramon Valley )的心脏城市。这座城市一直以美国小镇风情、优美景色、高尚社区、最好学校而闻名于北加州和旧金山湾区。',
        '这也是您为孩子的学业和成长作出的最正确的选择。Danville 市政府与学校管理部门多年来特别重视教育投资与人才培养，这里是整个大湾区学校综合评分最高的城市之一。阿亭尼私立高中（ The Athenian School ）是加州最佳私立高中的前10名，录取严格，竞争激烈。毕业生考入斯坦福，伯克莱加大等名牌大学的比例是湾区最高的。近年来很多湾区其他城市的华人家庭和新移民为了子女得到最好的教育，纷纷搬到这里安家落户。',
        '大凡来过黑鹰社区的购房者，无一不被这美丽如画的景色吸引，感叹这就是他们一直在寻找的“采菊东篱下，悠然见南山”的世外桃源。难怪很多娱乐、体育界和高科技名人都选择在此安居乐业。',
        'Blackhawk的人口构成有别于湾区大部份城市，白人居民仍然占大多数，比例接近八成。'
    ],
    related: [
      'Dublin', 'San Ramon', 'Livermore', 'Danville', 'Pleasanton'
    ]
  },
  'Alamo': {
    name: 'Alamo',
    title: '',
    population: 28005,
    description: [
        'Alamo（阿拉莫）位于旧金山东湾，距离旧金山市中心车程半小时，临近University of California Berkeley加州大学伯克利分校。这里是东湾著名的富人区，是美国最富有的社区之一，白人比例占87.6%，年平均家庭收入18万美元。此区域的房屋均以大面积豪宅为主，房屋中间价为188万美元，公立学校评分9-10。',
        '阿拉莫作为一个非自治社区，它没有自己的政府。警察服务由康特拉科斯塔县警长办公室提供。这里的社区安全指数很高，失业率低于全美5%及本州6%，仅为3%。',
        '该地区被命名为阿拉莫，来自西班牙的话á落寞，意思是“白杨”或“三角叶杨”。因其地理位置和气候，白杨增长迅速。这座城市一直以美国小镇风情、优美景色、高尚社区、最好学校而闻名于北加州和旧金山湾区。',
        '680号州际公路，贯穿南北，是San Ramon山谷主要的公路。它向北通过城市如Walnut Creek，Napa，萨克拉门托和Lake Tahoe（可通过80号州际公路）。往南，i-680穿过城市包括pleasanton,Fremont和San Jose。',
        'Alamo与24国道相交，与Walnut Creek相邻，最近的BART站是Walnut Creek站，开车大约10分钟可以到达。平时也可以乘坐本地的巴士服务路线21和321，在阿拉莫丹维尔大道，南至丹维尔和圣拉蒙、北至核桃溪，终止在BART站。',
        'Alamo的人口构成有别于湾区大部份城市，白人居民仍然占大多数，比例接近八成。'
    ],
    schoolData:[
      {
        type: 'link',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625811863/1625811708860_phnxkl.jpg',
        desc: 'Alamo 小学学区'
      },
      {
        type: 'link',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625811863/1625811851380_z6lf1b.jpg',
        desc: 'Alamo 高中学区'
      }
    ],
    related: [
      'Dublin', 'San Ramon', 'Livermore', 'Danville', 'Pleasanton'
    ]
  },
  'Walnut Creek': {
    name: 'Walnut Creek',
    title: '美丽富饶，以白人为主的雅致小城',
    population: 28005,
    description: [
        '核桃溪市(Walnut Creek)，因市内流有一条长满核桃的溪水而得名，位于旧金山东湾，是一个美丽又丰饶富裕，以白人为主的雅緻小城，距奥克兰市约18分钟车程，据旧金山约25分钟车程。市中心拥有一个户外开放式的百老汇广场(Broadway Plaza)，于1951年10月11日开业。Walnut Creek downtown有 east bay 最好的shopping area，购物中心内包括Macy’s百货公司、Nordstrom，和California Pizza Kitchen, J. Crew, P.F.Chang’s等知名连锁餐厅和许多高级商店和各种零售商，为湾区东部主要商业娱乐中心之一。',
        '该市于2012年被Livability.com评为十大最适合退休的城市的第一名，是加州最好的居住地之一。小镇虽小，却典雅小巧，宁静且富艺术气息，优异的生活品质，受到肯定。位居退休城市榜首的核桃溪市，大多数居民拥有自己的房屋。是加州最好的居住地之一。气候乾燥，温度适中、治安好，又拥有John Muir和Kaiser Permanente等美国顶级医疗保健中心，和全美十大最佳儿童专科医院。不仅自然风光美丽，还有顶级的医疗系统，非常适合白领们退休后作为养老的地方。有24座公园、脚踏车道、公共高尔夫球课程，剧院，以及莱舍艺术中心的画廊展览。是一个湖光山色自然景观很美的城市，由于富有的白人居多，欧式建筑点缀其中，更为核桃溪市增色不少。',
        '核桃溪有浓厚文化艺术气息，贝德福德画廊(Bedford Gallery)每年都举办现代艺术画展，街头也到处陈列着形象逼真的雕塑。',
        '缺点是就是中国人比较少，好吃的中餐厅很少，离中国人集中的南湾比较远。Walnut Creek市内有两条捷运（BART），分别为 Walnut Creek station 和 Pleasant Hill station，西向可以到达旧金山，东向可以抵达Antioch，40分钟之内即可到达目的地。'
    ],
    residenceInfos: [
      '核桃溪市的人口构成有别于湾区大部份城市，白人居民仍然占大多数，比例接近七成。这几年亚裔不断的涌入，几年前白人的比例接近八成'
    ],
    schoolInfos: [
      '核桃溪的居民可以在五个优质学区就读，除了可以在核桃溪学区（Walnut Creek School District）就读，该市居民还可以在另外四个学区上学。对于注重子女教育的华人父母来讲，是非常不错的选择。Walnut Creek 学区有5所小学和一所初中。 一些居民选择Mount Diablo Unified School District，Acalanes Union High School District，San Ramon Valley Unified School District，Lafayette School District学区。',
      '核桃溪学区质量也相对优秀，大部分评分在8分以上。高中、初中、小学都有评分9分的学校。9分的公立高中有：Las Lomas High School 和 Northgate High School。初中如，Foothill Middle School，以及Walnut Creek Elementary School 也获得了9分的评分。'
    ],
    related: [
      'Dublin', 'San Ramon', 'Livermore', 'Danville', 'Pleasanton', 'Alamo'
    ]
  },
  'Campbell': {
    name: 'Campbell',
    title: '迅猛成长，拥有良好社区，硅谷新宠。',
    img: Campbell,
    population: 28005,
    description: [
        'Campbell位于加州旧金山南部，属于Santa Clara County，是硅谷的城市之一。比起其他硅谷城市，Campbell没有那么多科技大公司，但是eBay公司创始于Campbell，而eBay创始人 Pierre Omidyar也来自Campbell。这座笼罩在璀璨精金-硅谷之下的小城，正在迅猛成长，悄然成为了硅谷新宠。Campbell是一个几乎被San Jose围绕着的城市，位于San Jose的西南面。近5年来它的房价一直保持着非常良好的走势。虽然期间也会受到社会经济等因素的影响而变化，但它是既敏感又沉稳，既恬静又倔强。Campbell的地理位置决定了Campbell人可以住在大农村，又可以同时享受城市生活。除了随时可以步行前往市中心享受美味咖啡下午茶，与友人前往酒吧狂欢，也可驱车往南10分钟进入美丽富裕的Los Gatos downtown，往北10分钟前往湾区最高档的购物中心Valley Fair Mall和Santana Row。',
        'Campbell毗邻West San Jose， 85号公路和17号公路贯穿于此，280公路也在不远处。圣荷西热闹的商场Westfield San Jose 和Satana Row商业区域离Campbell非常近，Campbell还有Pruneyard购物中心，交通方便，生活便利。Campbell的小镇，每周末有热闹的农夫市场，新鲜果蔬，音乐表演，各式商店和美味餐厅都在小镇中心可见，充满了欧洲小镇风情。',
        '但由于它的四周被West San Jose和South San Jose，以及Saratoga、Los Gatos等城市包围，因此即使当这个地区的供应量不足的时候，购房者也很容易在周边找到类似，甚至更好的社区来替代。因此也抑制了这个地区房价的快速增长。所以，它既对市场的变化敏感，又能稳健上升；既不会有超越其它地区幅度的爆发式上涨，但也很能消化社会经济上的不利因素。',
        'Campbell的位置紧邻西圣荷西，距离硅谷各大公司交通便利。尤其是位于Los Gatos的Netflix公司， 以及Cupertino的苹果公司， Campbell都非常方便。 在campbell镇中心，还有VTA轻轨铁路，连接硅谷各镇。Campbell邻近17号高速公路，只需10分钟车程即可到达苹果公司，如果高速过堵，从local绕道也不过20多分钟。其后YouTube，谷歌等280沿线的员工发现，只要开上17号高速，转上85，280也是很方便的。所以以其优越的地理位置，开始成为湾区科技人的新宠。',
        'CampbellCampbell小镇保留传统的加州欧式小镇风格，在街道两边的商店参观林立， 周末非常热闹，还有农夫市场等。每年还有一次德国啤酒节。做为一个拥有良好社区又有历史沉淀的城市，这个地区的供应量相对于它的需求，一直是稍显不足的。这个地区的居民保有比较稳定的住宅意愿，所以能够很稳健地上升。即使遇到大环境不利，也下降不多。',
        'Campbell本身也有不错的学区，加上更加中心的地理位置，被周围的一些涨得很厉害的区域环绕，使Campbell的房产投资收益率非常好，尤其周边一些房均价太贵的情况下，等于是更少的投资门槛就可以获得很好的收益。Campbell是硅谷的新宠，首先要聊聊大家最关心的房产趋势。根据房地产数据公司CoreLogic的数据，过去六年湾区现有住宅的销售额每月都在上涨，而Campbell市仅去年一年的同期增长率就为27.1%。'
    ],
    residenceInfos: [
      '这里的社区安全指数很高，失业率低于全美5%及本州6%，仅为3%。'
    ],
    schoolInfos: [
      'Campbell 学区公立学校有Capri Elementary School( Great School Score 8/10分）, Del Mar High School (7/10分） 以及Westmont High School（9/10分）。 著名私立学校Harker也离Campbell 不远。',
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Campbell 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335726/Campbell-ES_isfguh.png',
        desc: 'Campbell 小学的学区划分'
      },
      {
        type: 'text',
        value: '以下是Campbell 高中的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624335727/Campbell-HS-1_rdiyhh.png',
        desc: 'Campbell 高中的学区划分'
      }
    ],
    related: [
      'San Jose', 'Fremont', 'Santa Clara', 'Sunnyvale', 'Mountain View', 'Campbell', 'Cupertino'
    ]
  },
  'Castro Valley': {
    name: 'Castro Valley',
    title: '地理位置佳、可负担的价位，周边环境风景宜人',
    img: CastroValley,
    population: 28005,
    description: [
        '位于东湾的Castro Valley，紧临高速公路880与580交口，往北与Oakland Hill相接，西邻San Leandro、东邻都柏林、南接Hayward。Castro Valley无论向南向北都很方便，捷运（BART）到旧金山与南湾密尔比达都约为半小时的车程。',
        '安静治安好。邻居友善，没啥闲杂人等走动。街泊🅿️不怕被敲玻璃，在家不怕入室抢劫。',
        '近年来，Castro Valley位于湾区中部，因为地理位置佳、可负担的价位，加上学区、医院与周边环境风景宜人，吸引许多新移民搬入。加上Castro Valley邻近三谷（Tri-Valley）地带，无论到普利桑顿、利佛摩、圣拉蒙，或是很多人喜欢的Danville、Alamo、Blackhawk、Diablo等地区都很方便，四通八达位置适中，「特别适合夫妻两人分别在旧金山市或者三谷地区与南湾上班的家庭。」',
        'Castro Valley除了价钱亲民、学区不错，且生活机能丰富，Trader Joes\'s、Safeway超市都有。另外还有高尔夫球场、大型宠物公园，还有知名的夏柏湖（Lake Chabot）等休憩空间，健身、散步、划船、野餐等休闲活动选择很多。买菜便利，10min车程以内有好几个华人超市，常光顾的有美味园、鱼肉王、富联、新东记。costco也可以去San Leandro的或Hayward的。在Castro Valley现在越来越多好菜食，比如说和记烧腊小馆。',
        '很多人买房，特别关心医疗的便利性。Castro Valley的医疗资源非常完整，Eden Medical Center/Sutter Urgent Care就在Lake Chabot Rd上，该区邮递区号为94546，离市中心与捷运站很近，相当方便，而且后面就紧邻山坡，环境清幽，小学及中学学区都不错（Stanton Elementary、Creekside Middle、Castro Valley High) 。'

    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625809891/1625809876495_qfm5ql.jpg',
        desc: 'Castro Valley neighbourhood'
      },
    ],
    schoolInfos: [
      'Castro Valley距离伯克利很近，当地高中的学生有很多都考到UC Berkeley。',
    ],
    schoolData:[
      {
        type: 'text',
        value: '以下是Castro Valley 小学的学区划分'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1625810284/1625810236707_xrdelc.jpg',
        desc: 'Castro Valley 小学的学区划分'
      },
      {
        type: 'text',
        value: 'Castro Valley的高中主要是Castro Valley High School。'
      }
    ],
    estatemap: "castro-valley-estate",
    related: [
      'Pleasanton', 'Dublin', 'Union City', 'San Ramon', 'Milpitas', 'Newark'
    ]
  },
  'East Palo Alto': {
    name: 'East Palo Alto',
    title: '',
    population: 28005,
    description: [
        '东帕罗奥图市位于美国加州的圣马刁县，处于帕罗奥图（Palo Alto）与门罗帕克（Menlo Park）等科技繁荣之地的夹缝中。',
        '东帕罗奥图市正成为高科技繁荣引发增长鸿沟的鲜明例证。San Francisquito小溪另一侧的帕罗奥图市借助科技繁荣赚得盆满钵满，那里的人一掷千金购买豪宅的情况时有发生，这里是诸多亿万富豪生活的地方，比如Facebook首席执行官马克·扎克伯格（Mark Zuckerberg）、谷歌(微博)创始人拉里·佩奇（Larry Page）以及苹果已故联合创始人史蒂夫·乔布斯（Steve Jobs）的家人等。可是距其15米远的东帕罗奥图市居民，却依然生活在贫困线之下。由于高新技术产业导致生活成本提高，促使很多人不得不搬离到其他城市。',
        '东帕罗奥图是美国加州建立时间最短的城市之一，1983年才立市。但它却被寄予厚望，希望当地政府能够为当地居民带来富有邻居已经拥有的一切，包括工作、住宅、医保以及安全等。而东帕罗奥图也一直在为实现这个目标而努力。该市还清理了有毒废物垃圾场，建造了数十栋新住宅，并在旧金山湾附近开发海岸公园。',
        '现在，东帕罗奥图正处理另一个问题：全球顶级科技公司的扩张及其大量高薪员工的涌入。',
        '买家抢购东帕罗奥图住房的理由很充分，因为它就位于硅谷的最中间，而硅谷是世界上最富有、最昂贵的地区之一。随着硅谷其他地区住宅价格和租金上涨，越来越多的人搬到东帕罗奥图，或在这里买房作为投资。这其中的主要原因是东帕罗奥图非常接近科技公司和斯坦福大学，它们相距仅6公里远。',
        '东帕罗奥图的东部与旧金山湾接壤。在小城空旷的边缘，你可以看到数公里外的湿地，那里有白鹭在捕鱼，迪亚波罗山脉轮廓朦胧可见。在这里，人们可以沿着海岸线的土路骑自行车或跑步。'
    ],
    estatemap: "east-palo-alto-crime",
  },
  'Foster City': {
    name: 'Foster City',
    title: '风景旖旎的湾区威尼斯，华人置业移民的首选城市之一',
    img: FosterCity,
    population: 28005,
    description: [
        'Foster City隶属旧金山城市圈，圣马特奥县。整个城市环湖而建，河流、湖泊纵横，是风景旖旎的岛居之城，也是美国最安全的城市之一，曾被评为全美最适合居住的25个城市之一。',
        'Foster City位于湾区的东部，距离旧金山市区和机场都在40分钟车程以内。地理位置靠近硅谷，距离名校斯坦福大学仅15分钟车程，市区华人众多，几乎和白人同等比例，是华人置业移民的首选城市之一，相似的文化氛围，让新移民能够快速融入当地生活。',
        '这座城市当初建设的大量低密度住宅（single family home，townhouses）吸引了当时的中产阶级，如今也在市场上很受追捧，对于喜欢宁静生活的人来说，是个不错的选择。',
        '这座城市治安非常好。谋杀案每十年发生一次，人身伤害那样的恶性犯罪、入室或砸车盗窃在福斯特城都很少发生。2018年末在购物中心停车场确实发生了一起砸车案件，但很快被这里的警方破获了。',
        'Foster City的风景是值得称道的。程序员喜欢从零开始做一个新系统，因为不需要考虑各种恼人的向后兼容问题。规划师和建筑师也一样，他们得以创造出一个人工湖，得以安排许多公园在城市各个角落，得以留有余地建立完整的沿海步道，得以建造图书馆，社区中心，市政厅，露天剧院以便市民享用，得以吸引各类商家，包括如今的中国超市和饭店，甚至留下了大片的自然保护区以便候鸟的迁徙和野生动物的庇护。',
        '著名企业Visa的总部设在福斯特城，Visa也是这座城市的最大雇主。除此之外，另有很多技术型的公司总部设在福斯特城，如Acxiom Digital、Gilead Sciences、Philips (manufacturing facility)，Live365.com等。',
        'Foster City的交通比较为人诟病。由于跨海大桥西端坐落于此，而几乎所有跨湾通勤者都开车，每天下午晚高峰时期，跨湾车流要么从101/92高速立交桥排队蜗行（三个方向汇入一处），要么从福斯特城抄近道试图节省几分钟时间，于是，进福斯特城的交通非常糟糕。',
        '一年中除了独立日的烟火和夏日的一些音乐会，再没有人头攒动的场面。Foster City给人的感觉就是花园城市，生活十分便利，尤其大华就在那里。但是其实隐藏的坑也不少，比如FC外围有飞机航线很吵，某些地方有高压铁塔可能有辐射危害，有的房子地基不好沉降严重，还有就是在要去上班的时候，出岛的交通比较拥挤。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470176/Foster-City-web_wwpqjt.jpg',
        desc: 'Foster City neighbourhood'
      },
    ],
    basicInfos:[
      {
        type: 'text',
        value: 'Foster City整个城市环湖而建，河流湖泊纵横，山水之间，风景绝佳。整个福斯特城都浸在无边风景当中，让人无法不爱上这里。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1623398040/Foster_City_aerial_view__February_2018_w80c6k.jpg',
        desc: '2018年2月，福斯特城鸟瞰，可以看出这里经过了精心设计 作者 Pi.1415926535 - 原创作品, CC BY-SA 3.0'
      }
    ],
    residenceInfos: [
      'Foster City拥有舒适的居住环境和充足的就业机会，吸引大量亚裔家庭前往于此，华裔占亚裔的大多数。',
    ],
    schoolInfos: [
      'Foster City教育资源优质，学区共有4所公立学校，目前评分都是9分，然而在评分标准调整之前，都是全10分满分学校，都被授予加州杰出学校奖。这里有三所小学一所初中，每一所的评分都至少9分（满分10分），此外还要新建一所小学。Preschool（幼儿园），课后学校也有诸多选择。学生的族裔构成也很多元化，没有哪个民族占据绝对主导优势。唯一的不足是，这座城市并没有高中。',
      '奥杜邦学校Audubon School',
      '布鲁尔岛学校Brewer Island School',
      '福斯特城学校Foster City School',
      '博迪奇中学Bowditch Middle School',
      '其中的博迪奇中学Bowditch Middle School还曾被美国教育部评为蓝丝带学校。',
    ],
    related: [
      'Redwood City', 'Daly City'
    ]
  },
  'Millbrae': {
    name: 'Millbrae',
    title: '华人最密集的城市，被誉为美国的“摩纳哥”',
    img: Millbrae,
    population: 28005,
    description: [
        'Millbrae是San mateo county一个城市，一座在机场旁边的城市。Millbrae位于三藩市湾区西岸， 北部与San Bruno接壤， 南面是Brulingame。如今这座人口只有2万的小城，华人的数量已经占了约一半。',
        'Millbrae最大的雇用单位，并非本地的企业，而是旧金山市政府，即许多在旧金山市府机构（包括机场）上班的人，都选择住在了密尔布瑞。密尔布瑞因此也被戏称为「卧室城市」（Bedroom City），意思就是人们都只是回到密尔布瑞睡觉，白天在外地工作。',
        'Millbrae地理位置优越，紧邻旧金山国际机场，到机场只需5分钟的车程，吸引了世界各地的人来居住，是大家最喜欢居住的城市之一，也是美国华人最密集的城市之一，这里的气候好，环境优美，拥有顶尖校区，市内多间学校在加州名列前茅，声誉卓著。',
        '环境：这是一座占地约8平方公里的小城，拥有宜人的地中海式气候，一年四季如春，是旧金山湾区最适合居住的地段之一。Millbrae这个名字的“brae”是苏格兰语里的“起伏的山峦”的意思。所以，Millbrae的山丘地势给市里的不少房屋带来了海湾美景。',
        '旧金山雾大湿冷，经常入夜能见度很低，气温也低。但是San mateo county中半岛一带晴天较多，气候也相对温和一些。此外，因为旧金山是热闹的都会，人口密度大，因此停车交通的便利程度都不如中半岛小城。',
        '交通：距旧金山国际机场只有5分钟车程，距离旧金山市中心仅20分钟车程，连接280、101、35、84号公路，而且有地铁站，距离Burlingame市的火车站也不远，去南湾则约为30分钟车程，距离适中。如果是前往东湾，密尔布瑞距离圣马刁大桥也不远，可以过桥抵达东湾海沃（Hayward）。',
        '生活：如此高的亚裔比例说明当地的治安、教育非常出色，而且便于融入当地。这里不论是意大利餐厅、泰国餐馆、越南餐馆、日本寿司店，还是高档中餐馆（广东茶楼到上海小吃一应俱全），应有尽有，生意兴隆。Millbrea还有颇具规模的社区活动中心和高尔夫球俱乐部。摆街会、农夫市场等社区活动也让这座小城充满了人情味。因为华人众多，因此密尔布瑞的市中心（Downtown Millbrae），随处可见中文招牌和中餐馆。无论是小型的中餐厅，还是大型的茶楼都很多。',
        'Millbrae因为城市很小，因此无分好区差区。靠近Burlingame一带，Mills Estate Park的区域房子房价更高，城市往北，房价越低。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470177/Millbrae-web-1_s16pip.jpg',
        desc: 'Millbrae neighbourhood'
      },
    ],
    schoolInfos: [
      'Millbrae的学区好，而且地理位置适中。密尔布瑞的学区中的小学和中学，在诸多校区评分的网站上都获得较高评分。',
      '教育：Millbrae周边公立学校质量一流，Millbrae市有四所小学，一所初中和一所高中。其中，Mills High School的2013年API评分是圣马刁县（San Mateo County）联合校区得分最高的高中。该城市学区优秀，成绩在整个湾区名列前茅。',

    ],
    related: [
      'Redwood City', 'Daly City', 'Foster City', 'San Mateo'
    ]
  },
  'Burlingame': {
    name: 'Burlingame',
    title: '湾区半岛的富人区，典型的中产阶级生活',
    img: Burlingame,
    population: 28005,
    description: [
        'Burlingame是加州圣马特奥县下属的一个城市。1906年旧金山大地震后，许多人迁移至此。1908年6月6日建市。城市面积约为11.4平方公里，距离旧金山市中心30分钟车程。Burlingame有湾区中产阶级白人生活方式最生动的写照。',
        'Burlingame的居民平均年收入在全美中排名名列前茅，当地的生活水准极高，选在该区兴建豪宅大院的银行家、政客更是不在少数；这里的私立学校（小学、中学）在旧金山地区名列前茅；这里的环境极其优美，在大幅的绿化面积之下，是精致高雅的别墅小屋；这里的生活极其便利，附近就是繁华的华人区（Millbrea），中国美食应有尽有；这里的交通相当方便，延伸至该地的铁路（Bart Station）直达旧金山市中心。',
        'Burlingame的市区也就两三条街，但是满满的都是可爱的小店，咖啡馆，和各类美式餐厅。建筑的样式和一般美国方方正正“大板房”很不一样，色彩明亮并且都有自己的设计。美国人喜欢晒着太阳沿着街边吃Brunch，在阳光充足的星期天上午，所有餐厅都热闹非凡，很多人带着自己的小狗一起享受这样美好的时光，加上大红色的嘴唇和洋帽，画面很像电影。',
        '与相邻不远的Millbrea地区类似，Burlingame因具备良好的交通、学区、餐饮等优势因素，近十年来房价一直持明显的上涨趋势。该市场虽早已是身价为百万美元的房地产市场，其平均及中等销售价早已超过200万美元，但它正迅速地成长为一个名副其实定型为百万美元的市场。在城市里房价不到百万美元的独栋住宅已非常罕见。绝大多数的销售价格在每平方英尺800美元以上，连1200平方英尺的2卧室1浴室房也超过150万美元（尤其是那些具有增建潜在性的住宅）。'
    ],
    neighbours: {
      "Burlingame Gardens": [
        "Burlingame Gardens 社区是 Burlingame 的入门级住宅，性价比高。Burlingame Gardens 地理位置优越，临近101号高速公路，靠近Broadway百老汇的购物和餐饮中心。步行就可到达Burlingame High，拥有一流的设施，包括网球场和一个超大的公共游泳池。公立中学是Burlingame Intermediate，最近的小学是 McKinley Elementary。"
      ],
      "Burlingame Grove": [
        "Burlingame Grove 的房子价格相对合理，其面积比 El Camino Real 以西的房屋要小。这个街区迷人的街道上点缀着树木，距离百老汇的商店和餐馆仅几步之遥。Burlingame High 是一所拥有一流运动设施的公立学校，驾车仅 5 分钟车程。同样，位于 Ray Park 社区的公立中学 Burlingame Intermediate 距离Burlingame Grove也是 5 分钟路程，该社区的小学是Lincoln Elementary。"
      ],
      "Burlingame Hills": [
        "Burlingame Hills 社区非常适合那些想要买big lot的买家，Burlingame Hills社区风景如画。该地区的许多房屋都是高档住宅。这边的房子与 Easton Addition 附近的房子差不多，略低一些，因为后者更靠近 El Camino Real。",
        "Burlingame Hills社区不适合步行，但该社区靠近 280 号高速公路，非常适合通勤到硅谷和旧金山。",
        "Burlingame High 是一所拥有一流运动设施的公立学校，距离该地区约 10 分钟车程。 Burlingame Intermediate School 是一所位于 Ray Park 附近的公立中学，距离有 5 或 10 分钟的车程。同Hoover Elementary School是该社区的小学。"
      ],
      "Burlingame Park": [
        "Burlingame Park的房子非常抢手，这里很少有房屋出售。该社区大多数的房子都是大面积的single family，但该社区东边有一排公寓，价格也更实惠。截至 2020 年，这里的单户住宅价格从 2,360,000 美元到 6,750,000 美元，公寓/联排别墅的价格从 930,000 美元到 1,040,000 美元。",
        "Burlingame Park 最令人羡慕的属性之一是它的位置。它距离旧金山仅 15 英里，可以步行到 Burlingame 大道上的精品店和餐厅，同时闹中取静。",
        "该社区的学校包括 Burlingame High，这是一所公立高中，距离社区以东仅 15 分钟步行路程，那里的顶级设施包括网球场和一个超大型公共游泳池。该社区还设有公立中学 Burlingame Intermediate，距离 Ray Park 社区有 7 分钟车程，距离Washington Elementary仅 3 分钟车程。"
      ],
      "Burlingame Terrace": [
        "Burlingame Terrace 是 Burlingame 比较老的街区之一，其住宅外观非常漂亮，有各种建筑风格。 Burlingame Terrace 在 Burlingame 交通非常便利，位于百老汇和 Burlingame Avenue 之间，这里的住宅靠近众多精品店和餐厅。该社区的房屋价格适中，截至 2020 年，这里的单户住宅价格从 1,740,000 美元到 4,000,000 美元。",
        "Burlingame High 是社区东边的一所公立高中，步行即可到达，其设施包括网球场和一个超大的室外温水游泳池。 该社区的公立中学是 Burlingame Intermediate，距离该地区约 7 分钟车程，McKinley Elementary School位于该社区的中心。"
      ],
      "Burlingame Village": [
        "North Burlingame 的 Burlingame Village 社区拥有各种建筑风格的小型住宅，非常适合想买Burlingame但预算不太高的买家。 截至 2020 年，这里的单户住宅价格从 1,580,000 美元到 3,100,000 美元。",
        "虽然无法步行前往商店和餐厅，但该社区以青翠的Village Park为中心，公园内设有游乐场、篮球场和幼儿园。由于附近的Guittard Chocolate公司，空气中弥漫着巧克力的甜味，这让这个街区锦上添花。",
        "Burlingame High 是该社区的公立高中。Burlingame High位于Burlingame Village东南，驾车 6 分钟车程处，提供一流的运动设施，包括水上运动中心和网球场。 Burlingame Intermediate School 是一所位于 Ray Park 附近的公立中学，距离有 5 分钟车程。 同样在附近，Lincoln Elementary School为该社区提供服务，车程约 4 分钟。"
      ],
      "Downtown Burlingame": [
        "Downtown Burlingame适合入门级买家或者不需要地太大的买家。这里的库存主要包括apartments公寓和condominiums共管公寓，但也有一些single family。这里的大多数房子都有 30 到 40 年的历史，尽管在过去 10 年里，该社区出现了更新的开发项目和高档公寓condo。截至 2020 年，这里的公寓/联排别墅的价格从 946,000 美元到 1,600,000 美元不等。",
        "Downtown Burlingame交通非常方便，距离加州火车、华盛顿公园以及伯林盖姆大道上的商店和餐馆仅几步之遥。",
        "Downtown Burlingame的学校包括距离酒店约 5 分钟车程的公立高中 Burlingame High，那里的一流设施包括网球场和室外温水公共游泳池。该社区的公立中学是 Ray Park 社区的 Burlingame Intermediate，车程 6 分钟，Washington Elementary School 车程 3 分钟。"
      ],
      "Easton Addition": [
        "Easton Addition 是Burlingame最独特的社区之一，也许是最受欢迎的社区。这里的房子包括古典和现代住宅，涵盖各种建筑风格，包括美式和维多利亚式等。截至 2020 年，这里的单户住宅价格从 1,740,000 美元到 5,528,000 美元不等，公寓/联排别墅的价格从 1,040,000 美元到 1,871,260 美元。",
        "这里的街道安静而绿树成荫，毗邻多个公园。该社区甚至拥有自己的 Burlingame 图书馆分馆。该街区的宁静氛围与百老汇便利设施的步行距离相得益彰，距离伯灵格姆大道仅一小段车程。此外，Millbrae BART 站和 Burlingame Caltrain 站距离该地区大约 2 英里。",
        "该地区表现最好的公立学校包括位于 Ray Park 附近的 Burlingame High、Burlingame Intermediate、Roosevelt Elementary 和 Lincoln Elementary。"
      ],
      "Lyon Hoag": [
        "Lyon Hoag位于 Burlingables南边，是性价比最高的 Burlingame 社区之一。截至 2020 年，这里的单户住宅价格从 1,350,000 美元到 2,900,000 美元。 该社区的住宅混合了多种建筑风格，位于修剪整齐的街道上，街道比Burlingame的大多数街道都要宽。 该社区的另一个好处是靠近 Burlingame 大道、购物和餐饮场所以及华盛顿公园的户外活动场所，步行可达。",
        "Burlingame High 是一所公立高中，距离该社区有 5 或 10 分钟的步行路程，提供一流的运动设施，包括水上运动中心和网球场。 Burlingame Intermediate School 是一所位于 Ray Park 附近的公立中学，距离酒店有 10 分钟车程。 Washington Elementary School步行约 5 分钟即可到达。"
      ],
      "Mills Estates": [
        "Burlingame 的 Mills Estates 社区房子大多都是大平层，拥有更大的面积和更大的地块。这个街区的房屋大多建于 1960 年代。 这里还有一些标志性的 Eichler 住宅，有中世纪的风格和味道。 该社区靠近 280 号高速公路，但与 Burlingame 大道和百老汇距离较远。 截至 2020 年，这里的单户住宅价格从 2,150,000 美元到 4,388,000 美元。",
        "该社区的顶级学校包括 Burlingame High，这是一所距离该地区约 10 分钟车程的公立高中，其设施包括网球场和一个超大的室外温水游泳池。该社区的公立中学是 Burlingame Intermediate，距离 Mills Estates 有 5 分钟车程，而 Franklin Elementary是该社区的小学。"
      ],
      "Ray Park": [
        "North Burlingame 的 Ray Park 社区提供郁郁葱葱的绿化环境和各种建筑风格的新住宅，价格适中。截至 2020 年，这里的单户住宅价格从 1,925,000 美元到 3,508,000 美元。",
        "这是一个非常适合步行且位置优越的社区，靠近 Burlingame 广场、BART、280 号高速公路和 El Camino Real。 Mills-Peninsula 医疗中心也在 Trousdale Drive 附近，在其 450,000 平方英尺的设施中提供 24 小时紧急护理。",
        "该社区的公立高中 Burlingame High 距离酒店有 10 分钟车程。 这所学校提供一流的运动设施，包括水上运动中心和网球场。 Burlingame Intermediate School 是一所公立中学，位于附近的北部。  Lincoln Elementary School和Franklin Elementary School都在步行距离之内。"
      ],
      "Oak Grove Manor": [
        "Oak Grove Manor / Burlingables 是Burlingame的一个完善且非常受欢迎的社区。 Oak Grove Manor / Burlingables 是一个适合家庭入住的社区，享有绿树成荫的街道，可步行前往 Burlingame 大道上的许多商店和餐馆，并且靠近市中心的 Caltrain 车站和华盛顿公园。",
        "这个社区主要由居住空间从 1,200 到 1,900 平方英尺不等的高价值中型住宅组成，尽管该地区也有一些可爱的更大的住宅。这个社区的地块面积平均为 5,000 平方英尺，比 Burlingame 的平均面积 6,000 平方英尺要小，但可以通过附属的车库和宽敞的院子来弥补，这是这里的常态。这个理想社区的房屋平均每月仅出售一次。截至 2020 年，这里的单户住宅价格从 1,695,000 美元到 3,100,000 美元不等。",
        "该社区的学校包括 Burlingame High，这是一所公立高中，其顶级设施包括网球场和一个超大型公共游泳池。该社区的公立中学是 Burlingame Intermediate，小学是 Washington Elementary。"
      ]
    },
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470176/Burlingame-web_veq3va.jpg',
        desc: 'Burlingame neighbourhood'
      },
    ],
    related: [
      'Redwood City', 'Daly City', 'Foster City', 'San Mateo', 'Millbrae'
    ]
  },
  'Newark': {
    name: 'Newark',
    title: '性价比高，交通方便，年轻工程师的首选安家之地',
    img: Newark,
    population: 28005,
    description: [
        'Newark 位于旧金山湾区硅谷边缘地带，人口约5万，这是一个可以让您及家人享受美好生活的社区，同时该社区位置优越，方便前往旧金山湾区各主要城市。近几年来，该市就业机会激增，使这里成为成长中家庭定居安家的理想之地。与各大城市为邻，有体育公园相伴！Newark 将是您及家人幸福生活与健康成长的绝佳社区。',
        'Newark和Facebook只有一桥之隔，所以现在是Facebook年轻工程师的首选安家之地。房价比南湾的Palo Alto、Moutain View、Sunnyvale便宜了不是一点半点。',
        'Newark位于东湾，相较于南湾来说，房价便宜，性价比高，但是气候宜人，没有旧金山的冷，也没有南湾的热。Newark的房子在湾区属于性价比较高的地区，位置好，交通方便，人口组成比较均衡，生活方便，是未来增值潜力比较大的区域。',
        'Newark位于未来湾区轨道交通中转的中心位置，拟议中的 Dumbarton 轨道走廊可以联通 CalTrain，Bart，ACE Rail，Capitol Rail，MUNI，VTA，在Newark新设一站。Newark市政府以此为中心，正在新建一处2500家的大社区，涵盖交通、商业、居住、休闲为核心的新社区。这会从整体上改善整个城市的人口结构，收入水平，学校教育。',
        '购物：这里也为亚裔聚集的城市, 有一万多人，区内有大华超市, 中式餐厅以及85度C咖啡面包等华人喜爱的购物场所。也有美国传统农场，比如亚登伍德历史农场(Ardenwood Historic Farm): 34600 Ardenwood Blvd, 19世纪的农场，有一个维多利亚式豪宅以及迷你动物园和有机农产品。'
    ],
    residenceInfos: [
      '最近几年，亚裔购买该市房子的比例很高，尤其是华裔和印度裔，这将会逐渐提升Newark学区的API分数（目前学区一般也算是Newark的缺点了），帮助Newark实现房价的提升。在Newark首选的好地段是位于市西北位置的湖边，房子地大，社区安静，白人比例较高。其次是往西和往南靠近Dumbarton Bridge桥的位置。 ',
    ],
    schoolInfos: [
      '教育：Newark是Ohlone College的一个新校区，加州社区学院系统的成员之一。 其他附近城市的教育中心包括凤凰城大学，ITT研究所，德维利大学和Unitek学院。',
      'Newark 有两所高中，Newark纪念高中和布里奇普中学，以及一所初中Newark中学。 Bridgepoint高中是MacGregor校区的另一所高中，还设有纽瓦克成人学校。',
      '小学包括肯尼迪，Bunker，Graham，Lincoln，Milani，Musick，Schilling和Snow School以及一所天主教小学八年级圣爱德华天主教学校。',
    ],
    related: [
      'Pleasanton', 'Dublin', 'Union City', 'Fremont', 'Milpitas', 'Newark'
    ]
  },
  'Oakland': {
    name: 'Oakland',
    title: '',
    population: 28005,
    description: [
        '奥克兰被称为“旧金山的布鲁克林”，它是旧金山的姐妹城市，与东湾区(East Bay)仅有一水之隔。和纽约的布鲁克林区一样，它也具有悠久的历史、美丽的景色以及令人垂涎三尺的美食和美酒。',
        '奥克兰（Oakland），位于美国西海岸的加利福尼亚州，是加州人口第8大城市，地处旧金山湾区东北部，西临旧金山湾、北接著名大学城伯克利（世界著名高等学府加州大学伯克利分校所在地）。奥克兰隶属于阿拉米达县（Alameda County），是该县的县府所在地。奥克兰与旧金山市隔海相望，兴建于1936年的“旧金山-奥克兰海湾大桥（Bay Bridge）连接着两市。',
        '“奥克兰”一词是英文“橡树之地”（Oakland）的音译。这里原是旧金山湾东部沿岸的一片美丽的橡树林，城市由此而得名，连市徽都是一棵橡树。奥克兰依山傍海，风光秀丽，是典型的海洋性气候。终年享受来自太平洋上的西风，冬暖夏凉，冬季少见冰雪，夏季常多云雾，全年平均气温13℃。富饶的自然资源和宜人的气候景色，使这里充满了浪漫灿烂的气息，深深地吸引着世界各地的旅游观光者。',
        '风景名胜有美丽的，位于奥克兰市中心，也是美国城市中惟一有潮汐的咸水湖。梅里特湖被一座公园所环绕，公园里还有世界著名的儿童乐园和佩拉尔塔游乐场。',
        '奥克兰交通发达，是美国西部交通运输体系的中心。它的高速公路网是世界上最好的高速公路网之一。现代化的汽车运输遍及整个东部海湾并能直通旧金山市。'
    ],
  },
  'San Bruno': {
    name: 'San Bruno',
    title: '',
    population: 28005,
    description: [
        '圣布鲁诺是美国加利福尼亚州圣马特奥县下属的一个城市。于1914年12月23日建市。它位于旧金山市以南19公里的圣克鲁兹山脉山脚，濒临旧金山湾区，与旧金山国际机场临近。这座城市面积约为14.2平方公里。',
        '这座城市位于旧金山南部和米尔布雷之间，毗邻旧金山国际机场和金门国家公墓之间，距离旧金山市区南部约12英里( 19公里)。',
        '圣布鲁诺拥有旧金山湾区典型的地中海气候。夏季干燥，冬季多雨。与南湾的圣荷西不同，这里的夏天干燥且凉爽，平均最高气温只有摄氏23度，不像南边临近的城市（摄氏29度）那么炎热，冬季则与其它地区一样且较冷。',
        'San Bruno 城市公园， 在Crystal Springs Ave和El Crystal学校附近。 是一个主要的政府公园。 这里有成荫的走廊和徒步的小道，聚餐的桌子， 游乐园，一个小的运动场， 市营的游泳池和一个休闲中心。',
        '在San Bruno，El Camino Real 以西的区域治安比较好。',
        '圣布鲁诺临近硅谷，也是著名互联网企业Youtube公司总部所在地。',
        '圣布鲁诺拥有旧金山湾区典型的地中海气候。夏季干燥，冬季多雨。与南湾的圣荷西不同，这里的夏天干燥且凉爽，平均最高气温只有摄氏23度，不像南边临近的城市（摄氏29度）那么炎热，冬季则与其它地区一样且较冷。',
        'San Bruno从380开始往北会有雾，整个夏天几乎没几天有阳光。要生活方便的话，就是millbrae downtown附近的water stone，附近中餐馆多也有中国超市而且离bart近。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470177/San-Bruno-web_pamuon.jpg',
        desc: 'San Bruno neighbourhood'
      },
    ],
  },
  'San Mateo': {
    name: 'San Mateo',
    title: '生活便利、交通方便、环境优美、白富美的海湾小城',
    img: SanMateo,
    population: 28005,
    description: [
        '这座秀丽小城、湾区知名的富人区 — San Mateo，San Mateo虽没有旧金山的繁华，也没有圣何塞科技氛围那么浓厚，但这里是一个适合安家落户的好地方。San Mateo隶属于加州湾区半岛San Mateo County，正好位于旧金山和圣何塞中间的位置，因为所处地理位置的优势，交通四通八达，去到哪里都很方便，而且靠近海边，风景非常美，川流不息的高科技人才让这里日益变得壮大起来，生活、交通、环境都好，可能唯一不足的就是好学校不多。。',
        'San Mateo，一般来说可以以几条公路作为区分，82高速、101高速两条繁忙的高速公路穿过San Mateo市区。这两条高速公路是连接旧金山与南湾的交通要道。如果想要去到南湾的Facebook，开车也仅需要15分钟，20分钟到达Google MTV园区。101以东靠近Foster City的地方一般，82和101之间还行，82以西越靠近280越好。直穿San Mateo的92号公路一直向东湾延伸，一座连接旧金山半岛（San Francisco Paninsula ）与东湾的San Mateo大桥应运而生，开车只需20分钟即可到达东湾。连接圣何塞和旧金山，途径San Mateo的火车Caltrain，有快车站Hillsdale，只需要20分钟即可达到旧金山，解决上班族通勤的问题。',
        '以zip code来说，最好的是94402，其中有对口Aragon High的Foothill Terrace、Baywood Park都很贵，离千万豪宅区Hillsborough咫尺之遥。94403次之，对口高中Hillsdale High，最近降分到8分，这个区域比较杂，好的一般的小区都有，但是总体来说还是越靠西越好。',
        '总部设在San Mateo 的Snowflake创造了2020年华尔街最大规模的IPO。这家提供云数据存储服务的公司仅用了8个月就成功上市。除了Snowflake，PlayStation、SurveyMonkey、GoPro等科技公司的总部也都设在San Mateo。',
        'San Mateo在湾区的美食地位也颇高，有很多美食餐厅，吸引了很多外市的美食爱好者特地前来光顾。比较受华人喜爱的有日式拉面馆Ramen，小肥羊，还有北京面食馆Everyday Beijing，辣的有Spicy Empire，也有地地道道的上海菜Little Shanghai，四川菜Chef Zhao，或者寿司店Sushi Yoshizumi。San Mateo拥有一站式购物商场Hillsdale Shopping Center，周围还有丰富的hiking资源。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470176/San-Mateo-web_jvu471.jpg',
        desc: 'San Mateo neighbourhood'
      },
    ],
    neighbours: {
      "Aragon": [
        "Aragon房产非常受欢迎，这里有大片美丽的房屋，是一个适合家庭入住的绝佳地点。 因此，除了靠近市中心的 Baywood 社区外，该社区的房屋比 San Mateo 的其他大部分地区都贵。 截至 2020 年，阿拉贡单户住宅的价格从 1,935,000 美元到 3,050,000 美元。",
        "这个郁郁葱葱的绿树成荫的住宅区靠近中央公园和日本花园以及圣马刁市中心，靠近购物、餐饮和公共交通设施，步行可达。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, and Baywood Elementary School。"
      ],
      "Baywood": [
        "Baywo​​od 社区是圣马特奥最高档和最精英的社区之一。这里的房子相对高端。截至 2020 年，Baywo​​od 的单户住宅价格从 1,578,000 美元到 5,000,000 美元，公寓/联排别墅的价格从 795,000 美元到 1,775,000 美元。",
        "该街区的大部分生活设施都在步行距离内，可到达圣马特奥市中心的许多便利设施，以及中央公园和日本花园及其花岗岩宝塔、茶馆、锦鲤池和竹林。从该地区也可轻松前往 101、92 和 280 号高速公路。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, and Baywood Elementary School。"
      ],
      "Baywood Knolls": [
        "Baywood Knolls 是一个受欢迎的社区，拥有当代建筑风格的大型住宅，地理位置绝佳。 这个社区环境宁静和设施便利。 该社区距圣马刁市中心仅 1 英里，距 101 号高速公路仅 2 英里，可轻松前往 92 号和 280 号高速公路。截至 2020 年，这里的单户住宅价格从 2,025,000 美元到 3,120,000 美元。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, and Baywood Elementary School。"
      ],
      "Baywood Park": [
        "Baywood Park / Enchanted Hills 社区是令人梦寐以求的安静社区和城市便利的结合。 该社区距离圣马特奥仅 3 英里，靠近购物和餐饮场所，可轻松前往 92 号和 280 号高速公路。这里的房屋不经常上市，但当上市时，往往会有很多offer。 截至 2020 年，Baywood Park / Enchanted Hills 的单户住宅价格从 1,800,000 美元到 3,200,000 美元。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, and Highlands Elementary School。"
      ],
      "Beresford Manor": [
        "Beresford Manor 的房子性价比很高。截至 2020 年，该地区的单户住宅价格从 1,150,000 美元到 2,700,000 美元，公寓/联排别墅的价格从 539,500 美元到 1,020,000 美元不等。 这里的房地产主要是较小的single-family，通常面积较小，通常约为 5,000 平方英尺。",
        "这个街区享有非常适合步行的位置，靠近 Hillsdale 购物中心和 Beresford 公园，那里有灯光网球场、地掷球场、封闭式游乐场和野餐区。"
      ],
      "Bowie Estate": [
        "Bowie Estate 社区比较适合首次购房的买家或有投资需求的买家。截至 2020 年，该地区的单户住宅价格从 1,025,000 美元到 1,760,000 美元，公寓/联排别墅的价格从 309,100 美元至 1,160,000 美元。",
        "Bowie Estate 提供通勤便利，靠近圣马特奥市中心，该社区的学校包括San Mateo High School。"
      ],
      "Downtown San Mateo": [
        "San Mateo 的 Eastern Addition 社区的房子主要是single-family、condominiums公寓和apartments公寓。 这里的房屋价格不等，截至 2020 年，该地区的单户住宅价格从 880,000 美元到 2,650,000 美元，公寓/联排别墅的价格从 236,000 美元到 1,436,000 美元。",
        "Eastern Addition 为居民提供了一个非常方便且适合步行的选择，靠近圣马特奥市中心、伯林格姆大道、加州火车和 101 号高速公路的购物商场和餐馆。",
        "该社区的学校包括一流的圣马特奥高中。"
      ],
      "Edgewater Isle": [
        "Edgewater Isle 是 San Mateo 较新的社区之一，在 Edgewater Island 的房子都是condo，没有single-family house，价格方面比较适中。 截至 2020 年，这里的公寓价格从 710,000 美元到 1,230,000 美元。",
        "Edgewater Isle位于San Mateo中心地带，可轻松前往购物中学、餐馆和 92 号高速公路，社区里随处可见trail和绿地，居民的户外活动非常方便。"
      ],
      "Fiesta Gardens": [
        "Bay Meadows 和 Fiesta Gardens 是San Mateo的两个社区。 前者由 11 个具有中世纪现代设计风格的独立住宅社区组成，而后者则是 1950 年代和 1960 年代更传统的住宅。 截至 2020 年，该社区的单户住宅价格从 1,230,000 美元到 1,770,000 美元，condos公寓/townhomes联排别墅的价格从 444,905 美元到 2,125,000 美元。 该地区靠近 Caltrain 的地方距离 101 号高速公路很近，通勤便利。社区拥有很多公园，比如Paddock Park、Bay Meadows Park、Linear Park, and Persimmon Park。 该社区的学校包括Fiesta Gardens International Elementary、西班牙语-英语双语学校、Borel Middle School和 Hillsdale High School。"
      ],
      "Foothill Terrace": [
        "Foothill Terrace 社区的房子价格对大多数卖家来说可以接受，包括单层住宅和风景优美的两层住宅。 靠近高速公路的住宅通勤比较便利，而位于山上的住宅则会比较安静。这主要取决于买家的偏好。 截至 2020 年，该地区单户住宅的价格从 1,750,000 美元到 3,391,500 美元。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, 和 Baywood Elementary School。"
      ],
      "Hayward Park": [
        "海沃德公园 (Hayward Park) 是圣马特奥 (San Mateo) 一个绿化率很高的社区，建筑大多数是地中海风格。这里的房屋相对适中，截至 2020 年，该地区的独立屋single-family价格从 1,050,000 美元到 3,900,000 美元，公寓condos/townhomes联排别墅的价格从 699,000 美元到 1,450,000 美元。",
        "该社区交通便利，步行即可到达San Mateo圣马特奥市中心。 该社区还拥有自己的 Caltrain 车站，靠近广受欢迎的Central Park & Japanese Garden。",
        "该社区的学校包括一流的Aragon High School, Borel Middle School, 和 Sunnybrae Elementary School。"
      ],
      "Hillsdale": [
        "Hillsdale 社区适合家庭入住，在San Mateo属于中等价位的房子。 截至 2020 年，该地区的single-family独立屋价格从 1,145,000 美元到 2,500,000 美元，condos公寓/townhomes联排别墅的价格从 815,000 美元到 825,000 美元。Hillsdale 社区靠近 Hillsdale’s parks 和 Hillsdale Shopping Center。"
      ],
      "Homestead Husing": [
        "Homestead Husing 适合预算不是很充足的买家，地理位置优越，靠近一流的设施。截至 2020 年，该地区的single-family独立屋价格从 1,018,000 美元到 2,950,000 美元，公寓condos/townhomes联排别墅的价格从 430,756 美元到 1,202,000 美元。",
        "该社区的学校包括一流的 Aragon High School, Borel Middle School, 和 sought-after Baywood Elementary School。"
      ],
      "Lakeshore": [
        "Lakeshore 社区是首次购房者的绝佳选择之一，Lakeshore房产库存主要由condominium公寓大楼组成，还有single-family大平层和农场住宅。 截至 2020 年，该地区的单户住宅价格从 937,000 美元到 1,527,000 美元，公寓/联排别墅的价格从 650,000 美元到 855,000 美元。",
        "Lakeshore 社区靠近Hillsdale Shopping Center, Lakeshore Park, Caltrain, 和 Highway 101。 "
      ],
      "Laurelwood": [
        "Laurelwood / Sugarloaf 是 San Mateo 较新的社区， 截至 2020 年，该地区的单户住宅价格从 1,355,000 美元到 2,550,000 美元，公寓/联排别墅的价格从 460,000 美元到 1,655,000 美元。 该社区的居民喜欢Laurelwood Park和和附近的trail。 "
      ],
      "Sugarloaf": [
        "Laurelwood / Sugarloaf 是 San Mateo 较新的社区， 截至 2020 年，该地区的单户住宅价格从 1,355,000 美元到 2,550,000 美元，公寓/联排别墅的价格从 460,000 美元到 1,655,000 美元。 该社区的居民喜欢Laurelwood Park和和附近的trail。 "
      ],
      "Lauriedale": [
        "Lauriedale 是 San Mateo 较新的社区，主要是single-family homes住宅，其中较新的住宅面积高达 3,000 平方英尺。 Lauriedale 也很多multi-unit apartment和condo。 截至 2020 年，该地区的单户住宅价格从 1,730,000 美元到 2,360,000 美元，公寓/联排别墅的价格从 603,888 美元到 923,000 美元。",
        "该社区通勤便利且靠近Caltrain。"
      ],
      "Los Prados": [
        "Los Prados社区是以Los Prados Park为中心的社区，拥有众多景点。这里的房地产包括独户住宅、共管公寓和公寓，其中大部分都是1970年左右建的，该社区最近也有一些高端海滨开发项目。 截至 2020 年，该地区的单户住宅价格从 1,068,000 美元到 2,600,000 美元，公寓/联排别墅的价格从 690,000 美元到 1,261,000 美元。",
        "Los Prados Park的设施包括网球场和篮球场、棒球场和足球场以及两个picnic areas。 "
      ],
      "Mariners Isle": [
        "Mariners Isle是圣马特奥最大的街区之一，包括居民住宅和商业房产，有single-family homes单户住宅, townhomes联排别墅和condos公寓。",
        "该社区的便利设施包括Mariners Point Golf Center和Bridgepointe Shopping Center，提供零售和餐饮服务。"
      ],
      "Harbor Town": [
        "Harbor Town是一个围绕人工湖建造的大型联排别墅开发项目，是Mariners Isle的一部分。 这个街区的房屋不经常上市，一旦上市，就会很快收到很多offer。 截至 2020 年，Mariners Isle 的单户住宅价格从 1,260,000 美元到 1,649,000 美元，公寓/联排别墅的价格从 499,000 美元到 1,400,000 美元。"
      ],
      "San Mateo Woods": [
        "San Mateo Woods包括 El Camino Real 和 Bayshore Freeway、McClellan Avenue 和 East 40th Avenue 之间的区域。 San Mateo Village 社区长期以来一直是首次购房者和希望缩小规模的人的热门选择，尽管该社区的位置和便利性使其近年来价格上涨。截至 2020 年，该地区单户住宅的价格从 955,000 美元到 2,100,000 美元。 "
      ],
      "South Shoreview": [
        "Shoreview 社区的房子性价比很高。 该社区还有一些更新、更大的房屋可供选择。截至 2020 年，该地区的单户住宅价格从 890,000 美元到 1,650,000 美元，公寓/联排别墅的价格从 405,000 美元到 820,000 美元。",
        "该社区靠近 101 号高速公路，通勤便利，靠近Poplar Creek Golf Course和Coyote Point Recreation Area，适合野餐、游泳、钓鱼。",
        "该社区的学校包括 Parkside 小学、North Shoreview 小学和 Bayside Middle School。 "
      ],
      "Shoreview": [
        "Shoreview 社区的房子性价比很高。 该社区还有一些更新、更大的房屋可供选择。截至 2020 年，该地区的单户住宅价格从 890,000 美元到 1,650,000 美元，公寓/联排别墅的价格从 405,000 美元到 820,000 美元。",
        "该社区靠近 101 号高速公路，通勤便利，靠近Poplar Creek Golf Course和Coyote Point Recreation Area，适合野餐、游泳、钓鱼。",
        "该社区的学校包括 Parkside 小学、North Shoreview 小学和 Bayside Middle School。 "
      ],
      "Westwood Knolls": [
        "Westwood Knolls区可以俯瞰圣马特奥 (San Mateo) 最好的海湾景色。该社区的房屋主要是single-story房屋。截至 2020 年，该地区单户住宅的价格从 1,162,000 美元到 2,412,000 美元。",
        "这个街区靠近 El Camino Real 和 Hillsdale 购物中心，那里有无数的零售、餐饮和娱乐选择。该社区的学校包括Hillsdale High School, Abbott Middle School和Laurel Elementary School。 "
      ]
    },
    residenceInfos: [
      '在San Mateo本科学历以上的人口占到了49.1%。',
    ],
    schoolInfos: [
      'San Mateo的小学和初中学区属于San Mateo-Foster City School District，高中属于San Mateo Union high School District。有两个8分的高中Aragon High School和Burlingame High School，另外还有一个9分的小学College Park Elementary School。总的来说San Mateo学区一般，小学初中都没什么出挑的公立学校，高中似乎Aragon是最好的但也不是一线。',
    ],
    related: [
      'Redwood City', 'Daly City', 'Foster City', 'Millbrae'
    ]
  },
  'Hillsborough': {
    name: 'Hillsborough',
    title: '美国最宜居的城市，湾区顶级富人区，名人云集',
    img: Hillsborough,
    population: 28005,
    description: [
        '这里有加州最好的学区，入住者非富即贵。Hillsborough被评为美国最宜居的城市，也是全美最富裕的城市之一。',
        'Hillsborough(希尔斯堡)为顶级富人区，其所在的邮编区94010也被《福布斯》杂志评为全美前十最贵社区，排名第五位。市内没有商业区，交通便利，开车到Burlingame的繁华市区只需5分钟，距离旧金山市区仅25分钟车程，距离旧金山国际机场仅15分钟车程，距离斯坦福大学20分钟车程。影后林青霞便在此拥有私人住宅。希尔斯堡的特点是大隐于市，空气鲜香，景观无敌。',
        'Hillsborough的房屋以豪宅为主。城市规定，房子最小不能低于2,500平方英尺，占地起码0.5英亩。该市不允许建出租公寓，房屋的自住率高达95%。城市治安良好，犯罪率极低。',
        'Hillsborough属地中海气候，冬暖夏凉。这里的家庭一般不安装冷气，夏天晚上要盖薄棉被。冬天的中午，很多人穿短裤衬衣出行。每年平均三百多天阳光明媚、堪称人间天堂。',
        '该市居民包括：汤加王国的国王和公主、克罗克国民银行创办人、巴拿马太平洋万国博览会委员会主持人William H. Crocker、赫斯特报业集团发行人William R. Hearst、富兰克林基金公司董事局主席Charles Johnson、摩根.斯坦利集团主席之一Michael Grimes、电子艺术公司（EA）总裁Larry Probst。还包括著名歌手Bing Crosby、电影明星Shirley Temple、Alicia Silverstone、Kathy Garver、演员林青霞等等。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470176/Hillsborough-web-1_ik8dv8.jpg',
        desc: 'Hillsborough neighbourhood'
      },
    ],
    schoolInfos: [
      'Hillsborough有三家公立小学West Elementary School、South Elementary School、North Elementary School都是加州评级10分的学校。公立初中Crocker Middle School API 962分，加州评级10分。',
      '这里的学区曾排加州第一，全美前十名。上述学校至今得到过26个加州杰出学校奖，7个美国蓝带奖，29个肯特创新项目奖和其它各类奖项。学区没有公立高中，但可以入读San Mateo联合学区几所优质高中。希尔斯伯勒还有私立的The Nueva School，该校是一所K-12年级的全日制学校，私立中学Crystal Springs Uplands School。'
    ],
    related: [
      'Portola Valley', 'Burlingame', 'Woodside'
    ]
  },
  'South San Francisco': {
    name: 'South San Francisco',
    title: '',
    population: 28005,
    description: [
        '随着大批科技公司落户旧金山，本来房价高高在上的旧金山变得更加拥挤，好区的热门房源加价几十万更是屡见不鲜，所以很多买家开始把目光集中到了距离旧金山downtown 15分钟车程的南旧金山区域，宽敞的院落，便捷的交通和相对较低的房价成为旧金山科技公司员工的不二选择。',
        '南旧金山地理位置优越，开车到旧金山16-20分钟车程，到San Bruno 6-8分钟车程，到Milbrae 10-12分钟车程，到San Mateo 18-24分钟车程，对于在中半岛以上工作的同学可以说是相当方便，因为拥有便捷的地理位置，租金也是非常可观，3825美金的Median rent price可以大大减轻购房者的还款压力。目前南旧金山还处于价格洼地，周边的旧金山和Milbrae价格都是高得吓人，所以南旧金山的升值空间巨大。综合来看，丰厚的租金收入和良好的升值潜力使南旧金山注定成为未来投资的热门区域。',
        '综合考虑平均通勤时间，房价中值、学校评分和治安状况和犯罪率等。由于靠近旧金山，高铁贯穿通过，中半岛的南旧金山（South San Francisco）平均通勤时间为27.4分钟，房价中值为848,444美元，犯罪率为每10万人中有346起，学区平均评分为6分，成为旧金山上班族最适合居住的城市。',
        'South San Francisco 以前主要是工业区，生活配套，学校不是很好，如果不考虑学区，南三番不错，冬天不冷夏天不热， 到哪都方便。',
        '2017年12月10日，南旧金山举行市长就职典礼，由华裔副市长刘丽莎（Liza Lau Normandy）当选就职，成为该市建市110年来的首位华裔市长。'
    ],
    neighbourInfos:[
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1624470177/S-San-Francisco-web_qmcm1q.jpg',
        desc: 'South San Francisco neighbourhood'
      },
    ],
  },
  'Alameda': {
    name: 'Alameda',
    title: '与世隔绝的滨水城市，又不失生活的便利',
    img: Alameda,
    population: 28005,
    description: [
        '阿拉米达（英文：Alameda)是一座位于美国加州旧金山湾区东部的城市，在同名县份阿拉米达县境内，邻接奥克兰市。阿拉米达市包含了整个同名岛屿阿拉米达岛和部分的湾田岛;奥克兰国际机场占了湾田岛的其他部分。',
        'Alameda是旧金山湾内极少数仍然拥有滨水民居的城市;高街上开闸桥的南北两排民居家家户户的后院都建有私人码头，船舶可入旧金山湾和太平洋;有些建筑物直接坐落在水面，提供特殊的水上人家生活情调。还有许多水上房屋，住屋本身就是一艘船。Alameda因其著名的低犯罪率，优秀学区，平坦地势，以及悠闲步调吸引了许多有小孩的家庭前来落户。',
        'Alameda岛上保留了一千五百多栋优美的维多利亚式建筑，在旧金山湾区无出其右;尤以Gold Coast地段最为集中，建筑规模最大，建造也最精美。岛的西南边是Crown海滩，每年夏天吸引大量的弄潮游客，沙滩外的水面上经常可以看到运动爱好者 享受风帆运动。从该处海滩也可以眺望旧金山和奥克兰市中心的天际线。海滩南边是一个野鸟栖息保护区，有众多种类的水鸟栖息，建有眺望台。在此栖息的包括翼 展超过七呎的鹈鹕鸟，它们成群结队，数以百计，蔚为壮观。',
        'Alameda的人工小岛Bay Farm被依湖而建的别墅环抱，与世隔绝。从这里开车，5分钟可至高尔夫球场，15分钟到奥克兰机场、奥克兰唐人街，15分钟到阿拉米达购物中心，30分钟到旧金山。Bay Farm虽然环境与世隔绝，交通、购物却非常便利。小岛学区9分以上，40%华人学生。小区有两个9分以上的学校：Bay Farm School和Amelia Earhart School，学校里40%是华人学生。'
    ],
    related: [
      'Hayward', 'San Leandro', 'Castro Valley'
    ]
  },
  'Concord': {
    name: 'Concord',
    title: '',
    population: 28005,
    description: [
        'Concord有两个BART站，一个是Concord站，另一个是北Concord站，都是位于BART安蒂奥克（Antioch）线的东北部。在Concord搭乘该线路，仅需要70分钟直通旧金山国际机场，到达奥克兰和旧金山市区的时间分别是35分钟和45分钟，对很多工作在两大城市的华人形成了十分方便的交通网。',
        '由于680公路从北向南贯穿全市，驾车人士从Concord出发，直达州府沙加缅度和南湾硅谷的圣荷西市均可控制在90分钟内，通过80号州际公路前往旧金山，非高峰时段也可控制在1个小时内，湾区地理中心的优势相当明显。',
        '随着亚裔大量涌入湾区，Concord的情况越来越让人熟知，目前，Concord市的亚裔人口已经达到8%至10%。',
        'Concord的居住环境其实比较稳定，治安情况良好，天气也不错。大华99超市已经在Concord经营多年，附近多个商圈均有华人开办的餐馆和商店，购物非常方便。',
        '由于核桃溪所在的校区在加州排名很靠前，Concord靠近核桃溪的部分地区与核桃溪是同一个校区，因此深得家长青睐。即使Concord距离核桃溪较远的部分区域，其校区也比奥克兰和圣利安住排名要靠前一些。另外，康郡中文学校和东湾加大分校就在Concord市内，这是华人家长购房时的重要选择因素。'
    ],
  },
  // https://posts.careerengine.us/p/5aa87724a025784576262777
  "Las Vegas": {
    name: 'Las Vegas',
    title: '美国发展最迅速的城市之一，美国下一个房地产投资热土',
    img: LasVegas,
    population: 82372,
    description: [
        '拉斯维加斯（Las Vegas，简称LV），是内达化州人口最多的城市，目前人口为60.5万人，自2000年起上涨了41.5%。拉斯维加斯作为世界上知名的度假地之一，最重要的商业支柱是赌博业，旅游、购物、度假等相关产业链在这里也都蓬勃发展。',
        '大赌城区域主要包含有拉斯维加斯市，北拉斯维加斯市，克拉克县和亨德森市。这几个地区都是紧密相连的，往往分区只是一街之隔，比如街道这一边是拉斯维加斯，街道那一边是亨德森市。而拉斯维加斯市和克拉克县基本上本地人都合称为拉斯维加斯市。',
        '西南区大致的划分是以Charleston 大街和15号公路交界向南向西的区域。中国城位于拉斯维加斯西南中心区，靠近著名的拉斯维加斯大道。中国城附近有5个华人超市，还有一个韩国超市， 周边中国餐馆林立，华人的各种公司或者服务行业比比皆是，很多生活上的需求也能够得以解决。 在医院学校方面。西南区有几个大医院，其中Spring Valley Hospital , Southern Hill Hospital 都是本地人所熟知的。内州最强的磁铁初中和高中都位于西南区。',
        '沙漠林是拉斯维加斯的一个总体规划社区，始建于1990年，在过去的30年里沙漠林多次荣获全美最畅销总体规划社区奖，2019年在全国排名第三；2014年被财富杂志评为美国最适合居住的地方之一。沙漠林也是内华达州最富裕的区域之一。 沙漠林区位于拉斯维加斯西面 ,以Cheyenne和Rampart , Hualapai为界向西的区域。沙漠林占地超过22500英亩，有230多个公园，9个高尔夫球场，超过150英里的行走步道，紧邻国家自然保护区Red Rock Canyon 。沙漠林区域非常适合hiking , 骑行和露营等在大自然下的户外运动。 沙漠林的学区也是吸引人居住的另一个重要因素。沙漠林是本市最好的学区，共有20多所学校,公立学校大部分属于8分到10分的学校，除普通公立学校外它同时还拥有拉斯维加斯高中里排名第二的磁铁高中, 拉斯维加斯排名前三位的私立高中也都位于沙漠林，其中有内华达州排名第一的私立学校 The Meadows school。 沙漠林的公立中学有3个初中和2个高中，地段初中Sig Rogich 和Victoria Fertitta均为8分学校；高中Palo Verde High school 是地段高中，学分8分；West Career & Technical Academy 学分10分, 是拉斯维加斯排名第二位的磁铁高中。',
        '亨德森作为拉斯维加斯都会区的城市之一，其支柱性产业逐渐由工业向住宅和商业发展，直到今天亨德森拥有许多豪华酒店、顶级餐厅、购物中心，由于美国经济的复苏，旅游业的发展迅速，当地居民的收入也水涨船高，高出美国的平均收入水平。',
        '亨德森邻近顶级休闲旅游都市，却不被休闲城市的灯光和喧嚷所扰。在这里即可以享受到拉斯维加斯的热闹和欢乐，又能体会小镇夜晚的悠闲和宁静，是绝佳的度假胜地。'
    ],
    related: [
      'Orlando', 'Orlando'
    ],
    basicInfos:[
      {
        type: 'text',
        value: '以下Las Vegas分区图。'
      },
      {
        type: 'img',
        value: 'https://res.cloudinary.com/realtoragents-us/image/upload/v1622152668/lv_ks2xu1.jpg',
        desc: 'Las Vegas分区图'
      }
    ],
    residenceInfos: [
      '全市第二大族裔，亚裔中华裔居多。家庭平均年收入为全加州中位数的2倍。在2020年美国着名的“富士比财富”评比中，Pleasanton被列为全美国平均年收入最高的前十名城市之一。2014 年，Pleasanton 被评为全美最适合居住的 50 个城市前 5 名。',
      '居民受教育程度高达95.8%，其中本科以上学历占比66.4%。'
    ],
  },
  "Orlando": {
    name: 'Orlando',
    title: '养老、休闲与投资的佳地，美国房市最大的潜力股',
    img: Orlando,
    population: 82372,
    description: [
        '奥兰多以旅游休闲，科技研究，购物及教育多方面发展迅速，这里不光是游客热衷的目的地，也是全美最新颖的会展中心，拥有极高的投资价值。奥兰多大力发展高科技产业和旅游业。现已成为众多世界级公司区域总部所在地，全球最大的迪士尼主题公园所在地，并且是全美第二大校园的中佛罗里达大学(UCF)所在地。 学校数：181所小学，55所初中，36所高中。其中八所高中被《美国新闻排行榜》誉为优质高中。中佛罗里达大学(UCF)有全美第二大的校园，是全美人数前五大的大学，全美七大科研中心坐落其中。',
        '由于奥兰多具备旅游、投资和居住的三大主要因素，所以当地的房屋有短租与长租两种方式。租约在7个月以上的是长租，以下的算短租。当地所有房子都可长租，但只有迪斯尼周围的100多个社区，政府规划只允许短租。短期出租房一般是租给到迪斯尼的游客，租期以天或星期计。做短期出租就像拥有一个小生意，海外投资者一般交由租赁托管公司管理，管理成本相对较高。另外，绝大多数的投资房是用来做长期出租的，长租省心，稳定。 ',
        '目前奥兰多的投资现金回报率在2%-8%之间，一般地说，地段好，房龄短的住宅升值潜力大。',
        '1.升值潜力大 2.市场存量少 3. 租赁供不应求',
        '作为美国首屈一指的休闲度假圣地，奥兰多同时也是养老地的最佳选择。近年来奥兰多人口增长大幅领先美国及佛州平均水平。',
        '根据权威网站数据统计，奥兰多地区的犯罪率在全美属于最低水平，源于奥兰多良好的城市形象和家庭友好的城市特征。'
    ],
    related: [
      'Austin', 'Las Vegas'
    ]
  },
  "Austin": {
    // https://utofun.com/blog/%E5%9C%B0%E4%BA%A7%E8%B6%8B%E5%8A%BF%E5%88%86%E6%9E%90/%E9%80%83%E7%A6%BB%E7%A1%85%E8%B0%B7%E7%94%B2%E9%AA%A8%E6%96%87hp%E8%BF%81%E5%BE%80%E5%A5%A5%E6%96%AF%E6%B1%80-%E6%96%B0%E7%A1%85%E8%B0%B7%E7%9A%84%E5%90%B8%E5%BC%95%E5%8A%9B/
    name: 'Austin',
    title: '经济人口增长远超全美平均水平，高技术公司建立分公司',
    img: Austin,
    population: 82372,
    description: [
        '德州首府奥斯汀市是多元经济文化的中心，城市平均人口年龄为32岁。奥斯汀市被誉为Silicon Hill（硅山），跟西海岸的硅谷（Silicon Valley）有密切关系。有许多知名公司的总部设在奥斯汀市，例如Dell、Freescale、AMD、Whole Foods Market等等。奥斯汀市享有世界现场音乐之都的美誉，聚集着许多音乐娱乐人才；德州大学奥斯汀分校堪称是美国最大的公立大学；浓厚的文化教育和音乐气息，山灵水秀的自然环境，蓬勃发展的朝气，稳定的经济成长，使奥斯汀不断吸引各地人口的涌入。',
        '大量人口流入奥斯汀，给科技企业提供了充足的劳动供给',
        '临近大学城，奥斯汀的高科技人才众多',
        '科技企业的集聚效应，奥斯汀就已经集聚了包括Facebook, IBM, Google等在内的大公司以及许多初创小公司。科技企业的集聚效应使得搬去该地的企业享受更加完善的科技基础设施，科技人才以及科技市场。',
        '奥斯汀的物价水平相比旧金山要低很多，因此企业的运营成本相对较低',
        '奥斯汀的房价也低于旧金山地区，但是随着人口的搬入，房价会在近几年内上涨',
        '德州税收政策的优势，总体来讲，科技企业搬到奥斯汀地区，无论是个人所得税还是企业所得税所交的金额相比与旧金山湾区都相应的减少。',
      ],
      related: [
        'Orlando', 'Las Vegas'
      ]
  },
  'Tracy': {
    name: 'Tracy',
    title: '',
    img: Tracy,
    population: 28005,
    description: [
        '小镇生活虽安静，但四周也有许多垂钓野营、登山玩水的去处。城市四周是一望无际的农田果园，轻松休闲，一派乡野风光。一年三季的农夫市场，可吃到周围农场最新鲜的果蔬。',
        '在Tracy市区西南，沿580公路新开发的Tracy Hills社区，房价似乎不再是那么可负担了。这是在荒地上开发的一个全新高档社区，与原市区之间隔着一大片果园，社区内保留大片绿地，独立屋房价约在65万至80万元之间。',
        '但是，鉴于湾区人口的流入，市府早就对土地开发设定了限制。尽管房市火热，开发商也不是可以随意大兴土木。这里有联邦政府军工部门，更多的是农产品加工、存储和集散产业。Amazon在这里有一个巨大的分发中心，是当地最大雇主。Home Depot，Safeway，Bestbuy等也都在此有巨型物加工厂。政府须保证对居民的服务水准，如提供足够医疗、教育和交通服务，同时也要保护生态环境。也因如此，Tracy才成了许多人向往的落脚之地。'
    ],
  },
  'Tracy': {
    name: 'Tracy',
    title: '新兴开发建设的大型居民社区，充满活力全新社区',
    img: Tracy,
    population: 28005,
    description: [
        'Tracy是湾区的郊区，作为北加重要交通枢纽，Tracy的交通十分便利。整个城市被三条重要公路围在中间，纵贯美西的5号路从城东穿过，走出山谷的580公路在近城区前分出205号公路，两条路分别从城市西南和西北方经过，汇入5号路。三条公路组成一个三角型，Tracy的座右铭就是：在三角之内思考。',
        'Tracy是十分多元化的小镇，近年亚裔增长快速，餐饮及其他服务业都有亚裔经营。和湾区城市一样，在新开发区如Tracy Hills，到处可见年轻印度裔家庭的身影。Tracy吸引湾区人，当然不仅因其地理位置，其房价飙升也不是始于疫情。2008年次贷危机后，这里的房市一直在稳定复苏。许多退休人来这里购屋自住，小屋换大屋，也有些人置产投资，还有东湾南湾年轻上班族到此安家，也是因这里真的是宜居之地。',
        'Tracy曾是2013–2014年最佳房地产投资的首选城市之一，周遭生活机能完善、学区好、气候佳，是经济实惠的购屋好去处。Tracy的建立通过条理分明的都市规划，周边交通发达，位于东湾以东的Tracy未来将有6大投资优势：',
        '第一：这里的房子年份新，质量高，价格低，院子大。房屋、户型选择多样化成了Tracy不可多得的优势。对刚组建家庭的年轻小夫妻来说，Tracy有许多房产适合首购一族，属于价格较低的入门款单户型（Single Family）小屋。说是“小屋”其实不小，大多都是 3 卧房以上，空间足够让新生孩子快乐成长。',
        '第二：湾区许多高科技公司的工程师，无须每天进办公室工作，有大约 30%的员工可以在家远程办公。这些高技术人才喜欢高品质生活和家庭环境，许多人就选择在Tracy安家，为Tracy当地注入大量人流与经济，更大幅提高该学区的教育水平和学习竞争力。',
        '第三：由于城市规划良好，Tracy 的生活机能十分方便，医疗系统完善，户外活动空间多，还有多座大型卖场和餐厅提供生活必需品及餐饮。对退休养老而言，是悠闲且有保障的居家好地点。',
        '第四：Tracy是距离湾区最近的新兴房产市场，环境优美之居住城市，而且靠近580高速公路令广大通勤上班族非常方便。从东湾Livermore沿580公路东行，20分钟就可抵达。',
        '第五：随着湾区公司和企业不断往湾区周边延伸，各类产业已开始进驻Tracy。其中又以大量仓储空间为主，因为依赖湾区码头货物流通的网路营销公司（E-commerce），视Tracy为首选之一。随着产业入驻，将为Tracy创造大量工作机会和人力资源。',
        '第六：该城市和地区人口素质较高，社区安全良好，出行交通方便，距离旧金山市内车程只要一个小时多，还有各种交通工具 ACE、BART 等直通市内和湾区各地，休闲购物公园，投资自住任选，华商移民最爱！',
    ],
    schoolInfos: [
      '在Tracy有多所“GreatSchools Rating”8分以上的好学校，学校的硬体设施完善成熟，适合学生培养潜能，未来往多方向发展。Tracy也分老区和新区。市东北部是老城区，西南部是新区，而且越向外围越新，城市边缘处的农地上可见到正在开发的工地。老城区建筑陈旧，学校排名也靠后。而西南地区的学校教学品质好。近年迁入的家庭，考虑到子女教育，一般者选在西南区。新区老区的房价，因此也有明显差别。',
      '其中，当地较著名的高中有Tracy High和John C. Kimball High，而且Tracy High更荣获 U.S. News的“最佳高中（Best High School）”铜牌，该评比以学生表现和大学升学率为准。公立高中有雀西高中（Tracy High）（GreatSchools Rating 达8分）。',
    ],
    related: [
      'Mountain House'
    ]
  },
  'Mountain House': {
    name: 'Mountain House',
    title: '新兴开发建设的大型居民社区，充满活力全新社区',
    img: MountainHouse,
    population: 28005,
    description: [
        'Mountain House在5号公路、205公路、580公路的交界附近，正好是湾区与中谷转运地，位置方便；且小区规划很好，有新的学校与绿地，另外开发商将每1500到2000户规划成一个小区(village)，每个区都有自己的学校。整个Mountain House由美国一家退休金公司购买下来发展，所有都是新的，而且有心发展区域。 2009年 crash后建设停工，2016年开始继续发展。',
        'Mountain House区除了具有Tracy所有的地利、交通及生活机能等优点以外，最特别的是它具备了一般城市少见的各种户型新房，还有为数众多的大型豪宅。以刚组建家庭的年轻小夫妻为例，Mountain House有许多房产适合首购一族，属于价格较低的入门款单户型（Single Family）小屋。说是“小屋”其实不小，大多都是 3 卧房以上，空间足够让新生孩子快乐成长。因为是全新建设，Mountain House不乏现代科技设备，包括近年流行的环保绿能硬件，还可量身规划。',
        'Mountain House 并没有 local 警局只有消防局，由Tracy派遣警察前来巡逻和处理。治安算安全的，大部分是家庭与孩子，附近没有让孩子半夜跑出去娱乐的地方，对Teenager孩子来说挺无聊的，对家人来说挺好的。',
        '开车10分钟能到Costco, DMV, USPS, UPS, Target, Walmark, BOA, Chase, In-and-out, Mall...各式生活机能的商店巨方便。',
        '整体而言，Mountain House区是充满活力及新生的全新社区，广受新一代年轻族群喜爱，处处是机会和发展潜力。'
    ],
    schoolInfos: [
      '每个区域都有自己的中小学K-8，目前Mountain House只有一间巨大无比的High school，这个High school，比南湾的college校园还大，门口也是非常高大上。',
      'Mountain House区的学区，从小学到高中都是GreatSchools Rating高分的好学校，包括 9 分（满分10分）的Bethany Elementary School（K-8年级）、Sebastian Questa Elementary School（K-8年级）。',
      'Mountain House学区当中最著名的还有Mountain House高中（Mountain House High School），GreatSchools Rating达 9 分，学生表现优异。学校一改传统的教学模式，在学校设备以及教学方式上讲求创新。另外，“让孩子赢在起跑点”是Mountain House高中的教学理念，方式却不是强逼学生死背课本，而是让学生学会“自主学习”，在安排功课时，让学生独立从头进行并完成整套计划。如此重视“创新”及“独立”的特点让Mountain House高中成为模范重点高中，是周边许多学校的学习典范。'
    ],
    related: [
      'Tracy'
    ]
  },
}

export default cities