import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import {Doughnut, Line, HorizontalBar} from 'react-chartjs-2';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardTitle, Col} from 'reactstrap';
import cities from "../components/cities"
import FeatureStyle6 from "../components/Features/FeatureStyle6"
import Ads from "../images/ads.jpg"

// https://posts.careerengine.us/p/5b50dfeba580766a85b540d9
// https://posts.careerengine.us/p/5b569ae52f7dca7fabc80524

const Schoolpage = (props) => {
  const {pathContext} = props
  const school_name = pathContext.school["content"]["entity"]["name"]
  const ucAdmissions = pathContext.uc

  const ethnicityInfo = pathContext.school.data.ethnicityInfo.filter(raceInfo => raceInfo.label !== 'Low-income' && raceInfo.label !== 'All students');
  const races = ethnicityInfo.map(function (raceInfo) {
    return raceInfo.label;
  });
  let totalPercentage = 0; 
  ethnicityInfo.forEach(raceInfo => {
    if(raceInfo.percentage !== null)
    totalPercentage += raceInfo.percentage;
  })
  const remaining = 100 - totalPercentage;

  let racePopMap = new Map();
  ethnicityInfo.map(function (raceInfo) {
    if (raceInfo.percentage === null) {
      raceInfo.percentage = remaining;
    }
    raceInfo.NumberOf = pathContext.school.enrollment * raceInfo.percentage / 400;
    racePopMap.set(raceInfo.label, raceInfo.NumberOf);
  });

  const percentages = ethnicityInfo.map(function (raceInfo) {
    return raceInfo.percentage;
  });

  const ratings = ethnicityInfo.map(function (raceInfo) {
    return raceInfo.rating ? raceInfo.rating : 0;
  });

  const data = {
    labels: races,
    datasets: [{
      data: percentages,
      backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#9933CC',
      '#ff8f00'
      ],
      hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#9933CC',
      '#ff8f00'
      ]
    }]
  };
  const data2 = {
    labels: races,
    datasets: [
      {
        label: '评分',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        data: ratings
      }
    ]
  };

  var years = []
  var allAdms = []
  var allApps = []
  var allEnrs = []
  var asianAdms = []
  var asianApps = []
  var asianEnrs = []
  var admissionRaces = [];
  var admissionNums = [];
  if (ucAdmissions) {
    years = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.year;
    });
    allAdms = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.All.Adm;
    });
    allApps = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.All.App;
    });
    allEnrs = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.All.Enr;
    });
    asianAdms = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.Asian.Adm;
    });
    asianApps = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.Asian.App;
    });
    asianEnrs = ucAdmissions.map(function (ucAdmission) {
      return ucAdmission.races.Asian.Enr;
    });
    const ucLatestAdmissions = ucAdmissions[ucAdmissions.length - 1];
    admissionRaces = [];
    admissionNums = [];
    for (const [race, raceAdmission] of Object.entries(ucLatestAdmissions.races)) {
      if (race === 'All') {
        continue;
      }
      admissionRaces.push(race);
      admissionNums.push(raceAdmission.Adm);
    }
  
  }

  const allUCData = {
    labels: years,
    datasets: [
      {
        label: '申请',
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: allApps
      },
      {
        label: '录取',
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: allAdms
      },
      {
        label: '就读',
        backgroundColor: '#FFCE56',
        borderColor: '#FFCE56',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: allEnrs
      }
    ]
  };  
  const asianUCData = {
    labels: years,
    datasets: [
      {
        label: '申请',
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: asianApps
      },
      {
        label: '录取',
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: asianAdms
      },
      {
        label: '就读',
        backgroundColor: '#FFCE56',
        borderColor: '#FFCE56',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        fill: false,
        data: asianEnrs
      }
    ]
  };
  const enrollmentData = {
    labels: admissionRaces,
    datasets: [
      {
        label: '录取人数',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: admissionNums
      }
    ]
  };  

  const elements = []
  for(var rating_name in pathContext.school["data"]["subratings"]) {
    elements.push(
      <Col lg={ 4 } key={rating_name}>
        <Card className="mb-3">
            <CardBody>
                <CardTitle tag="h6" className="mb-4">
                    {rating_name}
                </CardTitle>
                <div>
                    <div className="mb-3">
                        <h2>{pathContext.school["data"]["subratings"][rating_name]} / 10</h2>
                    </div>
                </div>
            </CardBody>
        </Card>
      </Col>
    )
  }

  const city = pathContext.school["data"]["address"]["city"]
  const cityInfo = cities[city]
  const cityElement =  (cityInfo !== undefined || cityInfo.img !== undefined) ?
  <React.Fragment>
    <div className="col-md-4">
      <FeatureStyle6
          image={cityInfo.img}
          title={city}
          text={cityInfo.title}
          url={city}
      />
    </div>
    <div className="col-md-4">
    <FeatureStyle6
        image={Ads}
        title="Jing Xue"
        text='湾区Top房产经纪'
        url=""
        hot={true}
    />
    </div></React.Fragment> : null

  return (
    <>
      <HeaderStyle1 />
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>{school_name + "简介 | " + pathContext.school["data"]["districtName"] + "学区分析 | " + school_name + " 族裔分析 | " + school_name +  " 房产 | Jing Xue | 三谷湾区置业专家 三谷Top华人房产经纪 " + school_name + "房市分析"}</title>
              <meta name="description" content={school_name + "学校排名," + pathContext.school["data"]["address"]["city"] + "学校排名 " + "一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，南湾、东湾、三谷市场,手把手高效与您完成房产买卖服务,"} />
              <meta name="Keywords" content={school_name + "房产," + school_name + "投资房," + school_name + "简介," + pathContext.school["data"]["districtName"] + "学区," + "湾区,三谷,华人房产经纪,南湾,东湾,湾区学校排名,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"}></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />
          </Helmet>
      </div>
      {/* Start Portfolio Section*/}
      <section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={school_name} />
            </div>
          </div>
          <iframe
            title={school_name}
            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&zoom=12&q=" + school_name + "+California"}
            width="100%"
            height="500px"
            style={{ border: "0" }}
          ></iframe>
        </div>
      </section>
      {/* End Portfolio Section*/}
      {/* Start Feature Section */}
      <section
        className="pad-t20 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={school_name + '学校基本信息'} />
            </div>
          </div>
          <div className="row">
            <Col lg={ 6 }>
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6" className="mb-4">
                        学区
                        </CardTitle>
                        <div>
                            <div className="mb-3">
                                <h6>{pathContext.school["data"]["districtName"]}</h6>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={ 6 }>
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6" className="mb-4">
                        {pathContext.school["badge"]["display"]}
                        </CardTitle>
                        <div>
                            <div className="mb-3">
                                <h6>{pathContext.school["badge"] ? "在湾区总共" + pathContext.school["badge"]["total"] + "学校中排名 " + pathContext.school["badge"]["ordinal"] : 'N/A'}</h6>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={ 4 }>
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6" className="mb-4">
                            Great School 评分
                        </CardTitle>
                        <div>
                            <div className="mb-3">
                                <h2>{pathContext.school["data"]["rating"]} / 10</h2>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={ 4 }>
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6" className="mb-4">
                            学生人数
                        </CardTitle>
                        <div>
                            <div className="mb-3">
                                <h2>{pathContext.school["data"]["enrollment"]}</h2>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={ 4 }>
                <Card className="mb-3">
                    <CardBody>
                        <CardTitle tag="h6" className="mb-4">
                            学生老师比例
                        </CardTitle>
                        <div>
                            <div className="mb-3">
                                <h2>{pathContext.school["data"]["studentsPerTeacher"]}</h2>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {elements}
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <h4 style={{ fontSize: "17px" }}>各族裔比例</h4>
              <Doughnut data={data} />
            </div>
            <div className="col-md-6 text-center">
              <h4 style={{ fontSize: "17px" }}>各族裔成绩</h4>
              <HorizontalBar data={data2} />
            </div>
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Feature Section */}
      {ucAdmissions && <section
        className="pad-t30 pad-b30 parallax"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title={school_name + ' UC加州大学申请录取信息'} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <h4 style={{ fontSize: "17px" }}>{pathContext.school.name} University of California录取统计</h4>
              <Line data={allUCData} />
            </div>
            <div className="col-md-6 text-center">
              <h4 style={{ fontSize: "17px" }}>{pathContext.school.name} University of California亚裔录取统计</h4>
              <Line data={asianUCData} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <h4 style={{ fontSize: "17px" }}>{pathContext.school.name} 2022年University of California各族裔录取人数 </h4>
              <HorizontalBar data={enrollmentData} />
            </div>
          </div>
        </div>
      </section>}
      {/* End Feature Section */}
      {/* End Portfolio Section*/}
      {cityElement && <section className="pad-t30 pad-b30">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                  <SectionTitle title="所在城市" />
                  </div>
              </div>
              <div className="row">
                  {cityElement}
              </div>
          </div>
      </section>}
      {/* Start Footer Section*/}
      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default Schoolpage
